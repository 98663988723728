import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { ButtonInternal } from './Button';
import { theme } from '../../Theme';
import { toRem } from '../../../helpers';
import useChildren from '../../../hooks/useChildren';

const OuterWrapper = styled('div')``;
const ArticleBlockWrapper = styled.section`
  max-width: ${toRem(1360)};
  width: 100%;
  background-color: ${theme.colors.forest};
  display: flex;
  flex-direction: column;

  ${theme.above.md} {
    flex-direction: row-reverse;
    padding: ${toRem(72)} ${toRem(80)};
  }
`;

const TextWrapper = styled.div`
  padding: ${toRem(24)};
  ${theme.above.md} {
    flex: 1;
    padding: 0 ${toRem(65)} 0 0;
  }
`;

const Title = styled.h3`
  font-size: ${toRem(24)};
  line-height: ${toRem(28)};
  color: ${theme.colors.aloe};
  margin-bottom: ${toRem(18)};
`;

const Text = styled.p`
  line-height: ${toRem(22)};
  color: ${theme.colors.aloe};
  margin-bottom: ${toRem(30)};
`;

const Cta = styled(ButtonInternal)`
  display: inline-flex;
`;

const ImageWrapper = styled.div`
  width: 100%;
  ${theme.above.md} {
    flex: 2;
  }
`;

const ArticleBlockLarge = props => {
  const blockProps = useChildren(props);

  if (!blockProps) return;

  const altText = blockProps.imageDescription
    ? blockProps.imageDescription
    : blockProps.title;

  return (
    <OuterWrapper className="odd-even-background">
      <ArticleBlockWrapper>
        <ImageWrapper>
          <Image src={blockProps.image} alt={altText} aspect="125:73" />
        </ImageWrapper>
        <TextWrapper>
          <Title>{blockProps.title}</Title>
          <Text>{blockProps.text}</Text>
          <Cta className="primary light" href={blockProps.ctaLink}>
            {blockProps.ctaText}
          </Cta>
        </TextWrapper>
      </ArticleBlockWrapper>
    </OuterWrapper>
  );
};

export default ArticleBlockLarge;
