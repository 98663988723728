import { css } from 'linaria';

export const linkHoverStyle = css`
  :hover,
  :active {
    text-decoration: underline;

    .Heart {
      animation-name: heartbeat;
      animation-duration: 1.2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 2;
      animation-play-state: running;
    }
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.05);
    }
    40% {
      transform: scale(1);
    }
    60% {
      transform: scale(1.05);
    }
    80% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
`;
