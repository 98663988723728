import React from 'react';
import { propEval } from './components/ContentEditor/HeaderBlock';

const fontSizeBaseValue = 16; // pixels

/**
 *
 * @param {number} pixels
 * @returns {string}
 */
export const toRem = pixels => {
  return `${pixels / fontSizeBaseValue}rem`;
};

/**
 *
 * @param {*} possiblyAString
 * @returns
 */
export const convertStringToNumberWithComma = possiblyAString =>
  typeof possiblyAString === 'string'
    ? possiblyAString?.replace(/,/, '.')
    : possiblyAString;

/**
 *
 * @param {number} amount
 * @param {number} fixed
 * @returns {number}
 */
export const getRoundedAmount = (amount, fixed = 0) => {
  const _amount = convertStringToNumberWithComma(amount);
  return Number(
    Math.round((Number(_amount) + Number.EPSILON) * 100) / 100
  ).toFixed(fixed);
};

/**
 *
 * @param {string} currencyCode
 * @returns {string}
 */
export const getCurrencyString = currencyCode => {
  if (currencyCode === 'EUR') return currencyCode;

  return 'KR';
};

/**
 *
 * @param {object} price price object
 * @param {object} previousPrice previous price object
 * @param {number} fixed number of decimal places
 * @returns {number}
 */
export const getSavedAmount = (price, previousPrice, fixed) => {
  const savedAmount = previousPrice - price;

  return getRoundedAmount(savedAmount, fixed);
};

/**
 *
 * @param {object} currencyLocale
 * @returns {number}
 */
export const useDecimals = currencyLocale =>
  currencyLocale.code === 'EUR' ? 2 : 0;

/**
 *
 * @param {object} components object with components
 * @param {function} WrapperComponent component to wrap the components
 * @param {string[]} exclude array of components to exclude
 * @returns {object}
 */
export const wrapComponents = (components, WrapperComponent, exclude) =>
  Object.entries(components).reduce((acc, [key, Component]) => {
    return {
      ...acc,
      [key]:
        typeof Component !== 'string'
          ? !exclude.includes(key)
            ? props => (
                <WrapperComponent>
                  <Component {...props} />
                </WrapperComponent>
              )
            : Component
          : Component
    };
  }, {});

export const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'Å',
  'Ä',
  'Ö'
];

export const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null;
};

export const addAlphaChannel = (color, alpha) =>
  `rgba(${hexToRgb(color)}, ${alpha})`;

// eslint-disable-next-line no-control-regex
export const isValidEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

/**
 * The filterCEItemsWithProp function takes in an array of Content Editor items, a property name, and a value.
 * It then filters the items based on whether or not they have that property with that value.
 * The function returns an object containing two arrays: one for the filtered items and one for the rest of them.

 * @param {array} items Filter the items in the array
 * @param {string} propName Filter the items by a property name
 * @param {any} value Check if the value of the property is true or false
 *
 * @return An object with two arrays, filtered and rest
 *
 * @docauthor Anders Zetterström @ R3
 */
export const filterCEItemsWithProp = (items, propName, value = true) => {
  const filtered = [];
  const rest = [];

  items?.forEach(item => {
    const foundItems = item?.properties?.filter(prop => {
      const val = propEval(prop?.value);
      return prop?.name === propName && val === value;
    });

    if (foundItems?.length > 0) {
      filtered?.push(item);
    } else {
      rest?.push(item);
    }
  });

  return { filtered, rest };
};

/**
 * The filterCEItemsWithKey function takes in an array of children and a key,
 * and returns two arrays: one containing the children that have the given key as part of their `key` prop,
 * and another containing all other children.
 
 *
 * @param {array} children Filter the children of a component
 * @param {string} key Filter the children array
 *
 * @return An object with two properties:
 *
 * @docauthor Trelent
 */
export const filterCEItemsWithType = (items, type) => {
  const filtered = [];
  const rest = [];

  items?.forEach(item => {
    if (item?.type?.includes(type)) {
      filtered?.push(item);
    } else {
      rest?.push(item);
    }
  });

  return { filtered, rest };
};
