import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import addMultipleToCartMutation from './Cart/queries/addMultipleToCart.gql';
import cartQuery from './Cart/queries/cartQuery.gql';
import { FlyoutTrigger } from './ui/vita/Flyout/FlyoutTrigger';

export const OrderFromUrl = () => {
  const location = useLocation();
  const history = useHistory();
  const [completed, setCompleted] = useState(false);
  const [isFlyoutOpen, setFlyoutOpen] = useState(false);

  const productsList = (location?.search?.includes('addProducts=')
    ? location?.search?.split('addProducts=')?.[1]?.split(',')
    : []
  )?.map(item => ({ articleNumber: item, quantity: 1 }));

  const [addToCart] = useAddMultipleToCart(
    productsList,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        setCompleted(true);
      },
      onError: () => {
        setCompleted(false);
      }
    }
  );

  useEffect(() => {
    if (productsList?.length > 0) {
      addToCart(productsList);

      // Redirect to the URL without addProducts query parameter
      const newSearch = location.search
        .replace(/addProducts=[^&]*&?/, '')
        .replace(/&$/, '');
      history.replace({
        pathname: location.pathname,
        search: newSearch
      });
    }
  }, [productsList, addToCart, location.search, history, location.pathname]);

  useEffect(() => {
    if (completed) {
      setFlyoutOpen(true);
    }
  }, [completed]);

  return (
    <FlyoutTrigger triggerId={'header-cart-desktop'}>
      {drawer => {
        if (isFlyoutOpen && !drawer.isOpen) {
          drawer.showTarget();
          setFlyoutOpen(false); // Reset state to avoid re-triggering
        }
      }}
    </FlyoutTrigger>
  );
};
