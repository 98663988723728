import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const MaxWidth = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: 90rem;

  padding-top: 0rem;
  padding-bottom: 0rem;

  ${theme.above.md} {
    padding-left: ${props => (props.removePadding ? '0' : '5rem')};
    padding-right: ${props => (props.removePadding ? '0' : '5rem')};
  }

  ${theme.below.md} {
    padding-left: ${props => (props.removePadding ? '0' : '2rem')};
    padding-right: ${props => (props.removePadding ? '0' : '2rem')};
  }
`;

export default MaxWidth;
