import { css } from 'linaria';
import { theme } from '../../../Theme';

css`
  :global() {
    .html-row {
      display: flex;
      justify-content: center;
      max-width: ${theme.layout.maxWidth};
      margin: 0 auto;

      ${theme.below.md} {
        flex-direction: column;
      }
    }

    .html-row-item-wrapper {
      height: 100%;
    }
  }
`;
