import { Above } from '@jetshop/ui/Breakpoints';
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { css } from 'linaria';
import { theme } from '../Theme';

const boxRowImageVideoStyle = css`
  position: relative;
  width: 100%;
  max-width: ${theme.layout.maxWidth};
  margin: auto;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Videoplayer = ({
  desktopVideoUrl,
  desktopMediaRatio,
  mobileVideoUrl,
  mobileMediaRatio,
  autoplay = { value: true } // not in admin
}) => {
  const [videoUrl, setVideoUrl] = React.useState('');

  const aspectRatioConverter = ratio => {
    const cssRatio = ratio?.replace(':', '/');
    return cssRatio;
  };

  const videoProps = {
    height: '100%',
    width: '100%'
  };

  const videoUrlGenerator = aboveLg => {
    const auto = autoplay?.value;
    const vUrl = aboveLg ? desktopVideoUrl : mobileVideoUrl;
    const videoID = vUrl?.split('https://vimeo.com/')?.[1];
    const convertBoolToInt = b => {
      return b ? 1 : 0;
    };
    const intFromAutoPlay = convertBoolToInt(auto);
    return `https://player.vimeo.com/video/${videoID}?autoplay=${intFromAutoPlay}&autopause=${!intFromAutoPlay}&loop=${intFromAutoPlay}&title=0&byline=0&portrait=0&controls=${!auto}&muted=${auto}`;
  };

  return (
    <Above breakpoint="lg">
      {matches => {
        setVideoUrl(videoUrlGenerator(matches));

        return (
          <div
            className={boxRowImageVideoStyle}
            style={{
              aspectRatio: aspectRatioConverter(
                matches ? desktopMediaRatio : mobileMediaRatio
              )
            }}
          >
            <iframe
              loading="lazy"
              title={`Life video`}
              {...videoProps}
              src={videoUrl}
              data-cookieconsent="marketing"
            />
          </div>
        );
      }}
    </Above>
  );
};
