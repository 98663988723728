import React, { useState } from 'react';
import { cx } from 'linaria';
import { mobileHeaderStyle } from './MobileHeader.style';
import { SVG } from '../../../../assets/SVG';
import { StyledSearchBar } from '../../../ui/vita/SearchBar/SearchBar';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';
import { useIntl } from '@jetshop/intl';
import { FlyoutTrigger } from '../../../ui/vita/Flyout/FlyoutTrigger';
import { useModalManagement } from '../../../ui/vita/Flyout/useModalManagement';
import Badge from '../../../ui/Badge';
import { DynamicCategoryRenderer } from '../../../ContentEditor/DynamicCategoryRenderer/DynamicCategoryRenderer';
import { campaignbarComponents } from '../../../ContentEditor/ContentComponents';
import { useCart } from '@jetshop/core/components/Query/CartProvider';
import cartQuery from '../../../ui/vita/Cart/queries/cartQuery.gql';

export const MobileHeader = ({ routes, favouritesCount, style }) => {
  const [searchValue, setSearchValue] = useState('');
  const translate = useIntl();

  const { closeAll } = useModalManagement();
  const { cart } = useCart(cartQuery);

  const t = useIntl();

  const searchPlaceholderString = translate(
    'Search for product, category, brand...'
  );

  return (
    <div className={cx('mobile-header', style)} id="project-header">
      <DynamicCategoryRenderer
        categoryId={process?.env?.REACT_APP_CAMPAIGNBAR_CATEGORY_ID}
        rendererComponents={campaignbarComponents}
      />

      <div className={cx('logo-and-icons')}>
        <div className="left">
          <LinkEvaluator
            linkClickCallback={() => {
              closeAll();
            }}
            link={'/'}
          >
            <SVG name={'Life'} />
          </LinkEvaluator>
        </div>
        <div className="right">
          <LinkEvaluator
            linkClickCallback={() => {
              closeAll();
            }}
            link={t(routes?.stores?.path)}
          >
            <SVG name={'Pin_outline'} />
          </LinkEvaluator>
          <LinkEvaluator
            linkClickCallback={() => {
              closeAll();
            }}
            link={t('/favourites')}
          >
            <div
              className="favourites-icon-wrapper"
              style={{ position: 'relative' }}
            >
              <SVG name={'Heart'} />
              <Badge text={favouritesCount} />
            </div>
          </LinkEvaluator>
          <LinkEvaluator
            linkClickCallback={() => {
              closeAll();
            }}
            link={routes?.myPages?.path}
          >
            <SVG name={'Person'} />
          </LinkEvaluator>
          <FlyoutTrigger triggerId={'header-cart-mobile'}>
            {drawer => (
              <div style={{ position: 'relative' }}>
                <SVG
                  name={'Cart'}
                  onClick={() => {
                    drawer.isOpen ? drawer.hideTarget() : drawer.showTarget();
                  }}
                />
                <Badge text={cart?.totalQuantity} />
              </div>
            )}
          </FlyoutTrigger>
          <FlyoutTrigger triggerId={'header-menu-mobile'}>
            {drawer => (
              <SVG
                name={'Menu'}
                onClick={() => {
                  drawer.isOpen ? drawer.hideTarget() : drawer.showTarget();
                }}
              />
            )}
          </FlyoutTrigger>
        </div>
      </div>
      <div className="header-search">
        <FlyoutTrigger triggerId={'header-search-mobile'}>
          {drawer => {
            return (
              <StyledSearchBar
                placeholder={searchPlaceholderString}
                searchValue={searchValue}
                setSearchValue={value => {
                  value?.length >= 3
                    ? drawer.showTarget()
                    : drawer.hideTarget();
                  setSearchValue(value);
                }}
                onFocus={() => {
                  closeAll();
                }}
              />
            );
          }}
        </FlyoutTrigger>
      </div>
    </div>
  );
};

export const StyledMobileHeader = ({
  categories,
  routes,
  cartData,
  favouritesCount
}) => {
  return (
    <MobileHeader
      categories={categories}
      routes={routes}
      cartData={cartData}
      favouritesCount={favouritesCount}
      style={mobileHeaderStyle}
    />
  );
};
