import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../../../Theme';
import { SVG } from '../../../../assets/SVG';
import { hideScrollbar } from '../styles/scrollBar.style';
import emblem from '../../../../assets/img/lcp-emblem.png';
import bg from '../../../../assets/img/lcp-bg.jpg';
import useChannelCheck from '../../../../Temp/hooks/useChannelCheck';
import Image from '@jetshop/ui/Image';

export const FlyoutContentWrapper = styled('div')`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: auto;
  opacity: ${props => (props.active ? 1 : 0)};
  overflow-y: ${props => (props.active ? 'auto' : 'hidden')};
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  transform: translateX(${props => (props.active ? 0 : -100)}%);
  transition: ${props => (props.active ? 0.4 : 0.2)}s ease-in-out;
  background: ${theme.colors.backgroundGrey};
  height: 100%;

  ${theme.below.lg} {
    padding-bottom: 3rem;
  }

  .header {
    position: relative;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      background: none;
      border: none;
      font-size: 16px;
      text-transform: uppercase;
      color: white;
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 10px;
      z-index: 1;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    .emblem {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 1;
    }
    .bg {
    }
  }

  .content {
    padding: 40px;
    ${theme.below.lg} {
      padding: 20px 40px;
    }
    h2 {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 1rem;
      &.disclaimer {
        font-size: 12px;
        color: #4e4e4e;
        margin-top: 1rem;
        font-style: italic;
      }
    }

    .list {
      margin-bottom: 20px;
      ${theme.below.lg} {
        margin-bottom: 10px;
      }
      .list-item {
        display: flex;
        align-items: baseline;
        margin: 0;
        .icon {
          margin-right: 5px;
          svg {
            width: 14px;
            height: 14px;
          }
        }
        .text {
          font-size: 14px;
          line-height: 1.2;
        }
      }
    }

    .cta-wrapper {
      padding-top: 20px;
      margin-bottom: 2rem;
    }

    .cta {
      display: block;
      width: 100%;
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      color: ${theme.colors.forest};
      background: ${theme.colors.aloe};
      margin-bottom: 10px;
      text-decoration: none;

      &.outline {
        background: transparent;
        border: 1px solid ${theme.colors.forest};
        color: ${theme.colors.forest};
      }
    }

    .link-sep {
      font-size: 12px;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 10px;
      display: block;
    }
  }
`;

const content = {
  SE: {
    title: 'Bli medlem eller logga in i Life Club',
    text:
      'Som medlem får du tillgång till de allra senaste inom kosttillskott, hälsa och skönhet. Det är såklart helt kostnadsfritt att bli medlem. Välkommen till Life Club!',
    usps: [
      '10% rabatt* på ett köp som ny medlem',
      'Bonuspoäng på alla köp',
      'Exklusiva rabatter och erbjudanden'
    ],
    disclaimer:
      '*10 % välkomstrabatt på ett köp och är giltig i 3 månader från registrering av medlemskapet. Erbjudandet gäller endast för nya medlemmar i Life Club och kan inte kombineras med andra erbjudanden. Gäller ej naturläkemedel och elektronikprodukter.'
  },
  NO: {
    title: 'Bli medlem av Life Club og logg inn',
    text:
      'Som medlem får du tilgang til det aller siste innen kosttilskudd, helse og skjønnhet. Det er selvfølgelig helt gratis å bli medlem. Velkommen til Life Club!',
    usps: [
      '10 % rabatt* på et kjøp som nytt medlem',
      'Bonuspoeng på alle kjøp',
      'Eksklusive rabatter og tilbud'
    ],
    disclaimer:
      '*10% velkomstrabatt på et kjøp og er gyldig i 3 mnd fra registrering av nytt medlemskap. Rabatten gjelder kun for nye Life Club-medlemmer og kan ikke kombineres med andre tilbud. Gjelder ikke naturlegemidler og elektroniske produkter.'
  },
  FI: {
    title: 'Liity Life Clubiin ja kirjaudu sisään',
    text:
      'Jäsenenä saat tiedon uusimmista hyvinvointituotteista sekä hyvinvoinnin ja kauneuden trendeistä. Ja tietysti, liittyminen on täysin maksutonta. Tervetuloa Life Clubiin!',
    usps: [
      '10 % alennus* ensimmäisestä ostokerrasta',
      'Bonuspisteitä kaikista ostoksista',
      'Eksklusiivisia alennuksia ja tarjouksia'
    ],
    disclaimer:
      '*10% tervetuloalennus on käytettävissä vain kerran ja on voimassa 3 kuukautta jäseneksi rekisteröitymisestä. Tarjous on vain uusille Life Club -jäsenille. Etua ei voi yhdistää muihin tarjouksiin tai alennuksiin. Alennusta ei voi käyttää valikoimassa oleviin elektronisiin laitteisiin tai Aqva-tuotteisiin.'
  }
};

export const LifeClubFlyout = ({ close }) => {
  const { selectedChannel } = useChannelCheck();
  const { title, text, usps, disclaimer } = content[
    selectedChannel?.country?.code
  ];
  return (
    <>
      <FlyoutContentWrapper active={true} className={hideScrollbar}>
        <div className="header">
          <button className="close" onClick={() => close()}>
            {t('Close')} <SVG name={'Cross'} />
          </button>
          <div className="emblem">
            <img src={emblem} alt="Life Club Emblem" />
          </div>
          <div className="bg">
            <Image
              crop={true}
              aspect={'45:30'}
              cover={true}
              src={bg}
              alt="Life Club"
            />
          </div>
        </div>
        <div className="content">
          <h2>{title}</h2>

          <p>{text}</p>

          <div className="list">
            {usps.map((usp, index) => (
              <div key={index} className="list-item">
                <span className="icon">
                  <SVG name="UspCheck" />
                </span>
                <span className="text">{usp}</span>
              </div>
            ))}
          </div>

          <div className="cta-wrapper">
            <Link className="cta" to="/login" onClick={() => close()}>
              {t('Sign up')}
            </Link>
            <Link className="cta outline" to="/login" onClick={() => close()}>
              {t('Log in')}
            </Link>
          </div>

          <p className="disclaimer">{disclaimer}</p>
        </div>
      </FlyoutContentWrapper>
    </>
  );
};
