import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { ButtonInternal } from '../../Button';
import { theme } from '../../Theme';
import { toRem } from '../../../helpers';
import useChildren from './../../../hooks/useChildren';

const ArticleMainWrapper = styled('div')`
  padding-right: 0;
  padding-left: 0;

  ${theme.above.md} {
    padding: 0.5rem 5rem;
    padding-right: ${toRem(116)};
    padding-left: ${toRem(116)};
  }
`;

const TextRightWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;

  ${theme.below.lg} {
    padding: 0;
  }

  ${theme.above.md} {
    flex-direction: row-reverse;
  }
  .article-text-block {
    padding-left: 2.5rem;
    ${theme.above.md} {
      width: 50%;
      padding-right: ${toRem(8)};
    }
  }
`;

const TextLeftWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;

  ${theme.below.lg} {
    padding: 0;
  }

  ${theme.above.md} {
    flex-direction: row;
  }
  .article-text-block {
    padding-right: 2.5rem;
    ${theme.above.md} {
      width: 50%;
      padding-left: 0;
    }
  }
`;

const TextCenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${toRem(48)};

  ${theme.above.md} {
    align-items: center;
  }

  ${theme.below.lg} {
    padding: 0;
  }

  .article-text-block {
    display: flex;
    flex-direction: column;
    max-width: ${toRem(736)};
    ul {
      margin: 10px;
    }

    li {
      list-style: inside;
      color: ${theme.colors.black};
    }

    ${theme.above.md} {
      align-items: center;
    }
  }
`;

const SectionTitle = styled.h2`
  font-family: ${theme.fonts.primaryFontFamily};
  font-size: 32px;
  line-height: 120%;
  font-weight: bold;
  margin-bottom: ${toRem(24)};

  text-align: ${props => (props.textAlign === 'center' ? `center` : `left`)};
  color: ${theme.colors.black};
  ${theme.below.md} {
    text-align: left;
  }
`;

const SectionText = styled.div`
  /* text-align: ${props =>
    props.textAlign === 'center' ? `center` : `left`}; */
  text-align: left;
  max-width: ${props => (props.textAlign === 'center' ? toRem(736) : `auto`)};
  color: ${theme.colors.black};


  a {
      color: ${theme.colors.forest};
    }

  ${theme.below.md} {
    text-align: left;
  }
`;

const TextWrapper = styled.div`
  box-sizing: border-box;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: ${props => getImagePlacement(props.imagePlacement)};
  height: 100%;

  ${theme.above.md} {
    width: 50%;
  }

  .pic-text {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 3px;
    font-size: 13px;
    font-style: italic;
    color: #004e42;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: ${toRem(24)};
`;

const Button = styled(ButtonInternal)`
  display: inline-block;
  padding-top: ${toRem(11)};
  font-weight: 700;

  ${theme.below.md} {
    padding-top: ${toRem(13)};
    padding-bottom: 0;
  }
`;

const getImagePlacement = placement => {
  switch (placement) {
    case 'center':
      return 'center';
    case 'bottom':
      return 'end';
    default:
      return 'start';
  }
};

const ArticlePageSection = props => {
  const cleanedProps = useChildren(props);
  const {
    blockText,
    buttonLabel,
    buttonLink,
    image,
    imageDescription,
    belowImageText,
    textPlacement,
    imagePlacement,
    imageAspectRatio,
    title,
    script
  } = cleanedProps;

  const componentContent = (
    <>
      <TextWrapper className="article-text-block">
        {title && (
          <SectionTitle textAlign={textPlacement}>{title}</SectionTitle>
        )}
        {blockText && (
          <SectionText
            textAlign={textPlacement}
            dangerouslySetInnerHTML={{ __html: blockText }}
          />
        )}
        {script && (
          <SectionText
            textAlign={textPlacement}
            dangerouslySetInnerHTML={{ __html: script }}
          />
        )}
        {buttonLabel && buttonLink && (
          <ButtonWrapper>
            <Button className="primary dark" href={buttonLink}>
              {buttonLabel}
            </Button>
          </ButtonWrapper>
        )}
      </TextWrapper>
      {textPlacement !== 'center' && image && (
        <>
          <ImageWrapper imagePlacement={imagePlacement}>
            <Image
              src={image}
              alt={imageDescription || title || ''}
              aspect={imageAspectRatio || '4:3'}
              sizes={[1, 1 / 2, 1 / 4, 604]}
              cover
            />
            {belowImageText && (
              <span className="pic-text">{belowImageText}</span>
            )}
          </ImageWrapper>
        </>
      )}
    </>
  );
  let articleBlockComponent;
  if (textPlacement === 'center') {
    articleBlockComponent = (
      <TextCenteredWrapper>{componentContent}</TextCenteredWrapper>
    );
  } else if (textPlacement === 'right') {
    articleBlockComponent = (
      <TextRightWrapper>{componentContent}</TextRightWrapper>
    );
  } else
    articleBlockComponent = (
      <TextLeftWrapper>{componentContent}</TextLeftWrapper>
    );

  return (
    <ArticleMainWrapper className={'article-main-wrapper'}>
      {articleBlockComponent}
    </ArticleMainWrapper>
  );
};

export default ArticlePageSection;
