import { css } from 'linaria';
import { theme } from '../../../Theme';

// Use linaria css to style the component.
// All styling should be placed in :global().
// Begin all styling with root class name of component.

css`
  :global() {
    .hero {
      position: relative;
      padding: 0;
      width: 100%;
      max-width: ${theme.layout.maxWidth};
      margin: auto;

      &.fullWidth {
        max-width: 100%;
      }

      ${theme.below.lg} {
        padding: 0;
        max-width: 100%;
      }
      .content-positioner-container {
        max-width: 500px !important;
      }

      &.content-darken-gradient {
        &.middle {
          --gradient-angle: 0deg;
          --gradient-opacity: 0.3;
          --gradient-opacity-zero: 0;

          &.left {
            --gradient-angle: 90deg;
            :after {
              top: 0;
              right: auto;
              left: 0;
              bottom: 0;
              height: 100%;
              width: 50%;
              background: linear-gradient(
                var(--gradient-angle),
                rgba(0, 0, 0, var(--gradient-opacity)) 0%,
                rgba(0, 0, 0, var(--gradient-opacity)) 33%,
                rgba(0, 0, 0, var(--gradient-opacity)) 50%,
                rgba(0, 0, 0, var(--gradient-opacity)) 66%,
                rgba(0, 0, 0, var(--gradient-opacity-zero)) 100%
              );
            }
          }

          &.right {
            --gradient-angle: 270deg;
            :after {
              top: 0;
              right: 0;
              left: auto;
              bottom: 0;
              height: 100%;
              width: 50%;
              background: linear-gradient(
                var(--gradient-angle),
                rgba(0, 0, 0, var(--gradient-opacity)) 0%,
                rgba(0, 0, 0, var(--gradient-opacity)) 33%,
                rgba(0, 0, 0, var(--gradient-opacity)) 50%,
                rgba(0, 0, 0, var(--gradient-opacity)) 66%,
                rgba(0, 0, 0, var(--gradient-opacity-zero)) 100%
              );
            }
          }
        }
      }

      &.title-small {
        .content-positioner .content-positioner-container .content-title.hero {
          font-size: 26px;
          line-height: 37.41px;
        }
      }
      &.title-medium {
        .content-positioner .content-positioner-container .content-title.hero {
          font-size: 40px;
          line-height: 36.58px;
        }
      }
      &.title-large {
        .content-positioner .content-positioner-container .content-title.hero {
          font-size: 60px;
          line-height: 54.74px;
        }
      }
      &.title-x-large {
        .content-positioner .content-positioner-container .content-title.hero {
          font-size: 80px;
          line-height: 72.96px;
          @media (max-width: 500px) {
            font-size: 60px;
            line-height: 54.74px;
          }
        }
      }
      &.text-small {
        .content-positioner .content-positioner-container .content-text.hero {
          font-size: 16px;
          line-height: 1.3;
        }
      }
      &.text-medium {
        .content-positioner .content-positioner-container .content-text.hero {
          font-size: 18px;
          line-height: 1.3;
        }
      }
      &.text-large {
        .content-positioner .content-positioner-container .content-text.hero {
          font-size: 20px;
          line-height: 1.3;
        }
      }
      &.text-bold {
        .content-positioner .content-positioner-container .content-text.hero {
          font-weight: bold;
        }
      }
    }
  }
`;
