import { css } from 'linaria';

export const paymentMethodsRowStyle = css`
  width: 100%;
  padding: 1rem 0;

  .max-width {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;

    .icon {
      height: 100%;
      max-height: 40px;
      width: auto;
      max-width: 100px;
    }
  }
`;
