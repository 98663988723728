import { Spacer } from 'r3-lib/dist/r3lib';
import React from 'react';

export const SpacerExtended = ({ height, mobileSpacePercent }) => {
  return (
    <Spacer
      height={{ value: height?.value?.value ?? height.value }}
      mobileSpacePercent={{
        value: mobileSpacePercent?.value?.value ?? mobileSpacePercent.value
      }}
    />
  );
};
