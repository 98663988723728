import { css } from 'linaria';
import { theme } from '../../../Theme';

css`
  :global() {
    .cta {
      height: ${theme.button.desktop.height};
      width: fit-content;

      border-radius: ${theme.button.desktop.borderRadius};
      cursor: pointer;
      background: black;
      transition: all 0.3s ease-in-out;
      &.add-to-cart {
        width: 100%;
        height: 40px;
      }

      a,
      div,
      button {
        padding: ${theme.button.desktop.padding};
        width: 100%;
        color: white;
        text-decoration: none;
      }

      .link-evaluator {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${theme.fontSizes.desktop.p2};
        font-weight: ${theme.fontWeights.bold};
        line-height: 100%;
        overflow: hidden;

        .loader-dot {
          height: 6px !important;
          width: 6px !important;
          padding: 0 !important;
        }
      }

      &.white {
        background: ${theme.colors.white};
        a,
        div {
          color: ${theme.colors.black};
        }
      }

      &.forest {
        background: ${theme.colors.forest};
        a,
        div {
          color: ${theme.colors.white};
        }
      }
      &.plum {
        background: ${theme.colors.plum};
        a,
        div {
          color: ${theme.colors.white};
        }
      }

      &.guava {
        background: ${theme.colors.guava};
        a,
        div {
          color: ${theme.colors.white};
        }
      }

      &.aloe {
        background: ${theme.colors.aloe};
        a,
        div {
          color: ${theme.colors.forest};
        }
      }

      &.white_outline {
        border: 1px solid ${theme.colors.white};
        background: transparent;
        a,
        div {
          color: ${theme.colors.white};
        }
      }

      &.aloe_outline {
        border: 1px solid ${theme.colors.aloe};
        background: transparent;
        a,
        div {
          color: ${theme.colors.aloe};
        }
      }

      &.forest_outline {
        border: 1px solid ${theme.colors.forest};
        background: transparent;
        a,
        div {
          color: ${theme.colors.forest};
        }
      }

      &.plum_outline {
        border: 1px solid ${theme.colors.plum};
        background: transparent;
        a,
        div {
          color: ${theme.colors.plum};
        }
      }

      &.guava_outline {
        border: 1px solid ${theme.colors.guava};
        background: transparent;
        a,
        div {
          color: ${theme.colors.guava};
        }
      }

      :hover,
      :active {
        background: ${theme.colors.variants.black};

        &.aloe {
          background: ${theme.colors.variants.aloe};
        }
        &.white {
          background: ${theme.colors.variants.white};
        }

        &.forest {
          background: ${theme.colors.variants.forest};
        }
        &.plum {
          background: ${theme.colors.variants.plum};
        }

        &.guava {
          background: ${theme.colors.variants.guava};
        }
        &.white_outline {
          background: ${theme.colors.variants.white};
          a,
          div {
            color: ${theme.colors.variants.black};
          }
        }

        &.forest_outline {
          background: ${theme.colors.variants.forest};
          a,
          div {
            color: ${theme.colors.variants.white};
          }
        }
        &.aloe_outline {
          background: ${theme.colors.variants.aloe};
          a,
          div {
            color: ${theme.colors.variants.forest};
          }
        }
        &.plum_outline {
          background: ${theme.colors.variants.plum};
          a,
          div {
            color: ${theme.colors.variants.white};
          }
        }

        &.guava_outline {
          background: ${theme.colors.variants.guava};
          a,
          div {
            color: ${theme.colors.white};
          }
        }
      }

      :active {
        transform: scaleY(0.95);
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
`;
