import {
  CampaignBarItem,
  HtmlRowItem,
  PlaceholderChildComponent,
  StyledBoxRow,
  StyledCampaignBar
} from 'r3-lib/dist/r3lib';
import { FooterContent } from '../Layout/Footer/FooterContent/FooterContent';
import {
  FooterLinksColumn,
  FooterLinksColumnItem
} from '../Layout/Footer/FooterLinksColumn/FooterLinksColumn';

import { SocialMediaRow } from '../Layout/Footer/SocialMediaRow/SocialMediaRow';
import { PaymentMethodsRow } from '../Layout/Footer/PaymentMethodsRow/PaymentMethodsRow';
import Seo from './Seo';
import NewCategoryHeader from './NewCategoryHeader';
import NewFinnishCategoryHeader from './NewFinnishCategoryHeader';
import { ProductRow } from './ProductRow/ProductRow';
import { ProductRowItem } from './ProductRow/ProductRowItem';
import { CategoriesRow } from './CategoriesRow';
import { CategoriesRowItem } from './CategoriesRowItem';
import { IconCardRowItem } from './IconCardRow/IconCardRowItem';

//old content components
import ArticlePageSection from './OldComponents/ArticlePageSection';
import ArticleBlockLarge from './OldComponents/ArticleBlockLarge';
import ArticleBlockMedium from './OldComponents/ArticleBlockMedium';
import ArticleListingGrid from './OldComponents/ArticleListingGrid';
import ArticleListing from './OldComponents/ArticleListing';
import ArticleCategoryLinks from './OldComponents/ArticleCategoryLinks';
import HeroThree from './OldComponents/HeroThree';
import HeroOne from './OldComponents/HeroOne';
import CampaignSmall from './OldComponents/CampaignSmall';
import CategoryLinksWithImagesSection from './OldComponents/CategoryLinksWithImagesSection';
import CategoryLinksWithLinksSection from './OldComponents/CategoryLinksWithLinksSection';
import CategoryLinks from './OldComponents/CategoryLinks';
import { OldHtmlRow } from './OldComponents/HtmlRow';

import { CollageRow, CollageRowItem } from './CollageRow';
import { CustomBoxRowImageItem } from './CustomBoxRowImageItem';
import { CustomBoxRowColorItem } from './CustomBoxRowColorItem';
import { CustomHero } from './CustomHero';
import { PopupTrigger } from './PopupTrigger';
import { FooterBadge } from '../Layout/Footer/FooterBadge/FooterBadge';
import { FAQ, FAQItem } from './FAQ';
import loadable from '@loadable/component';
import { ScriptLoader } from './ScriptLoader';
import { SpacerExtended } from './SpacerExtended';
import { HeaderBlock } from './HeaderBlock';
import { Quote } from './Quote';
import { Title } from './Title';
import { ColumnText } from './ColumnText';
import { ElevateRecommendedProducts } from '../ProductPage/components/RecommendedProducts/RecommendedProducts';
import { CustomHTMLRow } from './CustomHTMLRow';
import { SiteWideBanner } from './SiteWideBanner';
import { SiteWideBannerItem } from './SiteWideBannerItem';
import {
  CustomSubcategoryLinks,
  CustomSubcategoryLinksItem
} from './CustomSubcategoryLinks';

export const LoadableStyledVidepHero = loadable(() => import('./VideoHero'), {
  resolveComponent: StyledVidepHero => StyledVidepHero.StyledVidepHero
});

export const allContentComponents = {
  'VITA-HERO': CustomHero,
  'VITA-SPACER': SpacerExtended,
  'VITA-IMAGEBOXROW': StyledBoxRow,
  'VITA-BOXROWIMAGEITEM': CustomBoxRowImageItem,
  'VITA-PRODUCTROW': ProductRow,
  'VITA-PRODUCTROWITEM': ProductRowItem,
  'VITA-POPULARCATEGORIESROW': CategoriesRow,
  'VITA-POPULARCATEGORIESROWITEM': CategoriesRowItem,
  'VITA-HTMLROW': CustomHTMLRow,
  'VITA-WYSIWYG': HtmlRowItem,
  'VITA-RAWHTML': HtmlRowItem,
  'VITA-BOXROWCOLOR': StyledBoxRow,
  'VITA-BOXROWCOLORITEM': CustomBoxRowColorItem,
  'VITA-ONEOFEACH': StyledBoxRow,
  'VITA-ICONCARDROW': StyledBoxRow,
  'VITA-ICONCARDITEM': IconCardRowItem,
  'VITA-COLLAGEROW': CollageRow,
  'VITA-COLLAGEROWITEM': CollageRowItem,
  'VITA-VIDEOHERO': LoadableStyledVidepHero,
  'VITA-FAQ': FAQ,
  'VITA-FAQITEM': FAQItem,
  'VITA-SCRIPTLOADER': ScriptLoader,
  'VITA-SCRIPTLOADERATTRIBUTE': PlaceholderChildComponent,
  'VITA-COLUMNTEXT': ColumnText,
  'VITA-QUOTE': Quote,
  'VITA-HEADERBLOCK': HeaderBlock,
  'VITA-TITLE': Title,
  'ARTICLE BLOCK LARGE': ArticleBlockLarge,
  'ARTICLE BLOCK MEDIUM': ArticleBlockMedium,
  'ARTICLE BOX MEDIUM': 'ARTICLE_BOX_MEDIUM',
  'ARTICLE CONTENT BLOCK': ArticlePageSection,
  'ARTICLE LISTING': ArticleListingGrid,
  'ARTICLE LISTING STARTPAGE': ArticleListing,
  'ARTICLE PAGE CATEGORY LINKS': ArticleCategoryLinks,
  'HERO ONE': HeroOne,
  'HERO THREE': HeroThree,
  'SMALL CAMPAIGN': 'SMALL_CAMPAIGN',
  'LARGE CAMPAIGN': 'LARGE_CAMPAIGN',
  'CAMPAIGN SMALL': CampaignSmall,
  'PRODUCT RECOMMENDATIONS': ElevateRecommendedProducts,
  'NAVIGATION LINKS WITH IMAGES': CategoryLinksWithImagesSection,
  'NAVIGATION LINK WITH IMAGE': 'NAVIGATION_LINK_WITH_IMAGE',
  'NAVIGATION LINKS WITH SUB LINKS': CategoryLinksWithLinksSection,
  'NAVIGATION LINK WITH SUB LINKS': 'NAVIGATION_LINK_WITH_SUB_LINKS',
  'CATEGORY LINKS': CategoryLinks,
  'CATEGORY LINK': 'CATEGORY_LINK',
  HTMLROW: OldHtmlRow,
  'HEADER ARTICLE PAGE': NewCategoryHeader,
  'HEADER CATEGORY PAGE': NewFinnishCategoryHeader,
  'ELEVATE PRODUCT RECOMMENDATIONS': ElevateRecommendedProducts,
  'VITA-CUSTOM-SUBCATEGORYLINKS': CustomSubcategoryLinks,
  'VITA-CUSTOM-SUBCATEGORYLINKSITEM': CustomSubcategoryLinksItem
};

export const popupComponents = {
  'VITA-POPUP': PopupTrigger
};

export const cartComponents = {
  'VITA-HTMLROW': CustomHTMLRow,
  'VITA-WYSIWYG': HtmlRowItem,
  'VITA-RAWHTML': HtmlRowItem,
  'VITA-PAYMENTMETHODS': PaymentMethodsRow
};

export const campaignbarComponents = {
  'VITA-CAMPAIGNBAR': StyledCampaignBar,
  'VITA-CAMPAIGNBARITEM': CampaignBarItem
};

export const footerComponents = {
  'VITA-FOOTER-1': FooterContent,
  'VITA-FOOTER-2-0-COLUMN': FooterLinksColumn,
  'VITA-FOOTER-2-1-COLUMNITEM': FooterLinksColumnItem,
  'VITA-PAYMENTMETHODS': PaymentMethodsRow,
  'VITA-SOCIALMEDIALINKS': SocialMediaRow,
  'VITA-FOOTERBADGE': FooterBadge
};

export const seoComponents = {
  //old seo component
  'ARTICLE CONTENT BLOCK': Seo
};

export const categoryHeaderComponents = {
  'HEADER ARTICLE PAGE': NewCategoryHeader,
  'HEADER CATEGORY PAGE': NewFinnishCategoryHeader
};

export const oldContentComponents = {
  'ARTICLE BLOCK LARGE': ArticleBlockLarge,
  'ARTICLE BLOCK MEDIUM': ArticleBlockMedium,
  'ARTICLE BOX MEDIUM': 'ARTICLE_BOX_MEDIUM',
  'ARTICLE CONTENT BLOCK': ArticlePageSection,
  'ARTICLE LISTING': ArticleListingGrid,
  'ARTICLE LISTING STARTPAGE': ArticleListing,
  'ARTICLE PAGE CATEGORY LINKS': ArticleCategoryLinks
};

export const veryOldContentComponents = {
  'CAMPAIGN SMALL': CampaignSmall,
  'HERO ONE': HeroOne,
  'HERO THREE': HeroThree,
  'LARGE CAMPAIGN': 'LARGE_CAMPAIGN',
  'SMALL CAMPAIGN': 'SMALL_CAMPAIGN'
};

export const SiteWideBannerComponents = {
  SITEWIDEBANNER: SiteWideBanner,
  SITEWIDEBANNERITEM: SiteWideBannerItem,
  'VITA-SPACER': SpacerExtended
};
