import { css } from 'linaria';
import { theme } from '../../../Theme';

css`
  :global() {
    .collage-row {
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(4, 10vw);
      max-width: calc(${theme.layout.maxWidth} + 40px);
      width: 100%;

      &:not(.fullwidth) {
        padding: 0 20px;
      }

      ${theme.above.xl} {
        &:not(.fullwidth) {
          grid-template-rows: repeat(4, 8rem);
        }
      }

      ${theme.below.lg} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 40vw);

        display: block;
        > * {
          display: block;
          aspect-ratio: 1;
          width: 100%;
          height: 100%;
          margin-bottom: 10px;
          > * {
            height: 100%;
            width: 100%;
          }
        }
      }

      &.fullwidth {
        max-width: 100%;
      }

      &.items-4 {
        .div-one {
          grid-area: 1 / 1 / 5 / 5;
        }
        .div-two {
          grid-area: 1 / 5 / 3 / 9;
        }
        .div-three {
          grid-area: 3 / 5 / 5 / 7;
        }
        .div-four {
          grid-area: 3 / 7 / 5 / 9;
        }
        ${theme.below.lg} {
          .div-one {
            grid-area: 1 / 1 / 3 / 3;
          }
          .div-two {
            grid-area: 3 / 1 / 4 / 3;
          }
          .div-three {
            grid-area: 4 / 1 / 5 / 2;
          }
          .div-four {
            grid-area: 4 / 2 / 5 / 3;
          }
        }
      }

      &.items-3 {
        .div-one {
          grid-area: 1 / 1 / 5 / 5;
        }
        .div-two {
          grid-area: 1 / 5 / 3 / 9;
        }
        .div-three {
          grid-area: 3 / 5 / 5 / 9;
        }
        ${theme.below.lg} {
          .div-one {
            grid-area: 1 / 1 / 3 / 3;
          }
          .div-two {
            grid-area: 3 / 1 / 4 / 3;
          }
          .div-three {
            grid-area: 4 / 1 / 5 / 3;
          }
        }
      }

      &.items-2 {
        .div-one {
          grid-area: 1 / 1 / 5 / 5;
        }
        .div-two {
          grid-area: 1 / 5 / 5 / 9;
        }
        ${theme.below.lg} {
          .div-one {
            grid-area: 1 / 1 / 3 / 3;
          }
          .div-two {
            grid-area: 3 / 1 / 5 / 3;
          }
        }
      }

      &.items-1 {
        .div-one {
          grid-area: 1 / 1 / 5 / 9;
        }
        ${theme.below.lg} {
          .div-one {
            grid-area: 1 / 1 / 5 / 3;
          }
        }
      }
    }
  }
`;
