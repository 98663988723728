import React, { useState } from 'react';
import { theme } from '../../../Theme';
import { styled } from 'linaria/react';
import { cx, css } from 'linaria';
import t from '@jetshop/intl';
import { Loader, useModalManagement } from 'r3-lib/dist/r3lib';
import { useChannelSettings } from '@jetshop/core/hooks/Channels/useChannelSettings';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { CartStatus } from '../Cart/CartStatus';
import { CartProductCard } from '../Cart/CartProductCard/CartProductCard';
import { Price } from '@jetshop/ui/Price';
import { DynamicCategoryRenderer } from '../../../ContentEditor/DynamicCategoryRenderer/DynamicCategoryRenderer';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import { useVoyadoTracker } from '../../../../useVoyadoTracker';
import { hideScrollbar } from '../styles/scrollBar.style';
import { PaymentMethodsRow } from '../../../Layout/Footer/PaymentMethodsRow/PaymentMethodsRow';
import { FreightStatus } from '../Cart/FreightStatus';
import { SVG } from '../../../../assets/SVG';
import { useOverflowEvaluator } from '../../../../hooks/useOverflowEvaluator';

export const closeButtonStyle = css`
  background: transparent;
  font-size: 15px;
  font-weight: ${theme.fontWeights.regular};
  line-height: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  height: fit-content;
  margin-bottom: 2px;

  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: black;
      stroke-width: 1px;
    }
  }
`;
export const FlyoutContentWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: ${theme.colors.white};

  .cart-status {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .flyout-cart-layout-scroll-title-row {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: end;

    h4 {
      font-size: 20px;
      font-weight: ${theme.fontWeights.medium};
      line-height: 100%;

      span {
        font-size: 16px;
        font-weight: ${theme.fontWeights.regular};
      }
    }
  }

  .flyout-cart-top {
    height: 82px;
  }

  .flyout-cart-layout-items {
    height: 100%;
    overflow: scroll;
  }

  .cart-lower {
    height: auto;
    padding: 10px 20px 20px 20px;
    position: relative;
    transition: all 0.5s ease-in-out;

    &.disable-checkout-area {
      .cart-total {
        opacity: 0.5;
        pointer-events: none;

        .total {
          opacity: 0.5;
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      height: 0px;
      width: 100%;
      left: 0;
      background: rgba(0, 0, 0, 0);
      pointer-events: none;
    }

    &.show-top-gradient {
      &::before {
        height: 30px;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.05) 35%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    &.hide-top-gradient {
      &::before {
        height: 0px;
        background: rgba(0, 0, 0, 0);
      }
    }

    .summary {
      .discount  {
        display: flex;
        justify-content: space-between;
        font-size: ${theme.fontSizes.desktop.p2};
        line-height: 24px;
        color: ${theme.colors.black};
        opacity: 0.7;
      }

      .cart-total {
        .total,
        .vat {
          display: flex;
          justify-content: space-between;
          font-weight: ${theme.fontWeights.medium};
        }

        .total {
          font-size: ${theme.fontSizes.desktop.p1};
          font-weight: ${theme.fontWeights.medium};
        }
        .vat {
          font-size: ${theme.fontSizes.desktop.p2};
        }
      }

      .cart-buttons {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .cta {
          width: 100%;
          height: 45px;
          color: white;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          line-height: 45px;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;

          .loader {
            height: 12px;
            width: 60px;
          }
          .loader-dot {
            height: 6px !important;
            width: 6px !important;
            padding: 0 !important;
          }
        }
      }
    }

    .cart-payment-information {
      margin: 12px 0 0 0;

      .payment-method-row {
        padding: 0;
        > div {
          gap: 20px;
          padding-bottom: 0;
        }
      }

      svg {
        height: 100%;
        max-height: 30px;
        width: auto;
        max-width: 75px;
      }
    }
  }

  .cart-out-of-stock {
    color: ${theme.colors.red};
    padding-bottom: 0.25rem;
    font-weight: ${theme.fontWeights.medium};
  }
`;

export const FlyoutCartLayout = ({ result, cart }) => {
  const [checkoutBtnClicked, setCheckoutBtnClicked] = useState(false);

  const { closeAll } = useModalManagement();

  const items = cart?.items;
  const totalQuantity = cart?.totalQuantity;
  const checkoutUrl = cart?.externalCheckoutUrl;

  const { pricesIncVat } = useChannelSettings();
  const track = useTracker();

  useVoyadoTracker();

  const [scrolledToItemsEnd, setScrolledToItemsEnd] = useState(false);

  const [overflowRef, { isOverflowingY }] = useOverflowEvaluator(items?.length);

  const handleScroll = e => {
    const bottom =
      e?.target?.scrollHeight - e?.target?.scrollTop ===
      e?.target?.clientHeight;
    setScrolledToItemsEnd(bottom);
  };

  return (
    <FlyoutContentWrapper>
      <CartStatus result={result}>
        <div className="flyout-cart-top">
          <div className="flyout-cart-layout-scroll-title-row">
            <h4 className="cart-title">
              {t(`Cart`)} <span>{`(${totalQuantity})`}</span>
            </h4>
            <button
              className={closeButtonStyle}
              onClick={() => {
                closeAll();
              }}
            >
              {t('Close')} <SVG name={'Cross'} />
            </button>
          </div>

          <FreightStatus cartTotal={cart?.productTotal ?? 0} />
        </div>
        <div
          id={'cart-items-list'}
          ref={overflowRef}
          className={cx('flyout-cart-layout-items', hideScrollbar)}
          onScroll={e => handleScroll(e)}
        >
          {items?.map((item, index) => (
            <CartProductCard
              key={item?.id + index}
              item={item}
              linkClickCallback={() => {
                closeAll();
              }}
            />
          ))}
        </div>

        <div
          className={cx(
            'cart-lower',
            cart?.totalQuantity < 1 && 'disable-checkout-area',
            isOverflowingY && !scrolledToItemsEnd
              ? 'show-top-gradient'
              : 'hide-top-gradient'
          )}
        >
          <div className="summary">
            {cart?.discountTotal?.incVat > 0 && (
              <div className="discount">
                <label className="discount-name">{t('Discounts')}</label>
                <Price
                  price={{
                    incVat: cart?.discountTotal?.incVat
                  }}
                  negative
                />
              </div>
            )}

            <div className="cart-total">
              <div className="total">
                <label>
                  {pricesIncVat ? t('Total incl. VAT') : t('Total excl. VAT')}
                </label>
                <Price price={cart?.productTotal} />
              </div>

              <div className="cart-buttons">
                <a
                  data-testid="checkout-button"
                  className={cx('cta forest')}
                  href={checkoutUrl}
                  onClick={() => {
                    setCheckoutBtnClicked(true);
                    track(
                      trackCartCheckoutEvent({
                        cart: cart,
                        callback: () => {
                          closeAll();
                          window.location = checkoutUrl;
                        }
                      })
                    );
                  }}
                >
                  {checkoutBtnClicked ? (
                    <Loader color="white" />
                  ) : (
                    t('Go to checkout')
                  )}
                </a>
              </div>
            </div>
            <div className="cart-payment-information">
              {process?.env?.REACT_APP_VITA_CART_CATEGORY_ID?.length > 0 && (
                <DynamicCategoryRenderer
                  categoryId={process?.env?.REACT_APP_VITA_CART_CATEGORY_ID}
                  rendererComponents={{
                    'VITA-PAYMENTMETHODS': PaymentMethodsRow
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </CartStatus>
    </FlyoutContentWrapper>
  );
};
