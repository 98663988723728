import React from 'react';
import { Above } from '@jetshop/ui/Breakpoints';
import { cx } from 'linaria';
import { boxRowVideoItemStyles } from './BowRowVideoItem.style';
import { Videoplayer } from '../Videoplayer';
import {
  CTA,
  ContentPositioner,
  LinkEvaluator,
  aspectRatioAdjustment
} from 'r3-lib/dist/r3lib';

export const BoxRowVideoItem = ({
  desktopVideoUrl,
  mobileVideoUrl,
  desktopImageAspect,
  mobileImageAspect,
  verticalPosition,
  horizontalPosition,
  color,
  preTitle,
  title,
  boldTitle,
  h1,
  text,
  buttonText,
  buttonTheme,
  contentDarkenGradient,
  link,
  style,
  children
}) => {
  const Title = h1?.value ? 'h1' : 'h2';

  return (
    <div className={cx('box-row-video-item', style)}>
      <LinkEvaluator link={link}>
        <div className="box-video-wrapper">
          <Videoplayer
            desktopMediaRatio={desktopImageAspect?.value}
            mobileMediaRatio={mobileImageAspect?.value}
            desktopVideoUrl={desktopVideoUrl}
            mobileVideoUrl={mobileVideoUrl}
          />
        </div>
        <ContentPositioner
          verticalPosition={verticalPosition}
          horizontalPosition={horizontalPosition}
          contentDarkenGradient={contentDarkenGradient}
          color={color}
        >
          {preTitle?.value && (
            <div
              className="content-pre-title"
              dangerouslySetInnerHTML={{
                __html: preTitle?.value
              }}
            />
          )}
          {title?.value && (
            <Title
              className={'content-title'}
              dangerouslySetInnerHTML={{
                __html: title?.value
              }}
            />
          )}

          {text?.value && (
            <div
              className="content-text"
              dangerouslySetInnerHTML={{
                __html: text?.value
              }}
            />
          )}
          {buttonText?.value && (
            <CTA className={buttonTheme?.value}>{buttonText?.value}</CTA>
          )}
        </ContentPositioner>
        {children}
      </LinkEvaluator>
    </div>
  );
};

export const StyledBoxRowvideoItem = ({
  desktopVideoUrl,
  mobileVideoUrl,
  desktopImageAspect,
  mobileImageAspect,
  verticalPosition,
  horizontalPosition,
  color,
  preTitle,
  title,
  boldTitle,
  h1,
  text,
  buttonText,
  buttonTheme,
  contentDarkenGradient,
  children,
  link
}) => {
  return (
    <BoxRowVideoItem
      desktopVideoUrl={desktopVideoUrl?.value}
      mobileVideoUrl={mobileVideoUrl?.value}
      desktopImageAspect={desktopImageAspect}
      mobileImageAspect={mobileImageAspect}
      verticalPosition={verticalPosition}
      horizontalPosition={horizontalPosition}
      color={color}
      preTitle={preTitle}
      title={title}
      boldTitle={boldTitle}
      h1={h1}
      text={text}
      buttonText={buttonText}
      buttonTheme={buttonTheme}
      contentDarkenGradient={contentDarkenGradient}
      link={link}
      children={children}
      style={boxRowVideoItemStyles}
    />
  );
};
