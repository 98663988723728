import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import { toRem } from '../../../../helpers';
import MainNavLink from './MainNavLink';
import SectionWrapper from './SectionWrapper';
import RenderSubLinks from './RenderSubLinks';

const Header = styled.h3`
  ${theme.fonts.desktop.h3}
  color: ${theme.colors.moss};
  margin-right: ${toRem(10)};

  ${theme.above.md} {
    margin-bottom: ${toRem(20)};
  }
`;

const DesktopNavLinks = ({ navSections }) => {
  return navSections.map(section => {
    const sectionSubLinks = [
      { title: section.link1Title, url: section.link1Url },
      { title: section.link2Title, url: section.link2Url },
      { title: section.link3Title, url: section.link3Url },
      { title: section.link4Title, url: section.link4Url },
      { title: section.link5Title, url: section.link5Url },
      { title: section.link6Title, url: section.link6Url },
      { title: section.link7Title, url: section.link7Url },
      { title: section.link8Title, url: section.link8Url },
      { title: section.link9Title, url: section.link9Url },
      { title: section.link10Title, url: section.link10Url }
    ];
    return (
      <SectionWrapper key={section.key}>
        <div>
          <Header>{section.title}</Header>
          <MainNavLink title={section.title} url={section.titleLink} />
          <RenderSubLinks links={sectionSubLinks} />
        </div>
      </SectionWrapper>
    );
  });
};

export default DesktopNavLinks;
