import React from 'react';
import { Hero } from 'r3-lib/dist/r3lib';
import { PromotionBadge } from '../ui/vita/PromotionBadge/PromotionBadge';
import { cx } from 'linaria';
import { Above } from '@jetshop/ui/Breakpoints';

export const CustomHero = props => {
  return (
    <Above breakpoint="lg">
      {matches => {
        const titleSizeClass = `title-${
          !matches && props?.mobileTitleSize?.value
            ? props?.mobileTitleSize?.value
            : props?.titleSize?.value
        }`;

        const textSizeClass = `text-${
          !matches && props?.mobileTextSize?.value
            ? props?.mobileTextSize?.value
            : props?.textSize?.value
        }`;
        const isTextBold = props?.boldText?.value ? 'text-bold' : '';

        return (
          <Hero
            style={cx(titleSizeClass, textSizeClass, isTextBold)}
            {...props}
          >
            {props?.promoBadgeText?.value?.length > 0 && (
              <PromotionBadge
                backgroundColor={props?.promoBadgeBackgroundColor}
                foregroundColor={props?.promoBadgeForegroundColor}
                text={props?.promoBadgeText}
                size={props?.promoBadgeSize}
              />
            )}
          </Hero>
        );
      }}
    </Above>
  );
};
