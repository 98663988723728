import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';
import t from '@jetshop/intl';
import Spinner from '../../ui/Spinner';
import theme from './Theme';

export const baseButtonStyles = `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  ${theme.fonts.h6};
  font-weight: 700;
  ${theme.above.md} {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    height: 2.375rem; /* for box-sizing: border-box to work */
  }
  ${theme.below.md} {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    height: 2.75rem; /* for box-sizing: border-box to work */
  }

  &.primary.light {
    background-color: ${theme.colors.aloe};
    color: ${theme.colors.forest};
    border: 2px solid ${theme.colors.aloe};

    svg path {
      fill: ${theme.colors.forest};
    }
    :hover {
      background-color: transparent;
      border: 2px solid ${theme.colors.aloe};
      color: ${theme.colors.aloe};
    }
  }

  &.primary.dark {
    background-color: ${theme.colors.seaweed};
    color: ${theme.colors.white};
    
    svg path {
      fill: ${theme.colors.white};
    }
    
    :hover {
      background-color: ${theme.colors.forest};
    }
  }

  &.secondary {
    background-color: transparent;
    color: ${theme.colors.seaweed};
    border: 2px solid ${theme.colors.seaweed};
    
    svg path {
      fill: ${theme.colors.seaweed};
    }

    :hover {
      color: ${theme.colors.aloe};
      border: 2px solid ${theme.colors.aloe};
      
      svg path {
        fill: ${theme.colors.aloe};
      }
    }
  }

  ${theme.below.md} {
    max-width: 100%;
  }
`;

export const baseLinkStyles = `
  color: ${theme.colors.black};
  background-color: inherit;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export const Button = styled('button')`
  ${baseButtonStyles};
`;

export const ButtonNoAction = styled('div')`
  ${baseButtonStyles};
`;

export const ButtonLink = styled(Link)`
  ${baseButtonStyles};
  text-decoration: none;
`;

export const LinkText = styled(Link)`
  ${baseLinkStyles}
`;

export const ButtonThatLooksLikeALink = styled('button')`
  ${baseLinkStyles}
`;

export const ButtonExternal = ({ children, ...aProps }) => {
  const ButtonA = styled('a')`
    ${baseButtonStyles};
    text-decoration: none;
  `;
  return (
    <ButtonA {...aProps} target="_blank" rel="noreferrer">
      {children}
    </ButtonA>
  );
};

export const LinkExternal = ({ children, ...aProps }) => {
  const ButtonA = styled('a')`
    ${baseLinkStyles}
    text-decoration: none;
  `;
  return (
    <ButtonA {...aProps} target="_blank" rel="noreferrer">
      {children}
    </ButtonA>
  );
};

export const ButtonInternal = ({ children, ...aProps }) => {
  const ButtonA = styled('a')`
    ${baseButtonStyles};
    text-decoration: none;
  `;
  return <ButtonA {...aProps}>{children}</ButtonA>;
};

export const ButtonAuto = props => {
  const { children, linkText, className } = props;

  if (linkText && typeof linkText === 'string') {
    if (linkText.indexOf('http') === 0) {
      return (
        <ButtonExternal href={linkText} className={className}>
          {children}
        </ButtonExternal>
      );
    } else if (linkText.indexOf('/') === 0) {
      return (
        <ButtonLink to={linkText} className={className}>
          {children}
        </ButtonLink>
      );
    }
  }
  return children;
};

export const AreaAuto = props => {
  const { children, linkText, ...aProps } = props;

  const StyledA = styled('a')`
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
  `;

  if (linkText && typeof linkText === 'string') {
    if (linkText.indexOf('http') === 0) {
      return (
        <StyledA href={linkText} target="_blank" rel="noreferrer" {...aProps}>
          {children}
        </StyledA>
      );
    } else if (linkText.indexOf('/') === 0) {
      return (
        <StyledA href={linkText} {...aProps}>
          {children}
        </StyledA>
      );
    }
  }
  return children;
};

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: ${theme.colors.white};
  }
`;

export const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  secondary,
  ...props
}) =>
  loading ? (
    <Button
      {...props}
      className={cx(props.className, secondary && 'secondary')}
    >
      <span>{loadingText}</span>
      <StyledSpinner />
    </Button>
  ) : (
    <Button
      {...props}
      className={cx(props.className, secondary && 'secondary')}
    />
  );
