import { useMemo } from 'react';

const normalizeKey = key => {
  const lowerCaseFirstLetter = key.replace(/(^.)(..)/, (match, _, string) => {
    return string === string?.toUpperCase() ? match : match?.toLowerCase();
  });
  const removeUnderscore = lowerCaseFirstLetter.replace(/_/g, '');

  return removeUnderscore;
};

const getFields = product => {
  const { customFields } = product;
  if (!customFields) return {};

  const arr = customFields.reduce(
    (
      accFields,
      { key, type, title, boolValue, stringValue, htmlValue, listValue }
    ) => {
      if (!key) return accFields;
      const normalizedKey = normalizeKey(key);
      switch (type) {
        case 'BOOL':
          return {
            ...accFields,
            [normalizedKey]: {
              type,
              value: boolValue,
              title,
              boolValue
            }
          };

        case 'STRING':
          return {
            ...accFields,
            [normalizedKey]: {
              type,
              value: stringValue,
              title,
              stringValue
            }
          };

        case 'HTML':
          return {
            ...accFields,
            [normalizedKey]: {
              type,
              value: htmlValue,
              title,
              htmlValue
            }
          };

        case 'LIST':
          return {
            ...accFields,
            [normalizedKey]: {
              type,
              value: listValue,
              title,
              listValue
            }
          };

        default:
          return accFields;
      }
    },
    {}
  );

  return arr;
};

export const useCustomFields = product => {
  return useMemo(() => {
    if (!product) return {};

    return getFields(product);
  }, [product]);
};

export const useCustomFieldsMultipleProducts = products => {
  return useMemo(() => {
    if (!products?.length > 0) return {};

    return products.map(product => ({
      ...product,
      ...getFields(product)
    }));
  }, [products]);
};
