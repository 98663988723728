import { css } from 'linaria';
import { theme } from '../../../Theme';

export const seoStyle = css`
  .article-main-wrapper {
    padding-right: 0;
    padding-left: 0;
  }

  .section-title {
    color: ${theme.colors.forest};
    margin-bottom: 20px;
    ${theme.below.md} {
      text-align: left;
    }
  }

  .section-text {
    p {
      margin-bottom: 20px;
    }

    h3,
    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      color: #333333;
      margin-bottom: 10px;
    }

    div {
      margin-bottom: 40px;
    }

    ${theme.below.lg} {
      text-align: left;

      h3 {
        font-size: 18px;
        line-height: 23px;
      }
    }
  }

  .text-wrapper {
    box-sizing: border-box;
  }

  .text-left-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;

    ${theme.above.md} {
      flex-direction: row;
    }

    .image {
      ${theme.above.md} {
        width: 50%;
        padding-left: 0;
      }
      .article-text-block {
        padding-right: 2.5rem;
      }
    }
  }

  .text-center-wrapper {
    display: flex;
    flex-direction: column;

    ${theme.above.md} {
      align-items: center;
    }

    .article-text-block {
      display: flex;
      flex-direction: column;

      ul {
        margin: 10px;
      }

      li {
        list-style: inside;
        color: ${theme.colors.forest};
      }

      ${theme.above.md} {
        align-items: center;
      }
    }
  }

  .text-right-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;

    ${theme.above.md} {
      flex-direction: row-reverse;
    }
    .article-text-block {
      padding-left: 2.5rem;
      ${theme.above.md} {
        width: 50%;
      }
    }
  }

  .image-wrapper {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    height: 100%;

    ${theme.above.lg} {
      width: 50%;
    }

    .pic-text {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 3px;
      font-size: 13px;
      font-style: italic;
      color: #004e42;
    }
  }

  .button {
    padding: 10px 30px;
    background: ${theme.colors.aloe};
    color: ${theme.colors.forest};
    margin-right: 10px;
    margin-top: 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    border-radius: 3px;
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background: ${theme.colors.forest};
      color: #ffffff;
    }
  }
`;
