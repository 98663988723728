import React from 'react';
import { styled } from 'linaria/react';
import ArticleCard from './ArticleCard';
import { theme } from '../../Theme';
import { toRem } from '../../../helpers';

const ArticleListingWrapper = styled('div')`
  ${theme.below.sm} {
    padding-right: 0;
    padding-left: 0;
  }

  ${theme.above.sm} {
    display: grid;
  }
`;
const GridContainer = styled('ul')`
  display: grid;
  column-gap: ${toRem(16)};
  row-gap: ${toRem(16)};
  align-items: stretch;

  ${theme.above.sm} {
    grid-template-columns: 1fr 1fr;
  }

  ${theme.above.md} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${theme.above.lg} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const ArticleListingGrid = props => {
  const subcategories = props.category?.value?.subcategories;
  if (!subcategories) return null;

  return (
    <ArticleListingWrapper>
      <GridContainer>
        {subcategories.map(category => (
          <ArticleCard key={category?.id} {...category} />
        ))}
      </GridContainer>
    </ArticleListingWrapper>
  );
};

export default ArticleListingGrid;
