import React from 'react';
import t from '@jetshop/intl';

import { Loader } from 'r3-lib/dist/r3lib';
import { css, cx } from 'linaria';
import { theme } from '../../../Theme';
import { useModalManagement } from '../Flyout/useModalManagement';
import { SVG } from '../../../../assets/SVG';
import { closeButtonStyle } from '../Flyout/FlyoutCartLayout';

const cartStatusStyle = css`
  &.cart-loading {
    padding: 0;
    margin: 20px;
    height: 20px;
  }
`;

export const CartStatus = ({ result, children }) => {
  if (result?.error) {
    return <CartError />;
  }

  if (result?.loading) {
    return <CartLoading />;
  }

  if (result?.data?.cart?.totalQuantity < 1) {
    return <CartEmpty />;
  }

  return <div className="cart-status">{children}</div>;
};

const emptyCartStyle = css`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: end;

  h4 {
    font-size: 20px;
    font-weight: ${theme.fontWeights.medium};
    line-height: 100%;
    margin: 0;
    padding: 0;
    height: 20px;
  }
`;

const CartEmpty = () => {
  const { closeAll } = useModalManagement();
  return (
    <div className={cx('cart-status', emptyCartStyle)}>
      <h4 className={cx('cart-empty', cartStatusStyle)}>
        {t('Cart is empty')}
      </h4>
      <button
        className={closeButtonStyle}
        onClick={() => {
          closeAll();
        }}
      >
        {t('Close')} <SVG name={'Cross'} />
      </button>
    </div>
  );
};

const CartLoading = () => {
  return (
    <div className={cx('cart-status', 'cart-loading', cartStatusStyle)}>
      <Loader color={theme.colors.black} />
    </div>
  );
};

const CartError = () => {
  return (
    <div>
      <h3 className={cx('cart-status', 'cart-error', cartStatusStyle)}>
        {t('We are unable to load your cart, please reload the page')}
      </h3>
    </div>
  );
};
