import { Headers } from 'node-fetch';
import { useEffect, useMemo, useState } from 'react';

export const useLipscoreMultipleProducsData = (products, fields) => {
  const [lipscoreData, setLipscoreData] = useState();
  const secret = process?.env?.REACT_APP_LIPSCORE_SECRET;
  const apiKey = process?.env?.REACT_APP_LIPSCORE_API_KEY;
  const productIds = products?.map(product => {
    return `internal_id[]=${product?.barcode}&`;
  });
  const productIdsString = productIds?.join('');
  const standardFields = fields ?? ['rating', 'review_count'];
  const fieldsString = standardFields?.join(',');
  const url = `https://api.lipscore.com/products/?${productIdsString}fields=${fieldsString}&api_key=${apiKey}`;

  const httpsHeaders = useMemo(() => {
    const headers = new Headers();
    headers?.append('X-Authorization', secret);
    return headers;
  }, [secret]);

  const options = useMemo(() => {
    return {
      method: 'GET',
      redirect: 'follow',
      headers: httpsHeaders
    };
  }, [httpsHeaders]);

  useEffect(() => {
    fetch(url, options)
      .then(response => {
        // check response
        return response?.json();
      })
      .then(data => {
        setLipscoreData(data);
      });
  }, [url, options]);
  return { lipscoreData };
};
