import { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const useChannelCheck = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const countryCode = selectedChannel?.country?.code;
  const isSE = countryCode === 'SE';
  const isNO = countryCode === 'NO';
  const isFI = countryCode === 'FI';

  return { isSE, isNO, isFI, selectedChannel };
};

export default useChannelCheck;
