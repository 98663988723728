import React from 'react';
import Image from '@jetshop/ui/Image/Image';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';

export const CategoriesRowItem = ({ image, title, link }) => {
  return (
    <div className="categories-row-item">
      <LinkEvaluator link={link}>
        <div className="categories-row-item-image-wrapper">
          <Image
            sizes={'200'}
            aspect={'1:1'}
            alt={image?.value}
            src={image?.value?.value ?? image?.value}
            fillAvailableSpace={true}
          />
        </div>

        <h4>{title?.value}</h4>
      </LinkEvaluator>
    </div>
  );
};
