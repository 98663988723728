import React from 'react';
import { useLipscoreMultipleProducsData } from '../../../hooks/useLipscoreProductData';

export const LipscoreProductsRatingFetcher = ({ products, children }) => {
  const cleanProducts = products?.map(product => {
    if (product?.products) {
      return {
        ...product.products?.[0],
        barcode: product?.products?.[0]?.custom?.GTIN?.[0]?.label
      };
    }
    return product;
  });

  const { lipscoreData } = useLipscoreMultipleProducsData(cleanProducts, [
    'rating',
    'review_count'
  ]);

  let productsWithRating = cleanProducts?.map(product => {
    const lipscoreProduct = lipscoreData?.find(
      lipscoreProduct => lipscoreProduct?.internal_id === product?.barcode
    );

    return lipscoreProduct
      ? {
          ...product,
          rating: lipscoreProduct?.rating,
          review_count: lipscoreProduct?.review_count
        }
      : { ...product };
  });
  return <div>{children(productsWithRating)}</div>;
};
