import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const ProductGalleryWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${props => props.gap};
  max-width: ${props => (props.fullWidth ? '100%' : '80%')};
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }

  ${theme.below.lg} {
    grid-template-columns: repeat(2, 1fr);
  }

  /* @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  } */

  .product-row-gallery-item {
    flex: 1;
    min-width: 250px !important;

    ${theme.below.lg} {
      min-width: 100px !important;
    }
  }
`;

export const ProductRowGallery = ({ products, gap, fullWidth }) => {
  return (
    <ProductGalleryWrapper gap={gap} fullWidth={fullWidth}>
      {products.map((product, index) => {
        return (
          <div key={index} className="product-row-gallery-item">
            {product}
          </div>
        );
      })}
    </ProductGalleryWrapper>
  );
};
