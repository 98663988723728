import { css } from 'linaria';

export const boxRowVideoItemStyles = css`
  position: relative;
  flex: 1;

  .box-video-wrapper {
    width: 100%;
    height: 100%;
    display: flex;

    > div {
      max-width: 100% !important;
    }

    .box-content-text {
      font-weight: 500;
      font-size: 28px;
      line-height: 35px;
    }
  }
`;
