import { Title } from 'r3-lib/dist/r3lib';
import React from 'react';

export const CategoriesRow = ({
  backgroundColor,
  foregroundColor,
  title,
  children
}) => {
  return (
    <section
      className="categories-row"
      style={{
        backgroundColor: backgroundColor?.value,
        color: foregroundColor?.value
      }}
    >
      <Title
        text={title}
        alignment={{ value: 'center' }}
        h1={{ value: false }}
      />
      <div className="categories-row-items">{children}</div>
    </section>
  );
};
