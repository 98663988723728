/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useElevate } from '../../../Elevate/Context';
import { StyledProductCard as ProductCard } from '../../../CategoryPage/ProductCard/ProductCard';
import { getProductStructure } from '../../../Elevate/utils/getProductStructure';
import { SharedSwiper } from '../../../Layout/Swiper/SharedSwiper';

export const RecommendationRow = ({ recos, title, id }) => {
  let recommendations = recos?.filter((rec, i) => {
    return rec?.id === id?.value;
  });

  if (!recommendations) {
    recommendations = recos;
  }

  if (!recommendations) return null;

  return (
    <>
      {recommendations?.map(list => {
        const products = list?.productGroups?.map(product => {
          return product?.products?.[0];
        });
        return (
          <div key={list?.id} style={{ padding: '1rem' }}>
            {title && (
              <section className="html-row">
                <div className="html-row-item-outer">
                  <div className="html-row-item-wrapper">
                    <h2 style={{ textAlign: 'center' }}>{title}</h2>
                  </div>
                </div>
              </section>
            )}
            <SharedSwiper>
              {products?.map(product => {
                return (
                  <ProductCard
                    product={getProductStructure(product)}
                    key={product?.key}
                  />
                );
              })}
            </SharedSwiper>
          </div>
        );
      })}
    </>
  );
};

export const ElevateRecommendedProducts = ({ id, algorithm, title, path }) => {
  const [recos, setRecos] = useState();
  const { getRecommendationData, api } = useElevate();
  useEffect(() => {
    if (api) {
      getRecommendationData({
        recoId: id?.value?.trim(),
        algorithm: algorithm?.value?.trim(),
        path: path?.value?.trim()
      }).then(data => {
        setRecos(data?.recommendationLists);
      });
    }
  }, [api]);

  if (!recos) return null;

  return (
    <div>
      <RecommendationRow recos={recos} title={title.value} id={id} />
    </div>
  );
};
