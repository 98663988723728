import React from 'react';
import { useScrollPosition } from '../hooks/useScrollPosition';

const HeaderHeightContext = React.createContext();

const HeaderHeightProvider = ({ headerId, children }) => {
  const [inited, setInited] = React.useState(false);
  const [topPos, setTopPos] = React.useState(0);
  const scroll = useScrollPosition();

  // TODO - check window resize? use hook.
  // TODO - Simplified.. needs to be improved
  React.useEffect(() => {
    const clientHeight = () => {
      const campaignBarHeight =
        typeof document !== 'undefined'
          ? document?.getElementById('top-campaign-bar')?.clientHeight || 0
          : 0;
      const headerHeight =
        typeof document !== 'undefined'
          ? document?.getElementById(headerId)?.clientHeight || 0
          : 0;
      return campaignBarHeight + headerHeight;
    };

    if (!inited) {
      setTopPos(clientHeight());
      setInited(true);
    } else {
      const topBarHeight = clientHeight();
      if (scroll < topBarHeight) {
        setTopPos(topBarHeight - scroll);
      } else if (scroll > topBarHeight) {
        setTopPos(0);
      }
    }
  }, [scroll, inited, headerId]);

  const value = { headerHeight: topPos };

  return (
    <HeaderHeightContext.Provider value={value}>
      {children}
    </HeaderHeightContext.Provider>
  );
};

export { HeaderHeightContext, HeaderHeightProvider };
