import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';

import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';

import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';

import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';

import ForgotPassword from './Auth/ForgotPassword.loadable';
import ResetPassword from './Auth/ResetPassword.loadable';
import SignUpPage from './Auth/Voyado/VoyadoSignup.loadable';
import VoyadoLookup from './Auth/Voyado/VoyadoLookup.loadable';
import CategoryPage from './CategoryPage/CategoryPage.loadable';
import ContentPage from './ContentPage/ContentPage.loadable';
import LoadingPage from './LoadingPage';
import LogOutPage from './LogOut.loadable';
import MyPages from './NewMyPages/MyPages.loadable';
import NavTreePage from './NavigationTree/NavTreePage.loadable';
import NotFoundPage from './NotFoundPage.loadable';
import PreviewRoute from './PreviewRoute.loadable';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPage from './ProductPage/ProductPage.loadable';
import SearchPage from './SearchPage/SearchPage.loadable';
import StartPage from './StartPage/StartPage.loadable';
import Store from './Store/Store.loadable';
import StoreLocator from './StoreLocator/StoreLocator.loadable';

import categoryPreviewQuery from './CategoryPreviewQuery.gql';
import pagePreviewQuery from './PagePreviewQuery.gql';
import productPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';

import { theme } from './Theme';
import loadFontCss from '../fonts/loadFontCss';
import '../globalStyles';
import { FaviconHandler } from './FaviconHandler';
import 'r3-lib/dist/style.css';
import { HeaderHeightProvider } from '../Temp/contexts/HeaderHeightContext';
import { QuickBuyProvider } from './Layout/QuickBuy/QuickBuyContext';

import { VoyadoProvider } from '@jetshop/flight-voyado';
import { useIntl } from '@jetshop/intl';
import { DynamicCategoryRenderer } from './ContentEditor/DynamicCategoryRenderer/DynamicCategoryRenderer';
import { popupComponents } from './ContentEditor/ContentComponents';
import { ElevateProvider, CustomerUpdater } from './Elevate/Context';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { FlyoutHolder } from './ui/vita/Flyout/FlyoutHolder';
import { OrderFromUrl } from './OrderFromUrl';

function Shop() {
  const { routes } = useShopConfig();
  const t = useIntl();
  const { loggedIn } = useAuth();

  const pageDefaultTitle = process?.env?.REACT_APP_PAGE_DEFAULT_TITLE;
  const pageCategoryTitle = process?.env?.REACT_APP_PAGE_CATEGORY_TITLE;
  const popupCategoryId = process.env.REACT_APP_POPUP_CATEGORY_ID;

  return (
    <GenericError>
      <ModalProvider>
        <QuickBuyProvider>
          <ElevateProvider>
            {loggedIn && <CustomerUpdater />}
            <HeaderHeightProvider headerId={'project-header'}>
              <Container>
                <LoadingBar color={'#2f80ed'} />
                <CustomFont
                  primaryFont={theme.fonts.primaryFontFamily}
                  injectCss={loadFontCss}
                />
                <Helmet
                  titleTemplate={pageCategoryTitle}
                  defaultTitle={pageDefaultTitle}
                />
                {popupCategoryId && (
                  <DynamicCategoryRenderer
                    categoryId={popupCategoryId}
                    rendererComponents={popupComponents}
                  />
                )}
                <ProductListProvider queries={productListQueries}>
                  <VoyadoProvider
                    providerOptions={{
                      activateOnSoftLogin: false
                    }}
                  >
                    <Header />
                    <OrderFromUrl />
                    <Content>
                      <PaginationProvider defaultProductsPerPage={24}>
                        <Switch>
                          <Route exact path="/" component={StartPage} />
                          <Route path={t('/favourites')}>
                            <Favourites />
                          </Route>
                          <Route
                            path={routes.search.path}
                            component={SearchPage}
                          />
                          <Route
                            path={routes.login.path}
                            component={VoyadoLookup}
                          />
                          <Route
                            path={routes.logout.path}
                            component={LogOutPage}
                          />
                          <Route
                            path={routes.signup.path}
                            component={SignUpPage}
                          />
                          <Route
                            exact
                            path={`${t(routes.stores.path)}/:slug`}
                            component={Store}
                          />
                          <Route
                            exact
                            path={t(routes.stores.path)}
                            component={StoreLocator}
                          />

                          <Route
                            path={routes.tree.path}
                            component={NavTreePage}
                          />
                          <Route
                            path={routes.myPages.path}
                            component={MyPages}
                          />
                          <Route
                            exact
                            path={routes.forgotPassword.path}
                            component={ForgotPassword}
                          />
                          <Route
                            path={`${routes.resetPassword.path}/:token`}
                            component={ResetPassword}
                          />
                          <Route
                            path="/preview"
                            render={props => (
                              <PreviewRoute
                                productQuery={productPreviewQuery}
                                categoryQuery={categoryPreviewQuery}
                                pageQuery={pagePreviewQuery}
                                productPage={ProductPage}
                                categoryPage={CategoryPage}
                                contentPage={ContentPage}
                                StartPage={StartPage}
                                {...props}
                              />
                            )}
                          />
                          <DynamicRoute
                            routeQuery={routeQuery}
                            productPage={ProductPage}
                            categoryPage={CategoryPage}
                            contentPage={ContentPage}
                            notFoundPage={NotFoundPage}
                            LoadingPage={LoadingPage}
                          />
                        </Switch>
                      </PaginationProvider>
                    </Content>
                    <Footer />
                    <FlyoutHolder />
                  </VoyadoProvider>
                </ProductListProvider>

                <ModalRoot />
                <ScrollRestorationHandler
                  ignoreForRouteTypes={[
                    'sortOrderChange',
                    'filterChange',
                    'paginationChange'
                  ]}
                />
              </Container>
            </HeaderHeightProvider>
          </ElevateProvider>
        </QuickBuyProvider>
      </ModalProvider>
      <FaviconHandler faviconFolderPath={'/favicon'} title={'Life'} />
    </GenericError>
  );
}

export default Shop;
