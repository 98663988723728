import React from 'react';
import { DynamicCategoryRenderer } from '../../ContentEditor/DynamicCategoryRenderer/DynamicCategoryRenderer';
import { cx } from 'linaria';
import { lifeClubStyle } from '../../ui/vita/styles/LifeClub.style';

export const LifeClub = () => {
  return (
    <div
      className={cx('life-club', lifeClubStyle)}
      style={{
        width: '100%'
      }}
    >
      {process?.env?.REACT_APP_FOOTER_LIFE_CLUB_CATEGORY_ID?.length > 0 && (
        <DynamicCategoryRenderer
          categoryId={process?.env?.REACT_APP_FOOTER_LIFE_CLUB_CATEGORY_ID}
        />
      )}
    </div>
  );
};
