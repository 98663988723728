import { css } from 'linaria';

export const footerBadgeStyle = css`
  /* max-width: 120px;
  max-height: 120px;

  img {
    height: 100%;
    border: 1px solid red;

  } */
`;
