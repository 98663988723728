import React from 'react';
import t from '@jetshop/intl';
import Link from './Link';
import SubLinkWrapper from './SubLinkWrapper';

const MainNavLink = ({ title, url }) => (
  <SubLinkWrapper>
    <Link href={url}>{t(`Go to { category }`, { category: title })}</Link>
  </SubLinkWrapper>
);

export default MainNavLink;
