import IvarHeadlineMedium_woff from './Ivar Headline/IvarHeadline-Medium.woff';
import IvarHeadlineMedium_woff2 from './Ivar Headline/IvarHeadline-Medium.woff2';
import IvarHeadlineMediumItalic_woff from './Ivar Headline/IvarHeadline-MediumItalic.woff';
import IvarHeadlineMediumItalic_woff2 from './Ivar Headline/IvarHeadline-MediumItalic.woff2';
import IvarHeadlineBold_woff from './Ivar Headline/IvarHeadline-Bold.woff';
import IvarHeadlineBold_woff2 from './Ivar Headline/IvarHeadline-Bold.woff2';
import IvarHeadlineBoldItalic_woff from './Ivar Headline/IvarHeadline-BoldItalic.woff';
import IvarHeadlineBoldItalic_woff2 from './Ivar Headline/IvarHeadline-BoldItalic.woff2';
import IvarHeadlineItalic_woff from './Ivar Headline/IvarHeadline-Italic.woff';
import IvarHeadlineItalic_woff2 from './Ivar Headline/IvarHeadline-Italic.woff2';
import IvarHeadlineRegular_woff from './Ivar Headline/IvarHeadline-Regular.woff';
import IvarHeadlineRegular_woff2 from './Ivar Headline/IvarHeadline-Regular.woff2';
import IvarHeadlineSemiBold_woff from './Ivar Headline/IvarHeadline-SemiBold.woff';
import IvarHeadlineSemiBold_woff2 from './Ivar Headline/IvarHeadline-SemiBold.woff2';
import IvarHeadlineSemiBoldItalic_woff from './Ivar Headline/IvarHeadline-SemiBoldItalic.woff';
import IvarHeadlineSemiBoldItalic_woff2 from './Ivar Headline/IvarHeadline-SemiBoldItalic.woff2';

const IvarHeadline = `
  /* Ivar Headline - Regular */
  @font-face {
    font-family: 'Ivar Headline';
    src: url(${IvarHeadlineRegular_woff2}) format('woff2'),
    url(${IvarHeadlineRegular_woff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  /* Ivar Headline - Bold */
  @font-face {
    font-family: 'Ivar Headline';
    src: url(${IvarHeadlineBold_woff2}) format('woff2'),
    url(${IvarHeadlineBold_woff}) format('woff');
    font-weight: bold;
    font-style: normal;
  }

  /* Ivar Headline - Italic */
  @font-face {
    font-family: 'Ivar Headline';
    src:  url(${IvarHeadlineItalic_woff2}) format('woff2'),
    url(${IvarHeadlineItalic_woff}) format('woff');
    font-weight: 400;
    font-style: italic;
  }

  /* Ivar Headline - Bold and Italic */
  @font-face {
    font-family: 'Ivar Headline';
    src: url(${IvarHeadlineBoldItalic_woff2}) format('woff2'),
     url(${IvarHeadlineBoldItalic_woff}) format('woff');
    font-weight: 700;
    font-style: italic;
  }

  /* Ivar Headline Medium - Regular */
  @font-face {
    font-family: 'Ivar Headline';
    src: url(${IvarHeadlineMedium_woff2}) format('woff2'),
    url(${IvarHeadlineMedium_woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  /* Ivar Headline Medium - Italic */
  @font-face {
    font-family: 'Ivar Headline';
    src: url(${IvarHeadlineMediumItalic_woff2}) format('woff2'),
    url(${IvarHeadlineMediumItalic_woff}) format('woff');
    font-weight: 400;
    font-style: italic;
  }

  /* Ivar Headline - Semi Bold */
  @font-face {
    font-family: 'Ivar Headline';
    src: url(${IvarHeadlineSemiBold_woff2}) format('woff2'),
    url(${IvarHeadlineSemiBold_woff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  /* Ivar Headline - Semi Bold and Italic */
  @font-face {
    font-family: 'Ivar Headline';
    src: url(${IvarHeadlineSemiBoldItalic_woff2}) format('woff2'),
    url(${IvarHeadlineSemiBoldItalic_woff}) format('woff');
    font-weight: 600;
    font-style: italic;
  }
`;

export default IvarHeadline;
