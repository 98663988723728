import React, { useRef } from 'react';
import { styled } from 'linaria/react';
import { useScriptLoader } from '../../hooks/useScriptLoader';
import { filterChildrenByKey } from 'r3-lib/dist/r3lib';

const ScriptLoaderWrapper = styled('div')`
  width: 100%;
  margin: 0 auto;
  max-width: 736px;
`;

export const ScriptLoader = ({ src, children }) => {
  const scriptRef = useRef();

  const attribrutesChildren = filterChildrenByKey(
    children,
    'vita-scriptLoaderAttribute',
    true
  );

  let attribrutesObj = {};
  attribrutesChildren?.forEach(attribute => {
    const att = attribute?.props;
    const name = att?.attributeName?.value;
    const value = att?.attributeValue?.value;
    attribrutesObj[name] = value;
  });

  const { loaded, error } = useScriptLoader({
    src: src?.value?.value ?? src?.value,
    attributes: attribrutesObj,
    ref: scriptRef,
    noCache: true
  });

  if (error) {
    console.error(`Script from -->${src}<-- did not load properly`);
    return null;
  }

  return (
    <ScriptLoaderWrapper ref={scriptRef}>{loaded && true}</ScriptLoaderWrapper>
  );
};
