import React from 'react';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image';
import t from '@jetshop/intl';
import Styled from './style';

const ArticleCard = ({
  name,
  images = [],
  content: description,
  primaryRoute = {}
}) => {
  const { path } = primaryRoute;

  if (!name || !path) return null;

  return (
    <Styled.ArticleCardContainer role="article" to={path}>
      <Link to={path} className="article-link">
        {images && images[4]?.url && (
          <Image
            aspect="290:160"
            sizes={'580'}
            cover={true}
            crop={true}
            src={images && images[4]?.url}
          />
        )}

        <h2 className="title">{name}</h2>

        {description && (
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        {path && <p className="read-more">{t('Read more')}</p>}
      </Link>
    </Styled.ArticleCardContainer>
  );
};

export default ArticleCard;
