import React from 'react';
import { Above, Below } from '@jetshop/ui/Breakpoints';

import { cx, css } from 'linaria';
import { theme } from '../../Theme';
import { SharedSwiper } from '../../Layout/Swiper/SharedSwiper';
import { LipscoreProductsRatingFetcher } from '../../ui/vita/LipscoreProductsRatingFetcher';
import { CTA } from 'r3-lib/dist/r3lib';
import { ProductRowGallery } from './ProductRowGallery';

const productRowStyle = css`
  h2 {
    text-align: center;
    font-size: 28px;
    font-weight: ${theme.fontWeights.medium};
    margin-bottom: 2rem;
  }

  .cta {
    margin: 0 auto;
  }
`;

export const productCardMaxSizeStyle = css`
  .product-card {
    max-width: 22vw;

    ${theme.below.lg} {
      max-width: 50vw;
    }
  }
`;

export const ProductRow = ({
  title,
  fullWidth,
  gap,
  padding = '0',
  buttonText,
  buttonTheme,
  buttonLink,
  cartPage,
  showAsGallery,
  children
}) => {
  const displayAsGallery = showAsGallery?.value ?? false;

  const buyableChildren = children?.filter(child => {
    return child?.props?.product?.value?.stockStatus?.buyable;
  });

  const productList = buyableChildren?.map(child => {
    return child?.props?.product?.value;
  });

  const desktopChildrenLength =
    productList?.length <= 5 ? productList?.length - 0.5 : 4.5;
  const mediumChildrenLength =
    productList?.length <= 2.3 ? productList?.length : 2.3;
  const mobileChildrenLength =
    productList?.length <= 2.1 ? productList?.length : 2.1;
  const desktopSlidesPerView = desktopChildrenLength;
  const mediumSlidesPerView = cartPage ?? mediumChildrenLength;
  const mobileSlidesPerView = cartPage ? 1.5 : mobileChildrenLength;

  return (
    <Above breakpoint="lg">
      {aboveLg => {
        return (
          <Below breakpoint="sm">
            {belowMd => {
              return (
                <LipscoreProductsRatingFetcher products={productList}>
                  {productsWithRating => {
                    const ratedProductChildren = buyableChildren?.map(child => {
                      // this sucks but works
                      return {
                        ...child,
                        props: {
                          ...child?.props,
                          product: {
                            ...child?.props?.product,
                            value:
                              productsWithRating?.find(
                                prod =>
                                  prod?.articleNumber ===
                                  child?.props?.product?.value?.articleNumber
                              ) ?? child?.props?.product?.value
                          }
                        }
                      };
                    });

                    return (
                      <section
                        className={cx(
                          'product-row',
                          fullWidth?.value && 'fullWidth',
                          productRowStyle
                        )}
                        style={{ padding: `0 ${padding?.value ?? padding}px` }}
                      >
                        {title?.value?.length > 0 && <h2>{title?.value}</h2>}
                        <div className="wrapper">
                          {displayAsGallery ? (
                            <ProductRowGallery
                              products={ratedProductChildren}
                              gap={
                                gap?.value ? theme.layout.gaps[gap?.value] : 0
                              }
                              fullWidth={fullWidth?.value}
                            />
                          ) : (
                            <SharedSwiper
                              gap={
                                gap?.value ? theme.layout.gaps[gap?.value] : 0
                              }
                              slidesPerView={
                                aboveLg
                                  ? desktopSlidesPerView
                                  : belowMd
                                  ? mobileSlidesPerView
                                  : mediumSlidesPerView
                              }
                            >
                              {ratedProductChildren}
                            </SharedSwiper>
                          )}
                        </div>
                        {buttonText?.value?.length > 0 && (
                          <CTA link={buttonLink} className={buttonTheme?.value}>
                            {buttonText?.value}
                          </CTA>
                        )}
                      </section>
                    );
                  }}
                </LipscoreProductsRatingFetcher>
              );
            }}
          </Below>
        );
      }}
    </Above>
  );
};
