import React from 'react';
import { cx } from 'linaria';
import { iconCardRowItemStyle } from '../../ui/vita/styles/IconCardRowItem.style';
import { Above } from '@jetshop/ui/Breakpoints';
import { LinkEvaluator, aspectRatioAdjustment } from 'r3-lib/dist/r3lib';

export const IconCardRowItem = ({
  backgroundColor,
  desktopBoxAspect,
  mobileBoxAspect,
  image,
  foregroundColor,
  title,
  link
}) => {
  return (
    <Above breakpoint="lg">
      {matches => (
        <div
          className={cx('icon-card-row-item', iconCardRowItemStyle)}
          style={{
            background: backgroundColor?.value,
            aspectRatio: aspectRatioAdjustment(
              matches ? desktopBoxAspect?.value : mobileBoxAspect?.value
            ),
            color: foregroundColor?.value ?? '#333'
          }}
        >
          <LinkEvaluator link={link}>
            {image?.value && (
              <img src={image?.value?.value ?? image?.value} alt={title} />
            )}
            {title?.value && <h3>{title?.value}</h3>}
          </LinkEvaluator>
        </div>
      )}
    </Above>
  );
};
