import { css } from 'linaria';
import { theme } from '../../../Theme';

export const productCardPriceStyle = css`
  display: flex;
  justify-content: space-between;

  [data-flight-price] {
    display: flex;
    align-items: end;
    ${theme.below.lg} {
      display: block;
    }
    > * {
      color: ${theme.colors.forest};
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
    }

    .new-price {
      color: ${theme.colors.rosehip};
    }

    .old-price {
      margin-left: 8px;
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 100%;
      color: #9c9c9c;
      ${theme.below.lg} {
        margin-left: 0;
        margin-top: 3px;
      }
    }
  }
`;
