import { useEffect, useState, useRef } from 'react';

/**
 * The useOverflowEvaluator function returns a ref and an object containing booleans indicating whether the element
 * referenced by the returned ref is overflowing in either direction.
 * @return A ref and an object with two boolean values: isoverflowingx and isoverflowingy
 *
 * @docauthor Anders Zetterström @ R3
 */
export const useOverflowEvaluator = dependecies => {
  const ref = useRef(null);
  const [isOverflowingX, setIsOverflowingX] = useState(false);
  const [isOverflowingY, setIsOverflowingY] = useState(false);

  useEffect(() => {
    if (ref?.current) {
      const {
        scrollWidth,
        clientWidth,
        scrollHeight,
        clientHeight
      } = ref?.current;
      setIsOverflowingX(scrollWidth > clientWidth);
      setIsOverflowingY(scrollHeight > clientHeight);
    }
  }, [ref?.current?.clientWidth, ref?.current?.clientHeight, dependecies]);

  return [ref, { isOverflowingX, isOverflowingY }];
};
