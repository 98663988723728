import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import theme from './Theme';
import { toRem } from '../../../helpers';
import { ButtonInternal } from './Button';
import Bubble from './Bubble';

const ArticleBoxWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${props =>
    props.bg === 'light' ? theme.colors.aloe : theme.colors.moss};
  overflow: hidden;
  justify-content: space-between;

  &:first-child {
    margin-bottom: ${toRem(16)};
  }

  ${theme.above.md} {
    flex-direction: row;
  }

  ${theme.above.lg} {
    &:first-child {
      margin-right: ${toRem(16)};
      margin-bottom: 0;
    }
  }
`;

const TextBlockWrapper = styled.div`
  color: ${props =>
    props.color === 'light' ? theme.colors.forest : theme.colors.aloe};
  padding: ${toRem(32)} ${toRem(32)} 0 ${toRem(32)};

  ${theme.above.md} {
    max-width: 45%;
    padding: ${toRem(28)} ${toRem(8)} 0 ${toRem(32)};
  }
  ${theme.above.lg} {
    max-width: 50%;
    flex: 1;
  }
`;

const PreHeader = styled.h4`
  ${theme.fonts.h4}
  font-weight: normal;
  margin-bottom: ${toRem(8)};
`;
const Title = styled.h3`
  font-weight: bold;
  font-size: ${toRem(32)};
  line-height: ${toRem(38)};
  margin-bottom: ${toRem(8)};

  ${theme.above.md} {
    font-size: ${toRem(24)};
    line-height: ${toRem(30)};
  }

  ${theme.above.xxl} {
    font-size: ${toRem(32)};
    line-height: ${toRem(38)};
  }
`;

const Description = styled.p`
  font-size: ${toRem(16)};
  font-weight: 400;
  margin-bottom: ${toRem(22)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`;

const Cta = styled(ButtonInternal)`
  display: inline-flex;
  margin-bottom: ${toRem(15)};
`;
const ImageWrapper = styled.div`
  width: 100%;
  padding: ${props =>
    props.align === 'center' ? `${toRem(28)} ${toRem(32)}` : 0};
  ${theme.above.md} {
    max-width: 55%;
    display: flex;
    align-items: ${props => (props.align === 'bottom' ? 'flex-end' : 'center')};
    padding: ${props =>
      props.align === 'center'
        ? `${toRem(28)} ${toRem(32)} ${toRem(28)} 0`
        : 0};
  }
  ${theme.above.lg} {
    max-width: 50%;
  }
`;
const ArticleImage = styled(Image)`
  padding: 0 ${toRem(20)};
  ${theme.above.md} {
    padding: 0 0 0 ${toRem(20)};
  }
`;
const BubbleWrapper = styled('div')`
  position: absolute;
  right: ${toRem(24)};
  bottom: ${toRem(20)};
  display: block;

  .bubble-large-oval {
    width: 9rem;
    height: 9rem;
    position: relative;

    ${theme.above.sm} {
      width: ${toRem(102)};
      height: ${toRem(102)};
    }
  }

  .bubble-small-oval {
    width: 1rem;
    height: 1rem;
    ${theme.above.sm} {
      width: ${toRem(13)};
      height: ${toRem(13)};
    }
  }

  .bubble-percentage {
    font-weight: 700;
    font-size: ${toRem(36)};
    line-height: ${toRem(36)};
    ${theme.above.sm} {
      font-size: ${toRem(28)};
      line-height: ${toRem(40)};
    }
  }

  ${theme.above.md} {
    right: ${toRem(28)};
    left: auto;
    top: ${toRem(28)};
  }
`;
const ArticleBoxMedium = ({
  bg,
  cta,
  ctaLink,
  image,
  imageAlignment,
  imageDescription,
  preHeader,
  title,
  description,
  bubbleLargeText,
  bubbleSmallText,
  bubbleBackgroundColor
}) => {
  const aspectRatio = imageAlignment === 'bottom' ? '16:10' : '1:1';
  const buttonTheme = bg === 'light' ? `dark` : `light`;

  return (
    <ArticleBoxWrapper bg={bg}>
      <TextBlockWrapper color={bg}>
        <PreHeader color={bg}>{preHeader}</PreHeader>
        <Title color={bg}>{title}</Title>
        <Description>{description}</Description>
        {ctaLink && cta && (
          <Cta className={`primary ${buttonTheme}`} href={ctaLink}>
            {cta}
          </Cta>
        )}
      </TextBlockWrapper>
      <ImageWrapper align={imageAlignment}>
        <ArticleImage src={image} aspect={aspectRatio} alt={imageDescription} />
      </ImageWrapper>

      <BubbleWrapper>
        <Bubble
          percentage={bubbleLargeText}
          text={bubbleSmallText}
          background={bubbleBackgroundColor}
        />
      </BubbleWrapper>
    </ArticleBoxWrapper>
  );
};

export default ArticleBoxMedium;
