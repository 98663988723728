/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState
} from 'react';
import { elevate, localStorageBackedSession } from '@apptus/esales-api';
import { useLocation } from 'react-router-dom';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useCustomerQuery } from '@jetshop/core/components/Auth/useCustomer';
import customerQuery from '../MyPages/CustomerNameQuery.gql';

function isMobileDevice() {
  // Use the user agent string to detect mobile devices
  return /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

const ElevateContext = createContext();

const PER_PAGE = 48;

const customFields =
  'Product_Size|Badges|SalesText|Combined_URLs|GTIN|AllCategoryBucket';

const ORIGINS = {
  ORG: 'ORGANIC',
  DYM: 'DID_YOU_MEAN',
  UAC: 'UNDO_AUTO_CORRECT',
  PS: 'PHRASE_SUGGEST',
  REL: 'RELATED_SEARCHES',
  REC: 'RECENT_SEARCH'
};

const initialState = {
  init: false,
  api: null,
  searchResult: null,
  menuData: [],
  origin: 'ORGANIC'
};

// const sortOptions = {
//   RELEVANCE: 'Relevans',
//   NEWEST_FIRST: 'Nyheter',
//   PRICE_DESCENDING: 'Högsta pris',
//   PRICE_ASCENDING: 'Lägsta pris',
//   DISCOUNT: 'Rea'
// };

const reducer = (state, action) => {
  switch (action.type) {
    case 'init':
      if (state.init) return state;
      const api = elevate({
        clusterId: process.env.REACT_APP_ELEVATE_CLUSTER_ID ?? 'w1757CA6A',
        market: action.payload?.country.code,
        locale: action.payload?.defaultLanguage.culture,
        touchpoint: isMobileDevice() ? 'mobile' : 'desktop',
        session: localStorageBackedSession()
      });

      if (localStorage) {
        const session = localStorageBackedSession();
        localStorage.setItem('elevateData', JSON.stringify(session()));
      }

      return { ...state, init: true, api };

    case 'updateSearchResult':
      return { ...state, searchResult: action.payload };

    case 'updateOrigin':
      if (ORIGINS[action.payload]) {
        return { ...state, origin: ORIGINS[action.payload] };
      }
      return { ...state, origin: 'ORGANIC' };

    default:
      return state;
  }
};

export const CustomerUpdater = () => {
  const { customer, error } = useCustomerQuery({ query: customerQuery });
  useEffect(() => {
    if (!error && customer && customer.externalId) {
      const session = localStorageBackedSession();
      session.updateCustomerKey(customer.externalId);
      localStorage.setItem('elevateData', JSON.stringify(session()));
    }
  }, [customer]);
  return null;
};

const removeTrailingSlash = str => {
  if (str.endsWith('/')) {
    return str.slice(0, -1);
  }
  return str;
};

export const ElevateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { pathname, search } = useLocation();
  const { selectedChannel } = useContext(ChannelContext);
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      dispatch({ type: 'init', payload: selectedChannel });
    }
  }, []);

  useEffect(() => {
    // dispatch({ type: 'updateOrigin', payload: search });
  }, [search]);

  const setOrigin = (origin = 'ORG') => {
    dispatch({ type: 'updateOrigin', payload: origin });
  };

  const getLandingPageData = async ({
    skip = 0,
    limit = PER_PAGE,
    altPath = null,
    ...rest
  }) => {
    const result = await state?.api?.query
      .landingPage({
        pageReference: altPath ?? removeTrailingSlash(pathname),
        skip: skip,
        limit: limit,
        presentCustom: customFields,
        ...rest
      })
      .catch(e => {
        console.error('ERROR', e);
      });
    return result;
  };

  const getRecommendationData = async ({ recoId, algorithm, path }) => {
    const result = await state.api.query.landingPage(
      {
        pageReference: path,
        presentCustom: customFields
      },
      {
        recommendationLists: [
          {
            id: recoId,
            algorithm: algorithm
          }
        ]
      }
    );
    return result;
  };

  const getProductPageData = async ({ productKey }) => {
    const result = await state.api.query.productPage(
      {
        productKey: productKey,
        presentCustom: customFields
      },
      {
        productGroup: {
          include: true
        },
        recommendationLists: [
          {
            id: 'top-sellers',
            algorithm: 'ALTERNATIVES'
          }
        ]
      }
    );
    return result;
  };

  const getNavigationTree = async () => {
    const result = await state.api.query.navigationTree();

    return result;
  };

  const getSearchPageData = async ({
    skip = 0,
    term = '',
    limit = PER_PAGE,
    ...rest
  }) => {
    if (term?.length < 1) {
      return null;
    }

    const result = await state.api.query.searchPage(
      {
        q: term,
        skip: skip,
        limit: limit,
        presentCustom: customFields,
        origin: state.origin,
        ...rest
      },
      {
        contentLists: [{ id: 'all-content' }]
      }
    );

    return result;
  };

  const runQuery = async (term, settings = { origin: null }) => {
    if (term.length < 3) {
      dispatch({ type: 'updateSearchResult', payload: null });
    }

    const searchResult = await state.api.query.autocomplete({
      q: term,
      presentCustom: customFields,
      origin: ORIGINS?.[settings.origin] ?? state.origin
    });
    dispatch({ type: 'updateSearchResult', payload: searchResult });
  };

  return (
    <ElevateContext.Provider
      value={{
        ...state,
        runQuery,
        getLandingPageData,
        getSearchPageData,
        getProductPageData,
        getNavigationTree,
        searchOpen,
        setSearchOpen,
        getRecommendationData,
        setOrigin
      }}
    >
      {children}
    </ElevateContext.Provider>
  );
};

export const useElevate = () => useContext(ElevateContext);
