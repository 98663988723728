import React from 'react';
import { styled } from 'linaria/react';
import { COMPONENTS } from './Imports';
import ArticleBoxMedium from './ArticleBoxMedium';
import theme from './Theme';
import useChildren from '../../../hooks/useChildren';

const ArticleBlockWrapper = styled('div')`
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 20px;

  ${theme.above.lg} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ArticleBlockMedium = props => {
  const allProps = useChildren(props);
  const articleBoxes = allProps[COMPONENTS.ARTICLE_BOX_MEDIUM];

  if (!articleBoxes) return null;

  return (
    <ArticleBlockWrapper className="odd-even-background">
      {articleBoxes.map(box => {
        return (
          <ArticleBoxMedium
            bg={box.background}
            cta={box.ctaText}
            ctaLink={box.ctaLink}
            image={box.image}
            imageAlignment={box.imageAlignment}
            imageDescription={box.imageDescription}
            key={box.key}
            preHeader={box.preHeader}
            title={box.title}
            description={box.description}
            bubbleLargeText={box.bubbleLargeText}
            bubbleSmallText={box.bubbleSmallText}
            bubbleBackgroundColor={box.bubbleBackgroundColor}
          />
        );
      })}
    </ArticleBlockWrapper>
  );
};

export default ArticleBlockMedium;
