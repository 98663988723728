import { css } from 'linaria';

export const headerDropdownStyle = css`
  position: absolute;
  background: white;
  width: 100%;
  height: fit-content;
  max-height: 0px;
  overflow: scroll;

  &.open {
    max-height: 80vh;
    padding: 20px;
  }
`;
