import { css } from 'linaria';
import { theme } from '../../../Theme';

export const barHoverStyle = css`
  position: relative;
  &:after {
    height: 3px;
    width: 0px;
    position: absolute;
    bottom: -4px;
    left: 0;
    background: ${theme.colors.forest};
    content: '';
    transition: width 0.15s ease-in-out;
  }
  :hover,
  :active {
    &:after {
      width: 100%;
    }
  }
`;
