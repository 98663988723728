import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import { toRem } from '../../../../helpers';

const Link = styled.a`
  display: block;
  ${theme.fonts.desktop.h4}
  color: ${theme.colors.seaweed};
  text-decoration: none;
  margin-right: ${toRem(8)};
  
  ${theme.above.md} {
    font-size: ${toRem(16)};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default Link;
