import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import { useIntl } from '@jetshop/intl';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { Price } from '@jetshop/ui/Price';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 20px 0 30px 0;

  &.list-wrapper {
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    .inner {
      margin-top: -10px;
    }
    .title {
      font-size: 13px;
    }
    .offer-price {
      font-size: 20px;
    }
    .standard-price {
      font-size: 12px;
    }
  }

  .title {
    color: ${theme.colors.forest};
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 3px;
  }

  .offer-price {
    font-size: 28px;
    line-height: 1;
    font-weight: bold;
    color: ${theme.colors.forest};
  }

  .standard-price {
    font-weight: 500;
    font-size: 15px;
    color: #1e1e1e;
    margin-bottom: 5px;
    display: flex;
    gap: 5px;
  }
`;

const ToggleButton = styled('button')`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 13px;
  color: ${theme.colors.black};
  cursor: pointer;
  text-align: left;
`;

const FlyoutToggle = ({ drawer, children, onClick }) => {
  return (
    <ToggleButton
      onClick={drawer.showTarget}
      dangerouslySetInnerHTML={{
        __html: children
      }}
    />
  );
};

export const MemberPriceList = ({
  memberPrice,
  standardPrice,
  children,
  hideTitle
}) => {
  const t = useIntl();
  return (
    <Wrapper className="list-wrapper">
      <div className="inner">
        {!hideTitle && <span className="title">{t('Member price')}</span>}
        <span className="offer-price">
          <Price price={memberPrice} />
        </span>
        <span className="standard-price">
          {t('Not member')} <Price price={standardPrice} />
        </span>
      </div>
      {children}
    </Wrapper>
  );
};

export const MemberPrice = ({ product, hideText, memberPrice }) => {
  const t = useIntl();
  const flyoutString = t(
    '<u>Logga in</u> / <u>Bli medlem</u> för att ta del av erbjudandet'
  );
  const { loggedIn } = useAuth();
  const standardPrice =
    product?.previousPrice?.incVat > product?.defaultPrice?.incVat
      ? product?.previousPrice
      : product?.defaultPrice;
  const campaign = product?.campaigns?.find(campaign =>
    campaign.name.includes('Life Club')
  );
  const percentage =
    parseFloat(campaign?.name.match(/\d+%|([0-9]\d?)\.\d+%/g)) / 100;
  memberPrice = memberPrice || {
    incVat: Math.round(standardPrice.incVat - standardPrice.incVat * percentage)
  };

  return (
    <Wrapper>
      <span className="title">{t('Member price')}</span>
      <span className="offer-price">
        <Price price={memberPrice} />
      </span>
      <span className="standard-price">
        {t('Not member')} <Price price={standardPrice} />
      </span>

      {!loggedIn && !hideText && (
        <DrawerTrigger preventOverflow={true} id="life-club-flyout">
          {drawer => (
            <FlyoutToggle drawer={drawer}>{flyoutString}</FlyoutToggle>
          )}
        </DrawerTrigger>
      )}
    </Wrapper>
  );
};
