import { css } from 'linaria';
import { theme } from '../../../Theme';

export const footerContentStyle = css`
  width: 100%;
  padding: 3.5rem 0 0 0;

  ${theme.below.lg} {
    padding: 12px 0 35px 0;
  }

  .social-media-row {
    .icon {
      margin-bottom: 40px;
      height: 74px;
      width: fit-content;
      ${theme.below.lg} {
        margin-top: 0px;
        margin-bottom: 20px;
      }
    }
  }

  .max-width {
    flex-direction: row;
    gap: 100px;

    ${theme.below.lg} {
      flex-direction: column-reverse;
      gap: 55px;
    }

    .full-grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: auto auto;
      grid-column-gap: 100px;
      grid-row-gap: 20px;

      ${theme.below.lg} {
        display: flex;
        flex-direction: column-reverse;

        .social-media {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      .text-info {
        grid-area: 1 / 1 / 2 / 3;

        ${theme.below.lg} {
          padding: 20px 0px 20px 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          .icon {
            margin-bottom: 25px;
          }
        }

        h3 {
          margin: 0 0 9px 0;
          ${theme.fonts.desktop.h3}

          ${theme.below.lg} {
            ${theme.fonts.desktop.h4}
          }
        }
        p {
          ${theme.fonts.desktop.p1}
          ${theme.below.lg} {
            ${theme.fonts.mobile.p2}
            text-align: center;
            line-height: 1.5;
          }
        }
      }

      .column-grid {
        grid-area: 1 / 3 / 2 / 6;
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: auto;
        grid-column-gap: 40px;
        grid-row-gap: 0px;
        direction: rtl;
        text-align: left;

        ${theme.below.lg} {
          display: flex;
          flex-direction: column;
        }

        .accordion-details summary {
          flex-direction: row;
        }
      }
    }

    .footer-badge-wrapper {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: end;

      ${theme.below.lg} {
        margin-top: 40px;
        justify-content: center;
      }
    }
  }
`;
