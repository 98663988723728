import React, { useState } from 'react';

import { styled } from 'linaria/react';
import { useCookies } from 'react-cookie';

import Image from '@jetshop/ui/Image';

import { HtmlRowItem, LinkEvaluator } from 'r3-lib/dist/r3lib';
import { PopupContent } from '../ui/vita/PopupContent';
import { theme } from '../Theme';

const PopupWrapper = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  > * {
    width: 50%;
    ${theme.below.lg} {
      width: 100%;
    }
  }

  ${theme.below.lg} {
    flex-direction: column;
    align-items: unset;
  }

  .html-row-item-outer {
    padding: 30px 20px;
  }

  .image-wrapper {
    ${theme.below.lg} {
      overflow: hidden;
    }
  }
`;

export const PopupTrigger = ({
  identifier,
  delay,
  expireInDays,
  image,
  imageAspect,
  html,
  link,
  verticalPosition,
  horizontalPosition,
  overlayStyle
}) => {
  const [open, setOpen] = useState();
  const [cookies, setCookie] = useCookies();
  const cookieKey = `hidePopup-${identifier?.value?.value ??
    identifier?.value}`;
  const hidePopup = cookies?.[cookieKey];

  const validateNumber = text => {
    let returnText = parseInt(text);
    if (isNaN(returnText)) {
      returnText = null;
    }
    return returnText;
  };

  const pDelay = validateNumber(delay?.value?.value ?? delay?.value);
  const pExpire = validateNumber(
    expireInDays?.value?.value ?? expireInDays?.value
  );

  if (!pDelay || !pExpire) {
    return null;
  }
  const handleSetOpen = value => {
    const d = new Date();
    d.setTime(d.getTime() + pExpire * 24 * 60 * 60 * 1000);
    setCookie(cookieKey, true, { path: '/', expires: d });
    if (value) {
      setOpen(value);
    }
  };

  if (hidePopup || !pDelay || !pExpire) {
    return null;
  }

  setTimeout(() => {
    setOpen(true);
  }, pDelay);

  const createStyles = s => {
    const checker = s?.value ?? s;
    switch (checker) {
      case 'light':
        return ['light'];

      case 'none':
        return [];

      case 'blur':
        return ['light', 'blur'];

      default:
        return ['light'];
    }
  };

  const styles = createStyles(overlayStyle?.value);

  return (
    <PopupContent
      open={open}
      setOpen={handleSetOpen}
      verticalPosition={
        verticalPosition?.value?.value ?? verticalPosition?.value
      }
      horizontalPosition={
        horizontalPosition?.value?.value ?? horizontalPosition?.value
      }
      overlayClassNames={styles}
    >
      <LinkEvaluator
        link={link?.value?.value ?? link?.value}
        linkClickCallback={handleSetOpen}
      >
        <PopupWrapper>
          <div className="image-wrapper">
            <Image
              sizes={[500, 500, 800, 300, 300]}
              src={image?.value?.value ?? image?.value}
              aspect={imageAspect?.value?.value ?? imageAspect?.value}
              cover
              crop={false}
              critical
            />
          </div>

          <HtmlRowItem html={html} />
        </PopupWrapper>
      </LinkEvaluator>
    </PopupContent>
  );
};
