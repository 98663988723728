import React from 'react';
import { cx } from 'linaria';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';

import { footerBadgeStyle } from './FooterBadge.style';
export const FooterBadge = ({ image, link }) => {
  return (
    <div className={cx('footer-badge', footerBadgeStyle)}>
      <LinkEvaluator link={link}>
        <img
          alt={image?.value?.value ?? image?.value}
          src={image?.value?.value ?? image?.value}
          width={120}
        />
      </LinkEvaluator>
    </div>
  );
};
