import React from 'react';
import { cx } from 'linaria';
import { headerDropdownStyle } from './HeaderDropdown.style';

export const HeaderDropdown = ({ open, style, children }) => {
  //TODO - Add close button ?
  return (
    <div className={cx('header-dropdown', style, open && 'open')}>
      {children}
    </div>
  );
};

export const StyledHeaderDropdown = ({ open, children }) => {
  return (
    <HeaderDropdown open={open} style={headerDropdownStyle}>
      {children}
    </HeaderDropdown>
  );
};
