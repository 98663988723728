import { useContext } from 'react';
import ModalContext from '@jetshop/ui/Modal/ModalContext';

/**
 * The useModalManagement function is a custom hook that provides the following functionality:
 * - singleModalMode() - only latest opened modal open.
 * - closeAll() - closes all modals.
 * - closeAllModalsExcept(id) - closes all modals except from modal with provided id.
 *   If no id is provided, it will open the first available modal in the list of registered Modals.
 *
 * @return An object with three functions
 *
 * @docauthor Anders Zettedrström @ R3
 */
export const useModalManagement = () => {
  const { modals } = useContext(ModalContext);

  const singleModalMode = () => {
    if (modals?.length > 1) {
      modals?.[0]?.hideTarget?.();
    }
  };

  const closeAll = () => {
    modals?.forEach(modal => {
      modal?.hideTarget();
    });
  };

  const closeAllModalsExcept = id => {
    modals?.forEach(modal => {
      if (modal?.modalId !== id) {
        modal?.hideTarget();
      }
    });
  };

  return { closeAll, closeAllModalsExcept, singleModalMode };
};
