import { css } from 'linaria';
import { theme } from '../../../Theme';

export const productCardHeaderStyle = css`
display: flex;
justify-content: space-between;


.product-card-header-names {

}
    .product-card-header-product-name {
        ${theme.fonts.desktop.p1}
        font-weight: ${theme.fontWeights.medium};
        line-height: 122%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        ${theme.below.lg}{
            ${theme.fonts.mobile.p1}
        }
        
        color: ${theme.colors.black};
        
    }
    .product-card-header-sub-name {
        height: 35px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
        color: ${theme.colors.black};
        ${theme.fonts.desktop.p2}

        ${theme.below.lg}{
            ${theme.fonts.mobile.p2}
        }
        
    }

`;
