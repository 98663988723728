import { styled } from 'linaria/react';
import { toRem } from '../../../../helpers';

export const SubLinkWrapper = styled.div`
  width: 100%;
  max-width: ${toRem(215)};
  display: flex;
  align-items: center;
  margin-bottom: ${toRem(24)};

  &:first-child {
    margin-top: ${toRem(20)};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export default SubLinkWrapper;
