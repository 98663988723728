import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../../Theme';
import { cx } from 'linaria';

const Scroll = styled('div')`
  flex: 1 1 auto;
`;

const NewDrawer = styled('nav')`
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  /* top: ${props => props.topPosition + 'px'}; */
  /* height: calc(100% - ${props => props.topPosition + 'px'}); */
  height: 100%;
  z-index: 4;
  max-width: 100%;
  ${theme.below.lg} {
    max-width: 90%;
    top: 0px;
    height: 100%;
  }

  width: ${props => props.size}px;
  transform: translateX(${props => (props.isOpen ? 0 : props.size)}px);
  transition: transform 0.2s ease-in-out;
  right: 0;

  &.left {
    left: 0;
    right: auto;
    transform: translateX(${props => (props.isOpen ? 0 : -props.size)}px);
  }

  &.full {
    max-width: 90%;
    width: 100vw;
    transform: translateX(${props => (props.isOpen ? '0px' : '90vw')});
    &.left {
      transform: translateX(${props => (props.isOpen ? '0px' : '-90vw')});
    }
  }
`;

export const PositionedDrawer = ({
  isOpen,
  size = 320,
  left,
  full,
  children
}) => {
  const topPosition = 0; //useHeaderHeight();

  return (
    <NewDrawer
      topPosition={topPosition}
      isOpen={isOpen}
      size={size}
      className={cx(left && 'left', full && 'full')}
    >
      <Scroll>{children}</Scroll>
    </NewDrawer>
  );
};

export const Flyout = ({
  targetId,
  size,
  left = false,
  full = false,
  children
}) => {
  return (
    <>
      <DrawerTarget id={targetId}>
        {drawer => {
          return (
            <PositionedDrawer
              isOpen={drawer.isOpen}
              size={size}
              left={left}
              full={full}
            >
              {children(drawer)}
            </PositionedDrawer>
          );
        }}
      </DrawerTarget>
    </>
  );
};
