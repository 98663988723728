import React from 'react';
import SubLink from './SubLink';

const RenderSubLinks = ({ links }) => {
  return links.map(link => {
    return (
      link?.title &&
      link?.url && (
        <SubLink title={link.title} url={link.url} key={link.title} />
      )
    );
  });
};

export default RenderSubLinks;
