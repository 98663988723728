import { css } from 'linaria';

export const productCardStyle = css`
  height: auto;
  padding: 0 10px 26px 10px;
  position: relative;
  overflow: hidden;
  .product-card-product-link {
    text-decoration: none;
  }

  .product-card-image-wrapper {
    position: relative;
    background: rgba(0, 0, 0, 0.03);

    [data-flight-image-container] {
      mix-blend-mode: darken;
    }
  }

  .star-rating {
    padding: 3px 0 6px 0;
  }
`;
