import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import t from '@jetshop/intl';
import theme from './Theme';
import { toRem } from '../../../helpers';
import useChildren from '../../../hooks/useChildren';
import { ReactComponent as GreenArrowRight } from '../../../svg/GreenArrowRight.svg';
import CategoryLinkWrapper from './CategoryLinkWrapper';
import {
  ImageWrapper,
  ImageTitleBlock,
  TitleAndProductsWrapper,
  CategoryTitle,
  NumProducts
} from './LinkCardComponents';

const CategoryLinksWrapper = styled('div')`
  background-color: ${theme.colors.aloe25};
`;

const StyledMaxWidth = styled('div')`
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;

  display: grid;
  grid-gap: ${toRem(8)};

  padding-top: ${toRem(24)};
  padding-bottom: ${toRem(12)};

  ${theme.above.md} {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 5rem;
    padding-right: 5rem;
  }

  ${theme.above.lg} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${theme.below.md} {
    grid-template-columns: repeat(1, 1fr);
    padding: ${toRem(20)};
  }
`;

const CategoryLinks = props => {
  const cleanedProps = useChildren(props);
  const categoryLinks = cleanedProps['CATEGORY_LINK'];

  if (!categoryLinks) return null;

  return (
    <CategoryLinksWrapper>
      <StyledMaxWidth>
        {categoryLinks.map(cat => {
          return (
            <CategoryLinkWrapper
              key={cat?.key}
              href={cat?.category?.primaryRoute?.path}
            >
              <ImageTitleBlock>
                {cat?.image && (
                  <ImageWrapper>
                    <Image
                      alt={cat?.imageDescription}
                      src={cat?.image}
                      fillAvailableSpace={true}
                    />
                  </ImageWrapper>
                )}
                <TitleAndProductsWrapper>
                  <CategoryTitle>{cat?.category?.name}</CategoryTitle>
                  {cat?.category?.products?.totalResults > 0 && (
                    <NumProducts>
                      {cat?.category?.products?.totalResults} {t('Products')}
                    </NumProducts>
                  )}
                </TitleAndProductsWrapper>
              </ImageTitleBlock>
              <GreenArrowRight />
            </CategoryLinkWrapper>
          );
        })}
      </StyledMaxWidth>
    </CategoryLinksWrapper>
  );
};

export default CategoryLinks;
