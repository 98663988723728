import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { Bubble, BlockLink, theme } from './Imports';
import { toRem } from '../../../helpers';
import { Above, Below, Between } from '@jetshop/ui/Breakpoints';

const LargeCampaignWrapper = styled('div')`
  grid-area: large;
  position: relative;
  width: 100%;
  height: 100%;
`;

const BubbleWrapper = styled('div')`
  position: absolute;
  left: ${toRem(24)};
  top: 1.5rem;
`;

const TextOverlay = props => {
  const { text } = props;

  if (!text) return null;

  const Overlay = styled('div')`
    position: absolute;
    left: ${toRem(24)};
    bottom: 1.5rem;
    margin-right: ${toRem(24)};

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: ${theme.colors.plum};
    color: ${theme.colors.white};

    padding: ${toRem(13)} ${toRem(20)} ${toRem(10)};

    font-size: ${toRem(20)};
    line-height: 120%;
    font-weight: bold;
    ${theme.above.md} {
      ${theme.fonts.h2};
    }
  `;

  return <Overlay>{text}</Overlay>;
};

const LargeCampaign = props => {
  const {
    externalLinkUrl,
    internalLinkUrl,
    backgroundImage,
    backgroundImageAlt,
    salesPercentage,
    salesText,
    salesBackgroundColor,
    overlayText
  } = props;

  return (
    <LargeCampaignWrapper>
      <BlockLink
        externalLinkUrl={externalLinkUrl}
        internalLinkUrl={internalLinkUrl}
      >
        <Below breakpoint="sm">
          {matches =>
            matches && (
              <Image
                src={backgroundImage}
                alt={backgroundImageAlt}
                aspect={'1:1'}
                cover
                critical
              />
            )
          }
        </Below>
        <Between breakpoints={['sm', 'lg']}>
          {matches =>
            matches && (
              <Image
                src={backgroundImage}
                alt={backgroundImageAlt}
                aspect={'16:9'}
                cover
                critical
                sizes={'1000'}
              />
            )
          }
        </Between>
        <Above breakpoint="lg">
          {matches =>
            matches && (
              <Image
                src={backgroundImage}
                alt={backgroundImageAlt}
                aspect={'1:1'}
                fillAvailableSpace
                cover
                critical
                sizes={'1000'}
              />
            )
          }
        </Above>
        <BubbleWrapper>
          <Bubble
            percentage={salesPercentage}
            text={salesText}
            background={salesBackgroundColor}
          />
        </BubbleWrapper>
        <TextOverlay text={overlayText} />
      </BlockLink>
    </LargeCampaignWrapper>
  );
};

export default LargeCampaign;
