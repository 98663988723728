import React from 'react';
import Image from '@jetshop/ui/Image';
import { newCategoryHeaderStyle } from './../../components/ui/vita/styles/NewCategoryHeader.style';
import { cx } from 'linaria';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import breadcrumbsQuery from '../ui/BreadcrumbsQuery.gql';
import { Breadcrumbs } from '../ui/Breadcrumbs';
import { useIntl } from '@jetshop/intl';

const NewCategoryHeader = ({
  description,
  image,
  imageDescription,
  heroTitle
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const location = useLocation();

  const t = useIntl();
  const readMoreString = t('Read more');
  const readLessString = t('Read less');
  const { data } = useQuery(breadcrumbsQuery, {
    variables: {
      path: location?.pathname
    },
    errorPolicy: 'all'
  });

  const breadcrumbProps = {
    breadcrumbText: data?.route?.object?.breadcrumbText,
    parents: data?.route?.parents
  };

  return (
    <div className={cx('header-article-page', newCategoryHeaderStyle)}>
      <div className="image-wrapper">
        <Image
          alt={imageDescription?.value}
          fillAvailableSpace={true}
          src={image?.value?.value}
          sizes={[1, 1, 1, 1, 1]}
          critical={true}
        />
      </div>
      <div className="text-wrapper">
        <Breadcrumbs {...breadcrumbProps} />
        <h1 className="title">{heroTitle?.value}</h1>
        <p
          onClick={() => {
            setExpanded(!expanded);
          }}
          className={cx(
            'description',
            'dynamic-height',
            expanded && 'expanded'
          )}
        >
          {description?.value}
        </p>
        <p
          className="read-more"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {expanded ? readLessString : readMoreString}
        </p>
      </div>
    </div>
  );
};

export default NewCategoryHeader;
