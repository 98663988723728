import React from 'react';
import { styled } from 'linaria/react';
import { toRem } from '../../../helpers';
import { theme } from '../../Theme';

const BubbleWrapper = styled('div')`
  position: relative;
`;

const SmallOval = styled('div')`
  position: absolute;
  left: 0rem;
  top: 0rem;

  background-color: ${props => props.bgcolor};
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
`;

const LargeOval = styled('div')`
  position: absolute;
  left: 0rem;
  top: 0rem;

  background-color: ${props => props.bgcolor};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 11.25rem;
  height: 11.25rem;
  border-radius: 50%;
`;

const TextWrapper = styled('div')`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Percentage = styled('div')`
  color: ${theme.colors.white};
  font-size: ${toRem(56)};
  line-height: ${toRem(70)};
  font-weight: bold;
  text-align: center;
`;

const Text = styled('div')`
  color: ${theme.colors.white};
  font-size: ${toRem(22)};
  line-height: ${toRem(27)};
  text-align: center;
`;

const Bubble = props => {
  const { percentage, text, background } = props;

  if (!percentage && !text) return null;

  return (
    <BubbleWrapper className="bubble-wrapper">
      <SmallOval
        className="bubble-small-oval"
        bgcolor={
          theme.colors[background?.toLowerCase()] ?? theme.colors.rosehip
        }
      />
      <LargeOval
        className="bubble-large-oval"
        bgcolor={
          theme.colors[background?.toLowerCase()] ?? theme.colors.rosehip
        }
      >
        <TextWrapper>
          <Percentage className="bubble-percentage">{percentage}</Percentage>
          {text && <Text className="bubble-text">{text}</Text>}
        </TextWrapper>
      </LargeOval>
    </BubbleWrapper>
  );
};

export default Bubble;
