import { css } from 'linaria';
import { theme } from '../../../Theme';

css`
  :global() {
    .categories-row {
      padding: 35px 0;

     .title {
      margin-bottom: 40px;
      font-size: 28px;
      font-weight: ${theme.fontWeights.medium};
     }
      .categories-row-items{
        margin: 0 auto;
        max-width: ${theme.layout.maxWidth};
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 60px;
    }
  }
`;
