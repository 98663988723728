import { css } from 'linaria';

export const iconCardRowItemStyle = css`
  flex: 1;

  .link-evaluator {
    height: 100%;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: inherit;

    img {
      height: 60px;
      width: auto;
      max-width: 100%;
      margin-bottom: 30px;
    }

    h3 {
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: inherit;
    }
  }
`;
