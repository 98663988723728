import { css } from 'linaria';
import { theme } from '../../../Theme';

export const desktopHeaderStyles = css`
  width: 100%;
  background-color: ${theme.colors.white};
  z-index: 3;
  display: block;
  position: relative;
  position: sticky;
  top: 0;
  transition: all, 0.2s ease;

  &.sticky {
    visibility: visible !important;
    position: sticky;
  }
  &.scrolled {
    visibility: hidden;
  }

  .logo-search-icons {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    height: 63px;
    .left {
      display: flex;
      align-items: center;
      gap: 30px;
    }
    .right {
      display: flex;
      align-items: center;
      gap: 10px;
      .link-evaluator {
        height: fit-content;
        text-decoration: none;
      }
      .favourites-icon-wrapper {
        .count-badge {
          background: ${theme.colors.seaweed};
        }
      }
    }
  }

  .categories-and-links {
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 0 20px;
    border: 1px solid ${theme.colors.aloe};
    border-left: none;
    border-right: none;

    .left {
      display: flex;
      gap: 20px;
    }
    .right {
      display: flex;
      gap: 20px;
    }
  }
`;

export const desktopHeaderButtonStyles = css`
  padding: 5px 0;
  background: white;
  color: ${theme.colors.black};
  text-decoration: none;
  display: flex; 
  justify-content: center;
  align-items: center;
  ${theme.fonts.desktop.p2}
  font-weight: ${theme.fontWeights.medium};
  ${theme.below.lg} {
    ${theme.fonts.mobile.p2}
    font-weight: ${theme.fontWeights.medium};
  }
`;

export const categoryMenuDesktopStyle = css`
  display: flex;
  flex-direction: column;
  .category-title {
    color: ${theme.colors.black};
    text-decoration: none;
    ${theme.fonts.desktop.h3};
    font-weight: ${theme.fontWeights.bold};
  }

  .category-menu-result {
    margin-top: 20px;
    margin-bottom: 20px;
    columns: 4;
    column-rule: 1px solid ${theme.colors.aloe};
  }
`;

export const searchResultDesktopStyle = css`

  .search-title {
    color: ${theme.colors.black};
    text-decoration: none;
    ${theme.fonts.desktop.h3};
    font-weight: ${theme.fontWeights.bold};
  }

  .results {
    margin-top: 36px;
    gap: 80px;
    display: flex;

       h4 {
        ${theme.fonts.desktop.p2}
        font-weight: ${theme.fontWeights.bold};
        line-height: 180%;
      }
      
    .left {
      width: max-content;
      white-space: nowrap;
    }

    .right {
      width: 100%;

      .search-products{
        margin-top: 12px;
        display: flex;
        width: 100%;
        overflow-x: auto;
        flex-wrap: wrap;
        > * {
          width: 25%;
          max-width: 266px;
          
        }
      }

      .cta {
        margin: 0 auto;
      }
    }
  }
`;
