import { useIntl } from '@jetshop/intl';
import { Price } from '@jetshop/ui/Price';
import React from 'react';
import { css, cx } from 'linaria';
import { theme } from '../../../Theme';
import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';

const freightStatusStyle = css`
  width: 100%;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  .freight-status-text {
    text-align: center;
    font-size: 15px;
    line-height: 100%;
    font-weight: 500;
  }

  &.free-freight {
    background: ${theme.colors.aloe};
    .freight-status-text {
      color: ${theme.colors.moss};
    }
  }

  &.left-to-free-freight {
    background: ${theme.colors.variants.guava};
    .freight-status-text {
      color: #9a6251;
    }

    [data-flight-price] {
      display: inline-block;
      font-weight: 400;
    }
  }
`;

/**
 * The FreightStatus function is a React component that displays the status of free shipping.
 * It will display either "You achived free shipping!" or "Left to free shipping: {amount}";
 * depending on whether the cart total has met the limit for free freight.
 *
 * @param { object } cartTotal the price object that contains the cart total cost
 *
 * @return A component with correct freight status text
 *
 * @docauthor Anders Zetterström @ R3
 */
export const FreightStatus = ({ cartTotal }) => {
  const {
    loading,
    hasMetLimit,
    untilLimit,
    freeShippingConfigured
  } = useFreeShippingCheck({
    cartTotal
  });
  const t = useIntl();

  if (!freeShippingConfigured || loading) return null;

  const roundedUntilLimit = Math.round(untilLimit?.incVat);
  const freeFreightAchived = t('You achived free shipping!');
  const leftToFreeFreight = t.rich('Left to free shipping: {amount}', {
    amount: (
      <Price key={'freight-status-key'} price={{ incVat: roundedUntilLimit }} />
    )
  });

  return (
    <div
      className={cx(
        'freight-status',
        freightStatusStyle,
        hasMetLimit ? 'free-freight' : 'left-to-free-freight'
      )}
    >
      <div className="freight-status-text">
        {hasMetLimit ? freeFreightAchived : leftToFreeFreight}
      </div>
    </div>
  );
};
