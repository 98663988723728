import { Price } from '@jetshop/ui/Price';
import React from 'react';
import { cx } from 'linaria';
import { productCardPriceStyle } from './ProductCardPrice.style';

export const ProductCardPrice = ({ product, style, children }) => {
  return (
    <div className={cx('product-card-price', style)}>
      <Price
        hidePrice={product?.hidePrice}
        price={product?.price}
        previousPrice={product?.previousPrice}
      />
      {children}
    </div>
  );
};

export const StyledProductCardPrice = ({ product, children }) => {
  return (
    <ProductCardPrice product={product} style={productCardPriceStyle}>
      {children}
    </ProductCardPrice>
  );
};
