import { css } from 'linaria';

// Lägg till padding-fält i CE (small medium large). Query för mobile
css`
  :global() {
    .box-row {
      .box-row-color-item {
        .content-positioner {
          a {
            color: inherit;
          }
        }
      }
      .box-row-color-item.small {
        .content-positioner {
          padding-left: 20px !important;
          padding-right: 20px !important;
        }
      }
      .box-row-color-item.medium {
        .content-positioner {
          padding-left: 40px !important;
          padding-right: 40px !important;
        }
      }
      .box-row-color-item.large {
        .content-positioner {
          padding-left: 60px !important;
          padding-right: 60px !important;
        }
      }
    }
  }
`;
