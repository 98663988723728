import { css } from 'linaria';

css`
  :global() {
    .collage-row-item {
      height: 100%;
      position: relative;

      .link-evaluator {
        height: 100%;

        > * {
          height: 100%;
        }

        .content-positioner {
          padding: 1rem;
          .content-pre-title {
            font-size: 14px;
          }
          .content-title {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
`;
