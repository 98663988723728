import { css } from 'linaria';

export const promotionBadgeStyle = css`
  aspect-ratio: 1;
  width: fit-content;
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 2;
  transform-origin: top left;

  .promotion-badge-circle {
    position: relative;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 17px;

    .promotion-badge-text {
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 100%;
      text-align: center;

      &.small {
        font-size: 32px;
      }
      &.medium {
        font-size: 52px;
        padding: 10px;
      }
      &.large {
        font-size: 72px;
        padding: 17px;
        @media (max-width: 400px) {
          font-size: 52px;
          padding: 10px;
        }
      }
    }

    .promotion-badge-dot {
      position: absolute;
      top: 0;
      left: 0;
      width: 14%;
      aspect-ratio: 1;
      border-radius: 50%;
    }

    :hover,
    :active {
      .promotion-badge-dot {
        animation-name: bounce;
        animation-duration: 3.8s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-play-state: running;
      }
    }

    @keyframes bounce {
      0% {
        top: 0;
        left: 0;
      }
      25% {
        top: 4%;
        left: 4%;
      }
      50% {
        top: 0;
        left: 0;
      }

      75% {
        top: 4%;
        left: 4%;
      }
      100% {
        top: 0;
        left: 0;
      }
    }
  }
`;
