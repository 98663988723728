import { css } from 'linaria';
import { theme } from '../../../Theme';

export const greenBarStyle = css`
  margin-bottom: 15px;
  width: fit-content;

  .green-bar-inner {
    position: relative;
    &:after {
      height: 3px;
      width: 36px;
      position: absolute;
      bottom: -7px;
      left: 0;
      background: ${theme.colors.forest};
      content: '';
      transition: width 0.15s ease-in-out;
    }

    :hover,
    :active {
      &:after {
        width: 100%;
      }
    }
  }
`;
