import React from 'react';
import Link from './Link';
import SubLinkWrapper from './SubLinkWrapper';

const SubLink = ({ title, url }) => (
  <SubLinkWrapper>
    <Link href={url}>{title}</Link>
  </SubLinkWrapper>
);

export default SubLink;
