import React from 'react';
import { cx } from 'linaria';
import { greenBarStyle } from './GreenBar.style';

export const GreenBar = ({ children }) => {
  return (
    <div className={cx('green-bar', greenBarStyle)}>
      <div className={'green-bar-inner'}>{children}</div>
    </div>
  );
};
