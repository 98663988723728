import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { theme } from '../Theme';

const BannerLink = styled(Link)`
  text-decoration: none;
  padding: 0.5rem 1.875rem;
  scroll-snap-align: start;
  background-color: ${props => props.bg};
  height: 2.18rem;
  color: ${props => props.p};
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  display: flex;
  flex: 1;
  background-repeat: no-repeat;
  background-position: center;
  ${theme.below.lg} {
    width: fit-content;
  }
`;

export const SiteWideBannerItem = ({
  textColor,
  text,
  link,
  backgroundColor,
  backgroundImage,
  height
}) => {
  return (
    <BannerLink
      to={link?.value}
      bg={backgroundColor.value}
      p={textColor?.value}
      style={{
        height: height.value,
        backgroundImage: backgroundImage?.value?.value
          ? `url(${backgroundImage?.value?.value})`
          : 'none'
      }}
      className="banner-item"
    >
      {text?.value}
    </BannerLink>
  );
};
