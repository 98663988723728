import { css } from 'linaria';
import { theme } from '../../../Theme';

export const lifeClubStyle = css`
  .dynamic-category-wrapper {
    background: ${theme.colors.aloe};
    padding: 40px 0;

    :empty {
      display: none;
    }
  }

  .html-row-item-outer {
    max-width: 420px;

    ${theme.below.lg} {
      padding: 0 30px;
    }

    a {
      height: 35px;
      width: fit-content;
      padding: 8px 30px;
      border-radius: ${theme.button.desktop.borderRadius};
      cursor: pointer;
      font-size: ${theme.fontSizes.desktop.p2};
      font-weight: ${theme.fontWeights.bold};
      text-decoration: none;
      background: ${theme.colors.white};
      color: ${theme.colors.black};
      display: block;
      margin: 0 auto;
      transition: all 0.3s ease-in-out;

      :hover,
      :active {
        background: transparent;
        border: 1px solid ${theme.colors.black};
      }
    }
  }
`;
