import React from 'react';
import { cx, css } from 'linaria';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';
import { Above } from '@jetshop/ui/Breakpoints';
import { StyledAccordion } from '../../../ui/vita/Accordion/Accordion';

// TODO - add to lib
export const hoverOpacityStyle = css`
  &:hover {
    opacity: 0.7;
  }
`;

//
export const FooterLinksColumn = ({ title, children }) => {
  return (
    <Above breakpoint="lg">
      {matches =>
        matches ? (
          <div className={cx('footer-links-column')}>
            <h4>{title?.value}</h4>
            {children}
          </div>
        ) : (
          <StyledAccordion title={title?.value}>{children}</StyledAccordion>
        )
      }
    </Above>
  );
};

export const FooterLinksColumnItem = ({ text, link }) => {
  return (
    <LinkEvaluator style={hoverOpacityStyle} link={link?.value}>
      {text?.value}
    </LinkEvaluator>
  );
};
