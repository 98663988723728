import React from 'react';
import { cx } from 'linaria';
import { SVG } from '../../../../assets/SVG';
import { titledIconActionStyle } from './TitledIconAction.style';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';
import { linkHoverStyle } from '../styles/LinkHover.style';

export const TitledIconAction = ({ iconName, title, action, link, style }) => {
  return (
    <LinkEvaluator link={link} linkClickCallback={action}>
      <div
        className={cx('titled-icon-action', style, style && linkHoverStyle)}
        onClick={action}
      >
        <SVG name={iconName} />
        <h4>{title}</h4>
      </div>
    </LinkEvaluator>
  );
};

export const StyledTitledIconAction = ({ iconName, title, action, link }) => {
  return (
    <TitledIconAction
      iconName={iconName}
      title={title}
      action={action}
      link={link}
      style={titledIconActionStyle}
    />
  );
};
