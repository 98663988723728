const fontSizeBaseValue = 16; // pixels

module.exports = {
  default: {
    colors: {
      /***** Life Colors ******/
      tropicalforest: '#04483E',
      forest: '#004E42',
      moss: '#00594C',
      seaweed: '#006C5B',
      grass: '#789F90',
      herb: '#B7CDC2',
      aloe: '#D1E0D7',
      aloe25: 'rgba(209, 224, 215, 0.25)',
      guava: '#E8927C',
      rosehip: '#E5554F',
      plum: '#893B67',
      white: '#FFFFFF',
      black: '#333333',
      beetroot: '#982743',
      backgroundGrey: '#f7f7f7',
      darkgrey: '#282828',
      lightgrey: '#F8F7F5',
      border: '#DDDDDD',
      secondaryBackground: '#F8F7F5',
      accent: '#004E42',
      red: '#DE2A23',
      variants: {
        aloe: '#B7CDC2',
        black: '#454545',
        white: '#D8D8D8',
        forest: '#006C5B',
        plum: '#8E6A7F',
        guava: '#EDB4A6'
      }
    },
    breakpoints: {
      xs: '20rem',
      sm: '40rem',
      md: '50rem',
      lg: '64rem',
      xl: '80rem'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700
    },
    fontSizes: {
      desktop: {
        h1: '32px',
        h2: '28px',
        h3: '24px',
        h4: '18px',
        p1: '16px',
        p2: '14px',
        p3: '12px',
        a: ''
      },
      mobile: {
        h1: '32px',
        h2: '28px',
        h3: '24px',
        h4: '18px',
        p1: '16px',
        p2: '14px',
        p3: '12px',
        a: ''
      }
    },
    lineHeights: {
      desktop: {
        h1: '122%',
        h2: '122%',
        h3: '122%',
        h4: '122%',
        p1: '150%',
        p2: '122%',
        p3: '122%',
        a: '122%'
      },
      mobile: {
        h1: '122%',
        h2: '122%',
        h3: '122%',
        h4: '122%',
        p1: '150%',
        p2: '122%',
        p3: '122%',
        a: '122%'
      }
    },
    fonts: {
      primaryFontFamily: 'Cera Pro',
      secondaryFontFamily: 'Cera Pro',
      journalFontFamily: 'Cera Pro',
      desktop: {
        h1: `
        font-family: Cera Pro;
        font-size: 1.75rem;
        line-height: 122%;
        font-weight: 500;
      `,
        h2: `
        font-family: Cera Pro;
        font-size: 1.4rem;
        line-height: 122%;
        font-weight: 500;
      `,
        h3: `
        font-family: Cera Pro;
        font-size: 1.2rem;
        line-height: 122%;
        font-weight: 500;
      `,
        h4: `
        font-family: 'Cera Pro';
        font-size: 1rem;
        line-height: 122%;
        font-weight: 500;
      `,
        p1: `
        font-family: Cera Pro;
        font-size: 14px;
        line-height: 150%;
        font-weight: 400;
      `,
        p2: `
        font-family: Cera Pro;
        font-size: 0.875rem;
        line-height: 122%;
        font-weight: 400;
      `,
        p3: `
        font-family: Cera Pro;
        font-size: 0.75rem;
        line-height: 122%;
        font-weight: 400;
      `
      },
      mobile: {
        h1: `
        font-family: Cera Pro;
        font-size: 2rem;
        line-height: 122%;
        font-weight: 500;
      `,
        h2: `
        font-family: Cera Pro;
        font-size: 1.75rem;
        line-height: 122%;
        font-weight: 500;
      `,
        h3: `
        font-family: Cera Pro;
        font-size: 1.5rem;
        line-height: 122%;
        font-weight: 500;
      `,
        h4: `
        font-family: 'Cera Pro';
        font-size: 1.125rem;
        line-height: 122%;
        font-weight: 500;
      `,
        p1: `
        font-family: Cera Pro;
        font-size: 1rem;
        line-height: 150%;
        font-weight: 400;
      `,
        p2: `
        font-family: Cera Pro;
        font-size: 0.875rem;
        line-height: 122%;
        font-weight: 400;
      `,
        p3: `
        font-family: Cera Pro;
        font-size: 0.75rem;
        line-height: 122%;
        font-weight: 400;
      `
      }
    },
    layout: {
      headerHeight: {
        desktop: '88px',
        mobile: '62px'
      },
      gaps: {
        none: '0px',
        xs: '4px',
        sm: '8px',
        md: '16px',
        lg: '24px',
        xl: '32px'
      },
      maxWidth: '80rem',
      maxWidthPadding: {
        desktop: '18px',
        mobile: '20px'
      },
      narrowMaxWidth: '50rem',
      productImageAspectRatio: '1:1',
      imageSizes: {
        grid: [300, 300, 300, 300, 300],
        productPage: [1, 1, 1, 300, 600],
        hero: [1, 1, 1, 1, 1],
        rowImage: [1, 1, 1, 300, 600]
      }
    },
    button: {
      desktop: {
        height: '35px',
        padding: '0px 30px',
        borderRadius: '3px'
      },
      mobile: {
        height: '35px',
        padding: '0px 30px',
        borderRadius: '3px'
      }
    },
    new: 'yes'
  },
  fontSizeBaseValue
};
