import React from 'react';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import { cx } from 'linaria';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';
import { cartProductCardStyle } from './CartProductCard.style';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useSetQuantity from '@jetshop/core/components/Mutation/useSetQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import { QtyAdjuster } from '../QtyAdjuster';
import removeFromCartMutation from '../queries/removeFromCart.gql';
import incrementQuantityMutation from '../queries/incrementQuantity.gql';
import decrementQuantityMutation from '../queries/decrementQuantity.gql';
import setQuantityMutation from '../queries/setItemQuantity.gql';
import cartQuery from '../queries/cartQuery.gql';
import { Price } from '@jetshop/ui/Price';
import { SVG } from '../../../../../assets/SVG';
import { theme } from '../../../../Theme';
import { useIntl } from '@jetshop/intl';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

export const CartProductCard = ({ item, linkClickCallback }) => {
  const { loggedIn } = useAuth();
  const t = useIntl();
  const { variantImage, hasVariantImage } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const { setQuantity } = useSetQuantity({
    setQuantityMutation,
    cartQuery
  });

  const handleInCrementQuantity = () => {
    incrementQuantity({ itemId: item.id });
  };
  const handleDeCrementQuantity = () => {
    decrementQuantity({ itemId: item.id });
  };

  const handleSetQantity = newQty => {
    setQuantity({ itemId: item.id, quantity: newQty });
  };

  const product = item?.product;
  const productImage = hasVariantImage
    ? variantImage
    : product?.images?.[0]?.url ?? transparentDataImg;

  // Remove the last part of the path untill first / from right.
  const path = item?.product?.categories?.slice(-1)?.[0]?.primaryRoute?.path;
  const regex = /\/[^/]*$/;
  const newPath = path?.replace(regex, '');
  const standardPrice = item?.product?.defaultPrice;
  const campaign = item?.product?.campaigns?.find(campaign =>
    campaign.name.includes('Life Club')
  );
  const percentage =
    parseFloat(campaign?.name.match(/\d+%|([0-9]\d?)\.\d+%/g)) / 100;
  const memberPrice = loggedIn &&
    percentage > 0 && {
      incVat: Math.round(
        standardPrice.incVat - standardPrice.incVat * percentage
      )
    };

  return (
    <div className={cx('cart-product-card', cartProductCardStyle)}>
      <Image
        sizes={theme.layout.imageSizes.grid}
        aspect={theme.layout.productImageAspectRatio}
        alt={product?.images?.[0]?.alt}
        src={productImage}
        fillAvailableSpace={true}
        modifiedDate={product?.images?.[0]?.modifiedDate}
        critical={true}
      />

      <div className="product-information">
        <LinkEvaluator
          linkClickCallback={linkClickCallback}
          link={product?.primaryRoute?.path}
        >
          <p>{product?.name}</p>
          {memberPrice ? (
            <div className="member-price-wrapper cart-product-price">
              <span className="title">{t('Member price')}</span>
              <span className="offer-price">
                <Price price={memberPrice} previousPrice={standardPrice} />
              </span>
              {!loggedIn && (
                <span className="standard-price">
                  {t('Not member')} <Price price={standardPrice} />
                </span>
              )}
            </div>
          ) : (
            <div className="cart-product-price">
              <Price
                hidePrice={product?.hidePrice}
                price={product?.price}
                previousPrice={product?.previousPrice}
              />
            </div>
          )}
        </LinkEvaluator>
        <div>
          {item?.product?.stockStatus?.buyable ? (
            <QtyAdjuster
              qty={item?.quantity}
              incrementCallback={handleInCrementQuantity}
              decrementCallback={handleDeCrementQuantity}
              setQuantityCallback={handleSetQantity}
            />
          ) : (
            <p className="out-of-stock">
              {t('Out of stock, ')}
              <a href={newPath}>{t('see options.')}</a>
            </p>
          )}
        </div>
      </div>

      <div className="remove">
        <button onClick={() => removeFromCart({ itemId: item.id, product })}>
          <div className="svg-wrapper">
            <SVG name={'X'} />
          </div>
        </button>
      </div>
    </div>
  );
};
