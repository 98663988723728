import React from 'react';
import { cx } from 'linaria';
import Image from '@jetshop/ui/Image';
import {
  LinkEvaluator,
  ContentPositioner,
  CTA,
  libTheme
} from 'r3-lib/dist/r3lib';
import { Above } from '@jetshop/ui/Breakpoints';

export const CollageRow = ({ fullWidth, gap, children }) => {
  const childClassName = children?.length;

  // fix for Safari
  const numberToText = number => {
    switch (number) {
      case 1:
        return 'one';
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4:
        return 'four';

      default:
        return 'number-not-found';
    }
  };

  return (
    <div
      className={cx(
        'collage-row',
        'items-' + childClassName,
        fullWidth?.value && 'fullwidth'
      )}
      style={{
        columnGap: libTheme.gaps[gap?.value],
        rowGap: libTheme.gaps[gap?.value]
      }}
    >
      <>
        {children?.map((child, index) => {
          return (
            <div
              key={index + '-collage-item'}
              className={cx(
                'div-' + numberToText(index + 1),
                fullWidth?.value && 'fullwidth',
                child?.props?.headerSize?.value
              )}
            >
              {child}
            </div>
          );
        })}
      </>
    </div>
  );
};

export const CollageRowItem = ({
  desktopImage,
  mobileImage,
  verticalPosition,
  horizontalPosition,
  color,
  preTitle,
  title,
  boldTitle,
  h1,
  text,
  buttonText,
  buttonTheme,
  contentDarkenGradient,
  link,
  style
}) => {
  const imageSizes = [1, 1, 1 / 2, 1 / 2];
  const Title = h1?.value ? 'h1' : 'h2';
  return (
    <Above breakpoint="lg">
      {matches => (
        <div className={cx('collage-row-item', style)}>
          <LinkEvaluator link={link}>
            <div className="collage-row-item-image-wrapper">
              <Image
                sizes={imageSizes}
                src={matches ? desktopImage?.value : mobileImage?.value}
                fillAvailableSpace={true}
                aspect="1:1"
              />
            </div>
            <ContentPositioner
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              color={color}
              contentDarkenGradient={contentDarkenGradient}
            >
              {preTitle?.value && (
                <div
                  className="content-pre-title"
                  dangerouslySetInnerHTML={{
                    __html: preTitle?.value
                  }}
                />
              )}
              {title?.value && (
                <Title
                  className={'content-title'}
                  dangerouslySetInnerHTML={{
                    __html: title?.value
                  }}
                />
              )}

              {text?.value && (
                <div
                  className="content-text"
                  dangerouslySetInnerHTML={{
                    __html: text?.value
                  }}
                />
              )}
              {buttonText?.value && (
                <CTA className={buttonTheme?.value}>{buttonText?.value}</CTA>
              )}
            </ContentPositioner>
          </LinkEvaluator>
        </div>
      )}
    </Above>
  );
};
