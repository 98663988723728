import React from 'react';
import { styled } from 'linaria/react';

const TitleWrapper = styled('div')`
  max-width: 90%;
  display: flex;
  justify-content: ${props =>
    props.position === 'center'
      ? 'center'
      : props.position === 'left'
      ? 'flex-start'
      : 'flex-end'};
  padding: 1rem 0;
  margin: 0 auto;
`;

export const Title = ({ h1, title, horizontalPosition }) => {
  return (
    <TitleWrapper position={horizontalPosition?.value}>
      {title?.value && h1?.value ? (
        <h1>{title?.value}</h1>
      ) : title?.value ? (
        <h2>{title?.value}</h2>
      ) : null}
    </TitleWrapper>
  );
};
