import React from 'react';
import { styled } from 'linaria/react';
import { COMPONENTS } from './ContentRenderer';
import NavLinkWithImageBox from './navLinkComponents/NavLinkWithImageBox';
import theme from '../../ContentEditor/OldComponents/Theme';
import { toRem } from '../../../helpers';
import useChildren from '../../../hooks/useChildren';
import MaxWidth from './MaxWidth';

const NavlinksWrapper = styled(MaxWidth)`
  margin-top: 30px;
  ${theme.below.md} {
    padding-left: ${toRem(16)};
    padding-right: ${toRem(16)};
  }
`;
const NavBoxWrapper = styled.div`
  display: grid;
  gap: ${toRem(16)};
  grid-template-columns: repeat(2, calc(50vw - 24px));
  justify-items: stretch;
  align-items: stretch;

  ${theme.above.sm} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;
const Title = styled.h2`
  ${theme.fonts.h2}
  text-align: center;
  color: ${theme.colors.forest};
  margin-bottom: ${toRem(32)};

  ${theme.below.md} {
    font-size: ${toRem(28)};
  }
`;
const CategoryLinksWithImagesSection = props => {
  const allProps = useChildren(props);
  const navBoxes = allProps[COMPONENTS.NAVIGATION_LINK_WITH_IMAGE];

  if (!navBoxes) return;

  return (
    <NavlinksWrapper className="odd-even-background">
      {allProps?.title && <Title>{allProps.title}</Title>}
      <NavBoxWrapper>
        {navBoxes.map(navBox => {
          return (
            <NavLinkWithImageBox
              image={navBox.image}
              imageDescription={navBox.imageDescription}
              key={navBox.key}
              link={navBox.link}
              title={navBox.title}
            />
          );
        })}
      </NavBoxWrapper>
    </NavlinksWrapper>
  );
};

export default CategoryLinksWithImagesSection;
