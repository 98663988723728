import React from 'react';
import ReactModal from 'react-modal';
import { styled } from 'linaria/react';
import { cx } from 'linaria';

import { SVG } from '../../../assets/SVG';
import { theme } from '../../Theme';

const CustomPopupWrapper = styled('div')`
  position: fixed;
  z-index: 10001;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translateX(-50%) translateY(-50%);
  max-width: 780px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: 85vh;
  min-height: 100px;
  height: fit-content;
  overflow-y: scroll;
  background: white;

  .inner {
    width: 100%;
    position: relative;

    .link-evaluator {
      text-decoration: none;
      color: ${theme.colors.black};
    }

    .close-icon {
      cursor: pointer;
      z-index: 1;
      position: absolute;
      top: 0px;
      right: 0px;
      width: 33px;
      height: 33px;
      padding: 9px 9px 9px 10px;
      background: ${theme.colors.white};
      color: ${theme.colors.black};
    }
  }

  ${theme.above.md} {
    min-width: 780px;
  }

  ${theme.below.lg} {
    width: 90vw;
  }

  ${theme.above.lg} {
    &.left {
      left: 0;
      right: auto;
      &.bottom {
        transform: translateY(0%);
        bottom: 0;
        top: auto;
      }

      &.middle {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
      }

      &.top {
        top: 0;
        bottom: auto;
        transform: translateY(0%);
      }
    }
    &.center {
      left: 50%;
      right: 50%;
      &.bottom {
        transform: translateX(-50%);
        bottom: 0;
        top: auto;
      }

      &.middle {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%) translateX(-50%);
      }

      &.top {
        top: 0;
        bottom: auto;
        transform: translateX(-50%);
      }
    }

    &.right {
      right: 0;
      left: auto;
      &.bottom {
        transform: translateX(0%);
        bottom: 0;
        top: auto;
      }

      &.middle {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%) translateX(0%);
      }

      &.top {
        top: 0;
        bottom: auto;
        transform: translateX(0%);
      }
    }
  }
`;

const CustomPopupOverlay = styled('div')`
  background: #ffffff66;
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.blur {
    backdrop-filter: blur(2.2px);
  }

  &.light {
    background: rgba(255, 255, 255, 0.5);
  }
`;

ReactModal.setAppElement('#root');

export const PopupContent = ({
  open,
  setOpen,
  verticalPosition = 'middle',
  horizontalPosition = 'center',
  overlayClassNames = [],
  children
}) => {
  const handleCloseClick = () => {
    setOpen(false);
  };

  const overlaysString = overlayClassNames?.join(' ');
  return (
    <>
      {open && (
        <CustomPopupWrapper
          className={cx(verticalPosition, horizontalPosition)}
        >
          <div className="inner">
            <SVG
              name={'Cross'}
              className={'close-icon'}
              onClick={handleCloseClick}
              data-testid="popup-close"
            />

            {children}
          </div>
        </CustomPopupWrapper>
      )}
      {open && overlaysString?.length > 0 && (
        <CustomPopupOverlay
          onClick={() => {
            setOpen(false);
          }}
          className={cx(overlaysString)}
        />
      )}
    </>
  );
};
