import React from 'react';

import { StyledProductCard } from '../../CategoryPage/ProductCard/ProductCard';

export const ProductRowItem = ({ product }) => {
  return (
    <StyledProductCard
      key={product?.value?.articleNumber}
      product={product?.value}
      as="div"
    />
  );
};
