import { css } from 'linaria';
import { theme } from '../../../Theme';

export const titledIconActionStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.forest};
  align-self: center;
  cursor: pointer;

  svg {
    height: 25px;
  }

  h4 {
    margin-top: 6px;

    ${theme.fonts.desktop.p3}
    ${theme.below.lg} {
      ${theme.fonts.mobile.p3}
    }
    line-height: 100%;
  }
`;
