import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/HomeCategoriesQuery.gql';
import routeQuery from './components/RouteQuery.gql';
import {
  LoadableStandardCategoryPage,
  LoadableWindowedCategoryPage
} from './components/CategoryPage/CategoryPage';
import { theme } from './components/Theme';
//import createFacebookTracker from '@jetshop/core/server/tracking/facebook';
import ProductPage from './components/ProductPage/ProductPage.loadable';
import ContentPage from './components/ContentPage/ContentPage.loadable';

const config = {
  theme,
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'life-fi',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io/',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: homeCategoriesQuery,
        variables: { levels: 1 }
      }
    ]
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID, //Non Vita || 'UA-39872214-1'
  additionalGtagTrackingIds: [],
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID, //Non Vita || 'GTM-M4DSV53'
  relewareEnabled: false,
  sentry: {
    clientDSN:
      'https://a5d86b35ee894f11ac7d9d376924a462@o4504999543767040.ingest.sentry.io/4505266323259392',
    serverDSN:
      'https://a5d86b35ee894f11ac7d9d376924a462@o4504999543767040.ingest.sentry.io/4505266323259392',
    ignoreErrors: []
  },
  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          EUR: { style: 'currency', currency: 'EUR' },
          SEK: {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 0,
            currencyDisplay: 'symbol'
          },
          USD: { style: 'currency', currency: 'USD' },
          GBP: { style: 'currency', currency: 'GBP' },
          NOK: {
            style: 'currency',
            currency: 'NOK',
            minimumFractionDigits: 0,
            currencyDisplay: 'symbol'
          },
          NKR: {
            style: 'currency',
            currency: 'NOK',
            minimumFractionDigits: 0,
            currencyDisplay: 'symbol'
          },
          AUD: { style: 'currency', currency: 'USD' }
        }
      }
    }
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: false,
  schemaExtensions: [],
  preserveRedirect: false,
  structuredData: {
    disableDefaultProductData: false // Non Vita - true
  },
  openGraph: {
    disableDefaultProductData: false
  },
  trackers: [],
  // serverTrackers: [
  //   createFacebookTracker({
  //     pixelId: process.env.FACEBOOK_PIXEL_ID,
  //     token: process.env.FACEBOOK_CAPI_TOKEN
  //   })
  // ],

  // Non Vita
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  googleMapsCountryCenter: {
    lat: process.env.REACT_APP_GOOGLE_MAPS_LAT,
    lng: process.env.REACT_APP_GOOGLE_MAPS_LONG
  } || {
    lat: 59.247948,
    lng: 14.755806
  },
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [ProductPage],
      Category: [LoadableStandardCategoryPage, LoadableWindowedCategoryPage],
      Page: [ContentPage]
    }
  },
  usePrimaryRouteForProducts: false,
  useTrackingConsentAPI: true,
  brandCustomField: 'Brand'
};
export default config;
