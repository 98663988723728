import React from 'react';
import { cx } from 'linaria';
import { searchBarCancelButtonStyle, searchBarStyle } from './SearchBar.style';
import { SVG } from '../../../../assets/SVG';
import { useHistory } from 'react-router';
import { useElevate } from '../../../Elevate/Context';
import { styled } from 'linaria/react';
import { StyledProductCard } from '../../../CategoryPage/ProductCard/ProductCard';
import { getProductStructure } from '../../../Elevate/utils/getProductStructure';
import t from '@jetshop/intl';
import { theme } from '../../../Theme';
import { Link } from 'react-router-dom';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { GreenBar } from '../GreenBar/GreenBar';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { useModalManagement } from '../Flyout/useModalManagement';

const SearchResult = styled.div`
    z-index: 99;
.menu-cover{
  position:absolute;
  top:-45px;
  left:0;
  right:0;
  height:45px;
  background-color:transparent;
}

font-weight: normal;
font-size: 1rem;
color: black;
left: 0 !important;
right: 0 !important;
width: 100vw;
max-width: 100vw;
top: auto !important;
bottom: 0;
height: 0px;
position: absolute;
will-change: top, left;
color: ${theme.colors.black};

  .searchResultGrid {
    background-color: white;
    width: 100%;
    height: auto;
    padding:1rem;
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto repeat(2, 1fr) 100px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

.showAll { 
    grid-area: 4 / 1 / 5 / 3; 
    display:flex;
    justify-content:center;
    align-items:center;

    a {
        display:flex;
        justify-content:center;
        align-items:center;
        text-decoration:none;
        padding: 0.5rem 1rem;
        background-color: ${theme.colors.forest} !important;
        color: #fff !important;
        font-weight: 700 !important;
        font-family: Cera Pro;
        font-size: 14px !important;
        line-height: 15px !important;
        border-radius: 3px !important;
        text-align: center;
        height: 43px !important;
    }
  }

  span {
    font-weight: bold;
    color:${theme.colors.forest};
    cursor:pointer;
  }

  .categories {
    grid-area: 2 / 1 / 4 / 2; 

    .listTitle {
      margin-bottom: 8px;
        ${theme.fonts.desktop.p2}
        font-weight: ${theme.fontWeights.bold};
        line-height: 180%;
    }

    .list{
      margin-bottom:1.5rem;

      .categoryItem {
        font-weight: 400!important;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 100%;
          color: #333;
          text-decoration: none;
          padding: 6px 0;

          span {
            font-weight: inherit;
            text-transform: capitalize;
          }
      }
    }

      a {
        color:${theme.colors.black};
        text-decoration:none;
      }
  }


  .productResults {
    grid-area: 2 / 2 / 4 / 3;
    display:flex;
    > div {
      flex-basis:25%;
    }
  }

  .green-bar {
    margin-bottom: 2rem;
  }
  .search-title {
    color: ${theme.colors.black};
    text-decoration: none;
    ${theme.fonts.desktop.h3};
    font-weight: ${theme.fontWeights.bold};
  }






  ${theme.below.lg} {
    .menu-cover{
      display:none;
    }
      .searchResultGrid {

    background-color: white;
    width: 100%;
    height: auto;
            height: calc(100dvh - 132px);
    padding:0rem;
    display: flex;
    flex-direction: column;
    .green-bar{
      display:none;
    }

    .productResults {
      overflow-x: scroll;
      & >div{
        flex-basis:75%;
        min-width:60vw;
      }
    }

    .categories{
      .listTitle{
    font-family: Cera Pro;
    font-style: normal;
    line-height: 120%;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    margin: 0!important;
    background: #d1e0d7;
    padding: 13px 17px;
    font-weight: 500;
    font-size: 16px;
      }

      .list{
        margin-bottom:0;
        .categoryItem{
              line-height: 1.5;
    font-weight: 400;
    color: #333;
    font-family: Cera Pro;
    font-size: 14px;
    text-transform: capitalize;
    position: relative;
    display: flex!important;
    align-items: center;
    margin: 0;
    border-bottom: 1px solid #f7f7f7;
    padding: 13px 17px;
                &:nth-child(n+3) {
          display:none!important;
        }
        }
      }
    }
  }

  }
`;

function highlighter(input) {
  // Use a regular expression to find text inside curly braces
  const regex = /{([^}]+)}/g;
  // Replace the found matches with <strong> tags
  const formattedText = input.replace(regex, '<strong>$1</strong>');
  return formattedText;
}

export const SearchBar = ({ placeholder, style, ...props }) => {
  const { runQuery, searchResult, searchOpen, setSearchOpen } = useElevate();
  const [searchTerm, setSearchTerm] = React.useState('');

  const closeSearch = () => {
    setSearchOpen(false);
  };
  const { closeAll } = useModalManagement();

  const ref = useClickOutside(closeSearch);

  const history = useHistory();
  const { routes } = useShopConfig();
  const keyactions = {
    Escape: () => {
      closeAll();
      setSearchOpen(false);
      setSearchTerm('');
    },
    Enter: () => {
      //ENTER button action triggered
      setSearchOpen(false);
      closeAll();
      setSearchTerm('');
      history?.push(
        `${routes?.search?.path}?term=${encodeURIComponent(searchTerm)}`
      );
    }
  };

  const updateTerm = e => {
    runQuery(e.target.value);
    setSearchTerm(e.target.value);
    if (e.target.value.length > 0) {
      setSearchOpen(true);
    } else {
      setSearchOpen(false);
    }
  };

  const updateHijack = val => {
    updateTerm({ target: { value: val } });
  };

  return (
    <div ref={ref} className={cx('search-bar', style)}>
      <SVG name={'Magnifier'} />
      <div
        className={cx('search-bar-input', style && searchBarCancelButtonStyle)}
      >
        <input
          type={'search'}
          placeholder={placeholder}
          onChange={updateTerm}
          onKeyDown={e => {
            keyactions?.[e?.key]?.();
          }}
          value={searchTerm}
          {...props}
        />
      </div>

      {searchOpen && (
        <SearchResult>
          <div className="menu-cover" onClick={closeSearch} />
          <div className="searchResultGrid">
            <GreenBar>
              <Link
                to={`${routes?.search?.path}/?term=${encodeURIComponent(
                  searchTerm
                )}`}
                onClick={() => {
                  setSearchTerm('');
                  closeSearch();
                }}
                className="search-title"
              >
                {t('You searched for "{searchTerm}"', {
                  searchTerm
                })}
              </Link>
            </GreenBar>

            <div className="categories">
              {searchResult?.phraseSuggestions?.length > 0 && (
                <>
                  <h4 className="listTitle">{t('Suggested phrases')}</h4>
                  <div className="list">
                    {searchResult?.phraseSuggestions?.map(c => {
                      return (
                        <div
                          className="categoryItem"
                          onClick={() => updateHijack(c.q)}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: highlighter(c.highlighted)
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {searchResult?.contentSuggestions?.length > 0 && (
                <>
                  <h4 className="listTitle">{t('Suggested categories')}</h4>
                  <div className="list">
                    {searchResult?.contentSuggestions?.map(c => {
                      return (
                        <div className="categoryItem" onClick={closeSearch}>
                          <Link
                            to={c.link}
                            dangerouslySetInnerHTML={{
                              __html: highlighter(c.highlightedTitle)
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
            <div className="productResults">
              {searchResult?.productSuggestions?.map(p => {
                const product = p?.products?.[0];

                const newProduct = getProductStructure(product);
                const handleClickCallback = e => {
                  // THIS WILL PREVENT SEARCH FROM CLOSING WHEN CLICKING ON SVG ICONS (FAVORITE, QUICKBUY ETC)
                  // TODO - ADJUST FOR RATINGS CLICK
                  if (e?.target?.tagName !== 'svg') {
                    closeSearch();
                  }
                };
                return (
                  <StyledProductCard
                    product={newProduct}
                    clickCallback={handleClickCallback}
                  />
                );
              })}
            </div>
            <div className="showAll" onClick={closeSearch}>
              <Link to={`/search/?term=${encodeURIComponent(searchTerm)}`}>
                {t('Show full result')}
                {/* Show all results for "{searchTerm}" */}
              </Link>
            </div>
          </div>
        </SearchResult>
      )}
    </div>
  );
};

export const StyledSearchBar = ({
  placeholder,
  searchvalue,
  setsearchvalue,
  keyactions,
  ...props
}) => {
  return (
    <SearchBar
      placeholder={placeholder}
      searchvalue={searchvalue}
      keyactions={keyactions}
      style={searchBarStyle}
      {...props}
    />
  );
};
