import React from 'react';
import Image from '@jetshop/ui/Image';
import { css, cx } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { BoxBreadcrumbs } from '../ui/vita/Breadcrumbs/Breadcrumbs';
import { Above } from '@jetshop/ui/Breakpoints';
import { ContentPositioner } from 'r3-lib/dist/r3lib';

const headerBlockStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${theme.layout.maxWidth};
  width: 100%;
  margin: 0 auto;
  background: ${theme.colors.backgroundGrey};
  position: relative;

  ${theme.below.lg} {
    flex-direction: column;
  }

  > * {
    width: 50%;
    ${theme.below.lg} {
      width: 100%;
    }
  }

  &.fullwidth {
    max-width: 100%;
  }

  .content-wrapper {
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    &.no-image {
      ${theme.above.lg} {
        align-items: center;

        .box-breadcrumbs {
          width: fit-content;
        }
      }
    }

    ${theme.below.lg} {
      padding: 40px 20px;
      align-items: start;
    }

    .title {
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
      color: #333333;
      margin-bottom: 10px;
    }

    a {
      color: inherit;
    }

    .description {
      line-height: 158%;
      p {
        font-size: 14px;
      }
    }
  }

  .image-wrapper {
    position: relative;

    .content-positioner {
    }
  }
`;

const ImageTextWrapper = styled('div')`
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 40px 60px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  ${theme.below.lg} {
    padding: 40px 20px;
  }

  .image-text {
    font-family: 'Cera Pro';
    font-size: ${props => props.txtsize};
    line-height: 122%;
    font-weight: 500;
    /* text-align: center; */
    color: ${props => props.txtcolor};

    /* ${theme.below.lg} {
      text-align: start;
    } */
  }
`;

export const propEval = (prop, defaultValue) => {
  return prop?.value?.value ?? prop?.value ?? defaultValue;
};

export const HeaderBlock = ({
  fullWidth,
  desktopImage,
  desktopImageDescription,
  desktopImageAspect,
  mobileImage,
  mobileImageDescription,
  mobileImageAspect,
  imageText,
  imageTextColor,
  imageTextSize,
  verticalPosition,
  horizontalPosition,
  breadcrumbs,
  h1,
  title,
  text
}) => {
  const Header = propEval(h1) ? 'h1' : 'h2';
  const imageTextValue = propEval(imageText, false);
  const imageTextColorValue = propEval(imageTextColor, '#fff');
  const imageTextSizeValue = propEval(imageTextSize, 'small');
  const chosenImageTextSize =
    imageTextSizeValue === 'small'
      ? '32px'
      : imageTextSizeValue === 'medium'
      ? '40px'
      : '60px';

  return (
    <Above breakpoint="lg">
      {matches => (
        <section
          className={cx(
            'header-block',
            headerBlockStyle,
            propEval(fullWidth) && 'fullwidth'
          )}
        >
          {/*ONLY SHOW IMAGE IF IMAGE IS ADDED */}
          {(matches ? propEval(desktopImage) : propEval(mobileImage)) && (
            <div className={cx('image-wrapper')}>
              <Image
                alt={
                  matches
                    ? propEval(desktopImageDescription)
                    : propEval(mobileImageDescription)
                }
                src={matches ? propEval(desktopImage) : propEval(mobileImage)}
                cover
                aspect={
                  matches
                    ? propEval(desktopImageAspect)
                    : propEval(mobileImageAspect)
                }
                sizes={[1, 1, 1, 1 / 2, 1 / 2]}
                critical={true}
              ></Image>

              {imageTextValue && (
                <ContentPositioner
                  verticalPosition={verticalPosition}
                  horizontalPosition={horizontalPosition}
                >
                  <ImageTextWrapper
                    txtcolor={imageTextColorValue}
                    txtsize={chosenImageTextSize}
                  >
                    <div
                      className="image-text"
                      dangerouslySetInnerHTML={{
                        __html: imageTextValue
                      }}
                    />
                  </ImageTextWrapper>
                </ContentPositioner>
              )}
            </div>
          )}
          <div
            className={cx(
              'content-wrapper',
              !propEval(desktopImage) && 'no-image'
            )}
          >
            {propEval(breadcrumbs) && (
              <BoxBreadcrumbs textColor={'#707070'} align={'left'} />
            )}

            {propEval(title) && (
              <Header className="title">{propEval(title)}</Header>
            )}
            {propEval(text) && (
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: propEval(text)
                }}
              />
            )}
          </div>
        </section>
      )}
    </Above>
  );
};
