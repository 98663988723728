import React from 'react';
import { StyledDesktopHeader } from './DesktopHeader/DesktopHeader';
import { Above } from '@jetshop/ui/Breakpoints';
import { StyledMobileHeader } from './MobileHeader/MobileHeader';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Notifications } from '../Notifications';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import { DynamicCategoryRenderer } from '../../ContentEditor/DynamicCategoryRenderer/DynamicCategoryRenderer';
import { SiteWideBannerComponents } from '../../ContentEditor/ContentComponents';

const Header = () => {
  const listId = null;
  const { routes } = useShopConfig();
  const { count } = useProductList(listId);

  return (
    <>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <StyledDesktopHeader routes={routes} favouritesCount={count} />
          ) : (
            <StyledMobileHeader routes={routes} favouritesCount={count} />
          )
        }
      </Above>
      <DynamicCategoryRenderer
        categoryId={process?.env?.REACT_APP_SITE_WIDE_BANNER}
        rendererComponents={SiteWideBannerComponents}
      />
      <Notifications />
    </>
  );
};

export default Header;
