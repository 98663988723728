import { styled } from 'linaria/react';
import { theme } from '../Theme';

const MaxWidth = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 0.75rem;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: ${theme.layout.maxWidth};
  padding-bottom: 2rem;

  ${theme.below.lg} {
    padding: 0 23px 20px 23px;
  }
`;

export default MaxWidth;
