import React from 'react';
import { cx } from 'linaria';
import { promotionBadgeStyle } from './PromotionBadge.style';
export const PromotionBadge = ({
  backgroundColor,
  foregroundColor,
  text,
  size,
  verticalPosition,
  horizontalPosition
}) => {
  return (
    <div className={cx('promotion-badge', promotionBadgeStyle, 'large')}>
      <div
        className="promotion-badge-circle"
        style={{
          background: backgroundColor?.value,
          color: foregroundColor?.value
        }}
      >
        <div
          className={cx('promotion-badge-text', size?.value)}
          dangerouslySetInnerHTML={{ __html: text?.value }}
        />
        <div
          className="promotion-badge-dot"
          style={{
            background: backgroundColor?.value
          }}
        />
      </div>
    </div>
  );
};
