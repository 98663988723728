import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { theme } from '../Theme';

const CategoriesLinksWrapper = styled('div')`
  margin: 0 30px;
  max-width: 100%;
  p {
    font-weight: 500;
  }

  ${theme.below.lg} {
    margin: 0 30px 1rem;
  }
`;

const CategoryNavigation = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${theme.below.lg} {
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const CategoryNavigationItem = styled(Link)`
  padding: 10px 30px;
  background: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  border-radius: 3px;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${props => props.hoverBackgroundColor};
    color: ${props => props.hoverTextColor};
  }

  ${theme.below.lg} {
    width: 100%;
    min-width: fit-content;
    text-align: center;

    &:first-child {
      margin-left: 10px;
    }
  }
`;

export const CustomSubcategoryLinksItem = ({
  text,
  link,
  textColor,
  backgroundColor,
  hoverTextColor,
  hoverBackgroundColor
}) => {
  return (
    <CategoryNavigationItem
      textColor={textColor?.value}
      backgroundColor={backgroundColor?.value}
      hoverTextColor={hoverTextColor?.value}
      hoverBackgroundColor={hoverBackgroundColor?.value}
      to={link?.value}
    >
      {text?.value}
    </CategoryNavigationItem>
  );
};

export const CustomSubcategoryLinks = ({ children }) => {
  return (
    <CategoriesLinksWrapper>
      <CategoryNavigation>{children}</CategoryNavigation>
    </CategoriesLinksWrapper>
  );
};
