import React from 'react';
import Image from '@jetshop/ui/Image';
import { seoStyle } from './../../components/ui/vita/styles/Seo.style';
import { cx } from 'linaria';

// const getImagePlacement = placement => {
//   switch (placement) {
//     case 'center':
//       return 'center';
//     case 'bottom':
//       return 'end';
//     default:
//       return 'start';
//   }
// };

const Seo = ({
  blockText,
  buttonLabel,
  buttonLink,
  image,
  imageDescription,
  belowImageText,
  textPlacement,
  imagePlacement,
  imageAspectRatio,
  title,
  script
}) => {
  const componentContent = (
    <>
      <div
        className={cx(
          'article-text-block',
          'text-wrapper',
          image?.value?.value && 'image'
        )}
      >
        {title?.value && (
          <h2
            className="section-title"
            style={{ textAlign: textPlacement?.value }}
          >
            {title?.value}
          </h2>
        )}
        {blockText?.value && (
          <div
            className="section-text"
            style={{ textAlign: textPlacement?.value }}
            dangerouslySetInnerHTML={{ __html: blockText?.value }}
          />
        )}
        {script?.value && (
          <div
            className="section-text"
            style={{ textAlign: textPlacement?.value }}
            dangerouslySetInnerHTML={{ __html: script?.value }}
          />
        )}
        {buttonLabel?.value && buttonLink?.value && (
          <div style={{ textAlign: textPlacement?.value }}>
            <a className="button" href={buttonLink?.value}>
              {buttonLabel?.value}
            </a>
          </div>
        )}
      </div>
      {textPlacement?.value !== 'center' && image?.value?.value && (
        <>
          <div
            className="image-wrapper"
            style={{ alignItems: imagePlacement?.value || 'start' }}
          >
            <Image
              src={image?.value?.value}
              alt={imageDescription?.value || title?.value || ''}
              aspect={imageAspectRatio?.value || '4:3'}
              sizes={[1, 1 / 2, 1 / 4, 604]}
              cover
            />
            {belowImageText?.value && (
              <span className="pic-text">{belowImageText?.value}</span>
            )}
          </div>
        </>
      )}
    </>
  );
  let articleBlockComponent;
  if (textPlacement?.value === 'center') {
    articleBlockComponent = (
      <div className="text-center-wrapper">{componentContent}</div>
    );
  } else if (textPlacement?.value === 'right') {
    articleBlockComponent = (
      <div className="text-right-wrapper">{componentContent}</div>
    );
  } else
    articleBlockComponent = (
      <div className="text-left-wrapper">{componentContent}</div>
    );

  return (
    <div className={cx('article-main-wrapper', seoStyle)}>
      {articleBlockComponent}
    </div>
  );
};

export default Seo;
