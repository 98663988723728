import React from 'react';
import { styled } from 'linaria/react';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import MainNavLink from './MainNavLink';
import SectionWrapper from './SectionWrapper';
import RenderSubLinks from './RenderSubLinks';
import { theme } from '../../../Theme';
import { toRem } from '../../../../helpers';
import { ReactComponent as ArrowUp } from '../../../../assets/arrow-up.svg';

const ArrowToggle = styled(ArrowUp)`
  height: auto;
  width: ${toRem(20)};
  transform: ${props => (props.open ? `rotate(0deg)` : `rotate(180deg)`)};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.h3`
  ${theme.fonts.desktop.h3}
  color: ${theme.colors.moss};
  margin-right: ${toRem(10)};
`;

const MobileNavLinks = ({ navSections }) => (
  <Accordion single>
    {({ openIndexes, handleClick, AccordionContent }) => {
      return navSections.map((section, index) => {
        const sectionSubLinks = [
          { title: section.link1Title, url: section.link1Url },
          { title: section.link2Title, url: section.link2Url },
          { title: section.link3Title, url: section.link3Url },
          { title: section.link4Title, url: section.link4Url },
          { title: section.link5Title, url: section.link5Url },
          { title: section.link6Title, url: section.link6Url },
          { title: section.link7Title, url: section.link7Url },
          { title: section.link8Title, url: section.link8Url },
          { title: section.link9Title, url: section.link9Url },
          { title: section.link10Title, url: section.link10Url }
        ];
        const open = openIndexes.includes(index);

        return (
          <SectionWrapper key={section.key}>
            <HeaderWrapper onClick={() => handleClick(index)}>
              <Header>{section.title}</Header>
              <ArrowToggle open={open} />
            </HeaderWrapper>
            <AccordionContent isOpen={open}>
              <MainNavLink title={section.title} url={section.titleLink} />
              <RenderSubLinks links={sectionSubLinks} />
            </AccordionContent>
          </SectionWrapper>
        );
      });
    }}
  </Accordion>
);

export default MobileNavLinks;
