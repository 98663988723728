import React from 'react';
import { cx } from 'linaria';
import { socialMediaRowStyle } from './SocialMediaRow.style';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';
import { SVG } from '../../../../assets/SVG';

export const SocialMediaRow = ({
  title,
  foregroundColor,
  alignment,
  ...props
}) => {
  const logos = [];

  for (const prop in props) {
    if (props?.[prop]?.type === 'text' && props?.[prop]?.value?.length > 4) {
      logos?.push({
        name: prop?.charAt?.(0)?.toUpperCase?.() + prop?.slice(1),
        link: props?.[prop]?.value
      });
    }
  }

  return (
    <div
      className={cx('social-media', socialMediaRowStyle)}
      style={{ color: foregroundColor?.value }}
    >
      {title?.value?.length > 0 && <h4>{title?.value}</h4>}
      <div className="social-media-row">
        {logos?.map((logo, index) => {
          return (
            <LinkEvaluator key={`payment-logo-${index}`} link={logo?.link}>
              <SVG name={logo?.name} />
            </LinkEvaluator>
          );
        })}
      </div>
    </div>
  );
};
