import React from 'react';
import { BoxRowImageItem } from 'r3-lib/dist/r3lib';
import { PromotionBadge } from '../ui/vita/PromotionBadge/PromotionBadge';
import { StyledBoxRowvideoItem } from './BoxRowVideoItem/BoxRowVideoItem';

export const CustomBoxRowImageItem = props => {
  const isVideoItem = props?.desktopVideoUrl?.value?.length > 0;
  const isImageItem = props?.desktopImage?.value?.value?.length > 0;
  const desktopVideoUrl = props?.desktopVideoUrl?.value;
  const mobileVideoUrl = props?.mobileVideoUrl?.value;

  if (isVideoItem) {
    return (
      <StyledBoxRowvideoItem
        desktopVideoUrl={desktopVideoUrl}
        mobileVideoUrl={mobileVideoUrl}
        {...props}
      >
        {props?.promoBadgeText?.value?.length > 0 && (
          <PromotionBadge
            backgroundColor={props?.promoBadgeBackgroundColor}
            foregroundColor={props?.promoBadgeForegroundColor}
            text={props?.promoBadgeText}
          />
        )}
      </StyledBoxRowvideoItem>
    );
  } else if (isImageItem) {
    return (
      <BoxRowImageItem {...props}>
        {props?.promoBadgeText?.value?.length > 0 && (
          <PromotionBadge
            backgroundColor={props?.promoBadgeBackgroundColor}
            foregroundColor={props?.promoBadgeForegroundColor}
            text={props?.promoBadgeText}
          />
        )}
      </BoxRowImageItem>
    );
  } else {
    return null;
  }
};
