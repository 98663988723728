import { addToCartSuccess } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { useNotification } from '@jetshop/core/components/Notifications';
import { addToCart } from '../components/Cart/queries/addToCart.gql';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import { useMutation } from '@apollo/react-hooks';
import React, { useContext, useState, useEffect } from 'react';
import { QuickBuyContext } from '../components/Layout/QuickBuy/QuickBuyContext';
import ProductToastWrapper from '../components/ProductPage/components/AddToCart/ProductToast';

export const useAddToCart = (onComplete = () => {}) => {
  const [product, setProduct] = useState(null);
  const [qty, setQty] = useState(null);
  const { setQuickBuyProduct, setQuickBuyQty } = useContext(QuickBuyContext);

  const { cartId, setCartId } = useContext(CartIdContext);
  const [trigger] = useNotification();

  const [add] = useMutation(addToCart, {
    variables: {
      input: {
        cartId,
        articleNumber: product?.articleNumber,
        quantity: qty,
        comments: product?.customerComments ?? []
      }
    },
    onCompleted: data => {
      trigger(
        <ProductToastWrapper
          product={product}
          quantity={qty}
          price={{
            price: product?.price,
            previousPrice: product?.previousPrice
          }}
        />,
        { type: 'add-to-cart' }
      );

      onComplete();
      addToCartSuccess({ cartId, setCartId })({ data });
      setProduct(null);
      setQty(null);
      setQuickBuyProduct(null);
      setQuickBuyQty(null);
    }
  });

  // ADDS COMMENTS TO PRODUCT
  const commentsApplier = prod => {
    const comments = [];
    const EAN_KEY = 'EAN';
    const BRAND_KEY = 'Brand';
    const PRODUCT_SIZE_KEY = 'Product_Size';

    // CHECKS AND ADDS EAN
    const ean = prod?.barcode;
    if (ean?.length > 0) {
      comments.push({ name: EAN_KEY, value: ean });
    }

    // CHECKE AND ADDS BRAND
    const brand = prod?.customFields?.find(field => field.key === BRAND_KEY)
      ?.stringValue;
    if (brand?.length > 0) {
      comments.push({ name: BRAND_KEY?.toLowerCase(), value: brand });
    }

    //CHECKS AND ADDS PRODUCT SIZE
    const productSize = prod?.customFields?.find(
      field => field.key === PRODUCT_SIZE_KEY
    )?.stringValue;
    if (productSize?.length > 0) {
      comments.push({
        name: PRODUCT_SIZE_KEY?.toLowerCase(),
        value: productSize
      });
    }

    return { ...prod, customerComments: comments };
  };

  useEffect(() => {
    if (product && qty) {
      add();
    }
  }, [product, qty, add]);

  const addProduct = (product, qty) => {
    setQty(qty);
    setProduct(commentsApplier(product));
  };

  return { addProduct };
};
