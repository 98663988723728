import React from 'react';
import boot from '@jetshop/core/boot/client';
import Shop from './components/Shop';
import config from './shop.config';
import { getVoyadoTenantID, initVoyado } from './useVoyadoTracker';

const shopId = process.env.REACT_APP_SHOP_ID;
const tenantId = getVoyadoTenantID(shopId);

boot(<Shop />, {
  ...config,
  trackers: [
    ...config.trackers,
    {
      name: 'voyado',
      initBrowser() {
        initVoyado(tenantId);
      },
      trackCart({ cart }) {
        if (cart?.id) {
          // We're sending null as cartRef if this is not present,
          // meaning the cart is actually never emptied.
          window.__cartId__ = cart.id;
        }
      }
    }
  ]
});
