import React from 'react';
import { styled } from 'linaria/react';
import { Below } from '@jetshop/ui/Breakpoints';
import { COMPONENTS, useChildren, theme } from './Imports';
import { toRem } from '../../../helpers';
import MobileNavLinks from './navLinkComponents/MobileNavLinks';
import DesktopNavLinks from './navLinkComponents/DesktopNavLinks';

const NavlinksWrapper = styled('div')`
  ${theme.below.md} {
    padding-left: ${toRem(62)};
    padding-left: ${toRem(62)};
  }
`;
const Title = styled.h2`
  ${theme.fonts.h2}
  color: ${theme.colors.forest};
  margin-bottom: ${toRem(32)};

  ${theme.below.md} {
    font-size: ${toRem(28)};
  }
`;

const NavSectionsWrapper = styled.ul`
  ${theme.above.md} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const CategoryLinksWithLinksSection = props => {
  const allProps = useChildren(props);
  const navSections = allProps[COMPONENTS.NAVIGATION_LINK_WITH_SUB_LINKS];

  if (!navSections) return;

  return (
    <NavlinksWrapper className="odd-even-background">
      {allProps?.title && <Title>{allProps.title}</Title>}
      <NavSectionsWrapper>
        <Below breakpoint="md">
          {matches =>
            matches ? (
              <MobileNavLinks navSections={navSections} />
            ) : (
              <DesktopNavLinks navSections={navSections} />
            )
          }
        </Below>
      </NavSectionsWrapper>
    </NavlinksWrapper>
  );
};

export default CategoryLinksWithLinksSection;
