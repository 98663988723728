import { styled } from 'linaria/react';
import React from 'react';
import Image from '@jetshop/ui/Image';
import { newCategoryHeaderStyle } from './../../components/ui/vita/styles/NewCategoryHeader.style';
import { cx } from 'linaria';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import breadcrumbsQuery from '../ui/BreadcrumbsQuery.gql';
import { Breadcrumbs } from '../ui/Breadcrumbs';

const TempHeaderStyle = styled('div')`
  height: auto;
  .text-wrapper {
    padding: 20px;
  }
`;

const NewCategoryHeader = ({
  heroImage,
  imageDescription,
  heroTitle,
  pageTitle,
  pageDescription
}) => {
  const location = useLocation();
  const { data } = useQuery(breadcrumbsQuery, {
    variables: {
      path: location?.pathname
    },
    errorPolicy: 'all'
  });

  const breadcrumbProps = {
    breadcrumbText: data?.route?.object?.breadcrumbText,
    parents: data?.route?.parents
  };

  return (
    <TempHeaderStyle
      className={cx('header-article-page', newCategoryHeaderStyle)}
    >
      {heroImage?.value && (
        <div className="image-wrapper">
          <Image
            alt={imageDescription?.value}
            fillAvailableSpace={true}
            src={heroImage?.value?.value}
            sizes={[1, 1, 1, 1, 1]}
            critical={true}
          />
        </div>
      )}
      <div className="text-wrapper">
        <Breadcrumbs {...breadcrumbProps} />
        <h1 className="title">{pageTitle?.value}</h1>
        <p className="description">{pageDescription?.value}</p>
      </div>
    </TempHeaderStyle>
  );
};

export default NewCategoryHeader;
