import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import theme from '../Theme';
import { toRem } from '../../../../helpers';
import { Link } from 'react-router-dom';

const NavLinkWrapper = styled(Link)`
  background-color: ${theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${toRem(25)} ${toRem(20)};
  border-radius: ${toRem(12)};

  text-decoration: none;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.08);

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: none;
  }
`;
const ImageWrapper = styled.div`
  width: ${toRem(80)};
  height: ${toRem(80)};
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: ${toRem(10)};

  ${theme.above.md} {
    width: ${toRem(98)};
    height: ${toRem(98)};
  }
`;
const Title = styled.h4`
  ${theme.fonts.h4};
  font-size: ${toRem(18)};
  color: ${theme.colors.moss};
`;
const NavLinkBox = ({ image, imageDescription, link, title }) => (
  <NavLinkWrapper to={link}>
    <ImageWrapper>
      <Image
        src={image}
        alt={imageDescription}
        aspect="1:1"
        cover
        sizes={[1 / 6]}
      />
    </ImageWrapper>
    <Title>{title}</Title>
  </NavLinkWrapper>
);

export default NavLinkBox;
