import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import { toRem } from '../../../../helpers';

const ArticleCardContainer = styled('li')`
  .article-link {
    display: flex;
    flex-direction: column;
    padding-bottom: ${toRem(20)};
    background-color: ${theme.colors.aloe};
    text-decoration: none;
    height: 100%;

    ${theme.above.sm} {
      position: relative;
    }

    &:hover {
      background-color: ${theme.colors.forest};

      .title,
      .description,
      .read-more {
        color: ${theme.colors.aloe};
      }
    }
  }

  .title {
    padding: ${toRem(20)} ${toRem(20)} ${toRem(16)} ${toRem(20)};
    font-size: ${toRem(20)};
    line-height: ${toRem(24)};
    color: ${theme.colors.forest};
  }

  .description {
    display: -webkit-box;
    padding: 0 ${toRem(20)} ${toRem(20)} ${toRem(20)};
    max-height: ${toRem(90)};
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: ${toRem(22)};
    color: ${theme.colors.forest};

    ${theme.above.sm} {
      max-height: ${toRem(85)};
    }
  }
  .read-more {
    display: block;
    padding: ${toRem(20)} ${toRem(20)} 0;
    margin-top: auto;
    font-family: 'Cera Pro';
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    color: ${theme.colors.forest};
    text-decoration: underline;
  }
`;

const style = {
  ArticleCardContainer
};

export default style;
