import React from 'react';
import { Button } from './Imports';

const BlockLink = props => {
  const { externalLinkUrl, internalLinkUrl, children } = props;

  if (internalLinkUrl) {
    return (
      <Button.AreaAuto linkText={internalLinkUrl}>{children}</Button.AreaAuto>
    );
  }

  if (externalLinkUrl) {
    return (
      <Button.AreaAuto linkText={externalLinkUrl}>{children}</Button.AreaAuto>
    );
  }

  return children;
};
export default BlockLink;
