import React from 'react';
import { SVG } from '../../../../assets/SVG';
import { cx } from 'linaria';
import MaxWidth from '../../MaxWidth';
import { footerContentStyle } from './FooterContent.style';
import { Above } from '@jetshop/ui/Breakpoints';
import { footerLinksColumnStyle } from '../FooterLinksColumn/FooterLinksColumn.style';

export const FooterContent = ({
  backgroundColor,
  foregroundColor,
  showLogo,
  title,
  text,
  children
}) => {
  const filterCEItemsWithKey = (children, key) => {
    const filtered = [];
    const rest = [];

    children?.forEach(child => {
      if (child?.key.includes(key)) {
        filtered.push(child);
      } else {
        rest.push(child);
      }
    });

    return { filtered, rest };
  };

  const { filtered: columns } = filterCEItemsWithKey(
    children,
    'Footer-2-0-Column'
  );

  const { filtered: socialMediaLinks } = filterCEItemsWithKey(
    children,
    'socialMediaLinks'
  );

  const { filtered: footerBadges } = filterCEItemsWithKey(
    children,
    'footerBadge'
  );

  return (
    <Above breakpoint="lg">
      {matches => (
        <div
          className={cx('footer-content', footerContentStyle)}
          style={{
            background: backgroundColor?.value,
            color: foregroundColor?.value
          }}
        >
          {matches && (
            <MaxWidth className="max-width">
              {showLogo?.value && (
                <SVG name={process?.env?.REACT_APP_LOGO_NAME} />
              )}
            </MaxWidth>
          )}
          <MaxWidth className="max-width">
            <div className="full-grid">
              <div className="text-info">
                {!matches && showLogo?.value && (
                  <SVG name={process?.env?.REACT_APP_LOGO_NAME} />
                )}
                {title?.value?.length > 0 && <h3>{title?.value}</h3>}
                {text?.value?.length > 0 && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: text?.value
                    }}
                  />
                )}
                {!matches && (
                  <div className="footer-badge-wrapper">{footerBadges}</div>
                )}
              </div>

              <div className={cx('column-grid', footerLinksColumnStyle)}>
                {columns}
                {!matches && <>{socialMediaLinks}</>}
              </div>
              <div>
                {matches && <>{socialMediaLinks}</>}
                {matches && (
                  <div className="footer-badge-wrapper">{footerBadges}</div>
                )}
              </div>
            </div>
          </MaxWidth>
        </div>
      )}
    </Above>
  );
};
