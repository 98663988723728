import React, { useEffect, useState } from 'react';
import { cx } from 'linaria';
import {
  categoryMenuDesktopStyle,
  desktopHeaderButtonStyles,
  desktopHeaderStyles
} from './DesktopHeader.style';
import { SVG } from '../../../../assets/SVG';
import { StyledTitledIconAction } from '../../../ui/vita/TitledIconAction/TitledIconAction';
import { StyledSearchBar } from '../../../ui/vita/SearchBar/SearchBar';
import { StyledHeaderDropdown } from '../../../ui/vita/HeaderDropdown/HeaderDropdown';
import { StyledHeaderDropdownCategory } from '../../../ui/vita/CategoryMenuLink/CategoryMenuLink';
import { GreenBar } from '../../../ui/vita/GreenBar/GreenBar';
import { Link } from 'react-router-dom';
import { useIntl } from '@jetshop/intl';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { barHoverStyle } from '../../../ui/vita/styles/BarHover.style';
import { FlyoutTrigger } from '../../../ui/vita/Flyout/FlyoutTrigger';
import { useModalManagement } from '../../../ui/vita/Flyout/useModalManagement';
import Badge from '../../../ui/Badge';
import { DynamicCategoryRenderer } from '../../../ContentEditor/DynamicCategoryRenderer/DynamicCategoryRenderer';
import { campaignbarComponents } from '../../../ContentEditor/ContentComponents';
import { useQuery } from '@apollo/react-hooks';
import homeCategoriesQuery from '../HomeCategoriesQuery.gql';
import { useCart } from '@jetshop/core/components/Query/CartProvider';
import cartQuery from '../../../ui/vita/Cart/queries/cartQuery.gql';

export const DesktopHeader = ({ routes, favouritesCount, style }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchvalue, setsearchvalue] = useState('');
  const [activeCategory, setActiveCategory] = useState();
  const translate = useIntl();
  const [scrollDir, setScrollDir] = useState('scrolling down');
  const [scrollY, setScrollY] = useState(0);
  const t = useIntl();
  const cartData = useCart(cartQuery);

  const homeCategoryResult = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? 'scrolling down' : 'scrolling up');
      setScrollY(scrollY);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDir]);

  const { cart } = cartData;

  const { closeAll } = useModalManagement();

  const searchPlaceholderString = translate(
    'Search for product, category, brand...'
  );

  const handleMenuButtonClick = category => {
    closeAll();
    setsearchvalue('');
    setActiveCategory(category);
    setMenuOpen(activeCategory?.id === category?.id ? !menuOpen : true);
  };
  const handleMenuClose = () => {
    closeAll();
    setsearchvalue('');
    setActiveCategory(false);
    setMenuOpen(false);
  };

  const handleSetMenuOpen = () => {
    setsearchvalue('');
    setMenuOpen(false);
  };

  const searchInputExists = () => {
    return searchvalue?.length >= 1;
  };

  // TODO: Fix this
  const preventDropdownMenu = ['Life Guide', 'Kampanjer'];

  return (
    <>
      <div className={'header-top-banner'} id="top-campaign-bar">
        {process?.env?.REACT_APP_CAMPAIGNBAR_CATEGORY_ID?.length > 0 && (
          <DynamicCategoryRenderer
            categoryId={process?.env?.REACT_APP_CAMPAIGNBAR_CATEGORY_ID}
            rendererComponents={campaignbarComponents}
          />
        )}
      </div>

      <div
        className={cx(
          'desktop-header',
          style,
          scrollDir === 'scrolling up' && 'sticky',
          scrollY > 150 && 'scrolled'
        )}
        id="project-header"
      >
        <div
          className={'logo-search-icons'}
          onMouseEnter={() => {
            // ONLY RUN FUNCTION IF SEARCH INPUT VALUE IS LESS THAN 3
            !searchInputExists() && handleMenuClose();
          }}
        >
          <div className="left">
            <Link to={'/'}>
              <SVG name={'Life'} />
            </Link>
            <StyledSearchBar
              placeholder={searchPlaceholderString}
              searchvalue={searchvalue}
              setsearchvalue={setsearchvalue}
              onFocus={() => {
                setMenuOpen(false);
                closeAll();
              }}
            />
          </div>
          <div className="right">
            <StyledTitledIconAction
              iconName={'Pin_outline'}
              title={t('Find store')}
              link={t(routes?.stores?.path)}
            />
            <div
              className="favourites-icon-wrapper"
              style={{ position: 'relative' }}
            >
              <StyledTitledIconAction
                iconName={'Heart'}
                title={t('Favourites')}
                link={t('/favourites')}
              />
              <Badge text={favouritesCount} />
            </div>
            <StyledTitledIconAction
              iconName={'Person'}
              title={t('My pages')}
              link={routes?.myPages?.path}
            />

            <FlyoutTrigger triggerId={'header-cart-desktop'}>
              {drawer => (
                <div style={{ position: 'relative' }}>
                  <StyledTitledIconAction
                    iconName={'Cart'}
                    title={t('Cart')}
                    action={() => {
                      setsearchvalue('');
                      setMenuOpen(false);
                      drawer.isOpen ? drawer.hideTarget() : drawer.showTarget();
                    }}
                  />
                  <Badge text={cart?.totalQuantity} />
                </div>
              )}
            </FlyoutTrigger>
          </div>
        </div>

        <div className="categories-and-links">
          <div className="left">
            {homeCategoryResult?.data?.categories?.map((category, index) => {
              if (
                !category?.hasSubcategories ||
                preventDropdownMenu.includes(category?.name)
              ) {
                return (
                  <CategoryLink
                    key={index + '-' + category?.id}
                    category={category}
                    onMouseEnter={() => {
                      // ONLY RUN FUNCTION IF SEARCH INPUT VALUE IS LESS THAN 3
                      !searchInputExists() && handleMenuClose();
                    }}
                    onClick={() => setMenuOpen(false)}
                    className={cx(
                      'header-category-button',
                      style && desktopHeaderButtonStyles
                    )}
                  >
                    <div
                      className={cx(
                        style && barHoverStyle,
                        activeCategory?.id === category?.id && 'active'
                      )}
                    >
                      {category?.name}
                    </div>
                  </CategoryLink>
                );
              }
              return (
                <CategoryLink
                  key={index + '-' + category?.id}
                  category={category}
                  onMouseEnter={e => {
                    // ONLY RUN FUNCTION IF SEARCH INPUT VALUE IS LESS THAN 3
                    !searchInputExists() && handleMenuButtonClick(category);
                  }}
                  onClick={() => {
                    // SETS CATEGORY IF INPUT VALUE IS LESS THAN 3
                    if (searchInputExists()) {
                      handleMenuButtonClick(category);
                    } else {
                      setMenuOpen(false);
                      handleMenuClose();
                    }
                  }}
                  className={cx(
                    'header-category-button',
                    style && desktopHeaderButtonStyles
                  )}
                >
                  <div
                    className={cx(
                      style && barHoverStyle,
                      activeCategory?.id === category?.id && 'active'
                    )}
                  >
                    {category?.name}
                  </div>
                </CategoryLink>
              );
            })}
          </div>
        </div>
        <StyledHeaderDropdown open={menuOpen} setOpen={setMenuOpen}>
          <div
            onMouseLeave={() => handleMenuClose()}
            className={cx('category-menu', categoryMenuDesktopStyle)}
          >
            <GreenBar>
              <Link
                to={activeCategory?.primaryRoute?.path ?? '/'}
                onClick={() => {
                  handleMenuButtonClick(activeCategory);
                  handleMenuClose();
                }}
                className="category-title"
              >{`${activeCategory?.name}`}</Link>
            </GreenBar>
            <div className="category-menu-result">
              {homeCategoryResult?.data?.categories?.map((cat, index) => (
                <div
                  key={index}
                  style={{
                    display: cat?.id === activeCategory?.id ? 'block' : 'none'
                  }}
                >
                  {cat?.subcategories?.map((lvl2, index) => (
                    <StyledHeaderDropdownCategory
                      key={index}
                      category={lvl2}
                      setOpen={handleSetMenuOpen}
                      close={handleMenuClose}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </StyledHeaderDropdown>
      </div>
    </>
  );
};

export const StyledDesktopHeader = ({
  categories,
  routes,
  cartData,
  favouritesCount
}) => {
  return (
    <DesktopHeader
      categories={categories}
      routes={routes}
      cartData={cartData}
      favouritesCount={favouritesCount}
      style={desktopHeaderStyles}
    />
  );
};
