import React from 'react';
import { styled } from 'linaria/react';
import { COMPONENTS, theme } from './Imports';
import LargeCampaign from './LargeCampaign';
import SmallCampaign from './SmallCampaign';
import { toRem } from '../../../helpers';
import useChildren from '../../../hooks/useChildren';

const HeroThreeWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    'large large large large large large'
    'small0 small0 small0 small0 small0 small0'
    'small1 small1 small1 small1 small1 small1';
  gap: ${toRem(16)};

  ${theme.above.sm} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      'large large large large large large'
      'small0 small0 small0 small1 small1 small1';
  }

  ${theme.above.lg} {
    height: ${toRem(510)};
    grid-template-areas:
      'large large large small0 small0 small0'
      'large large large small1 small1 small1';
  }

  ${theme.above.xl} {
    grid-template-areas:
      'large large large large small0 small0'
      'large large large large small1 small1';
  }

  & > div:nth-child(2),
  & > div:nth-child(3) {
    .bubble-text {
      display: none;
    }
  }
`;

const Hero = props => {
  const allProps = useChildren(props);

  const largeCampaignArray = allProps[COMPONENTS.LARGE_CAMPAIGN];
  const smallCampaignArray = allProps[COMPONENTS.SMALL_CAMPAIGN];
  const heroOneArray = allProps[COMPONENTS.LARGE_CAMPAIGN];

  if (heroOneArray && heroOneArray.length > 0) {
    const largeCampaignProps = largeCampaignArray.pop();
    return (
      <HeroThreeWrapper className="odd-even-background">
        <LargeCampaign {...largeCampaignProps} fillAvailableSpace />

        {smallCampaignArray.map((smallCampaign, index) => (
          <SmallCampaign
            {...smallCampaign}
            index={index}
            fillAvailableSpace
            cover
          />
        ))}
      </HeroThreeWrapper>
    );
  }
};

export default Hero;
