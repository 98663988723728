import React from 'react';
import { HtmlRow } from 'r3-lib/dist/r3lib';

export const CustomHTMLRow = props => {
  const hasMaxWidth = props?.maxWidth?.value;
  return (
    <HtmlRow style={hasMaxWidth && 'limited-max-width'} {...props}>
      {props?.children}
    </HtmlRow>
  );
};
