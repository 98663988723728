import CeraPro_Regular_woff from './CeraPro-TypeMates/Webfonts/CeraPro-Regular.woff';
import CeraPro_Regular_woff2 from './CeraPro-TypeMates/Webfonts/CeraPro-Regular.woff2';
import CeraPro_RegularItalic_woff2 from './CeraPro-TypeMates/Webfonts/CeraPro-RegularItalic.woff2';
import CeraPro_RegularItalic_woff from './CeraPro-TypeMates/Webfonts/CeraPro-RegularItalic.woff';
import CeraPro_Bold_woff2 from './CeraPro-TypeMates/Webfonts/CeraPro-Bold.woff2';
import CeraPro_Bold_woff from './CeraPro-TypeMates/Webfonts/CeraPro-Bold.woff2';
import CeraPro_BoldItalic_woff2 from './CeraPro-TypeMates/Webfonts/CeraPro-BoldItalic.woff2';
import CeraPro_BoldItalic_woff from './CeraPro-TypeMates/Webfonts/CeraPro-BoldItalic.woff';
import CeraPro_Medium_woff2 from './CeraPro-TypeMates/Webfonts/CeraPro-Medium.woff2';
import CeraPro_Medium_woff from './CeraPro-TypeMates/Webfonts/CeraPro-Medium.woff';

const CeraPro = `
  /* Cera Pro - Normal */
  @font-face {
    font-family: 'Cera Pro';
    src: url(${CeraPro_Regular_woff2}) format('woff2'),
      url(${CeraPro_Regular_woff}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  /* Cera Pro - Normal Italic */
  @font-face {
    font-family: 'Cera Pro';
    src: url(${CeraPro_RegularItalic_woff2}) format('woff2'),
      url(${CeraPro_RegularItalic_woff}) format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  /* Cera Pro - Medium */
  @font-face {
    font-family: 'Cera Pro';
    src: url(${CeraPro_Medium_woff2}) format('woff'),
    url(${CeraPro_Medium_woff}) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  /* Cera Pro - Bold */
  @font-face {
    font-family: 'Cera Pro';
    src: url(${CeraPro_Bold_woff2}) format('woff'),
    url(${CeraPro_Bold_woff}) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  /* Cera Pro - Bold Italic */
  @font-face {
    font-family: 'Cera Pro';
    src: url(${CeraPro_BoldItalic_woff2}) format('woff2'),
      url(${CeraPro_BoldItalic_woff}) format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  `;

export default CeraPro;
