import React from 'react';
import { styled } from 'linaria/react';
import { Below } from '@jetshop/ui/Breakpoints';
import * as Button from './Button';
import { COMPONENTS } from './ContentRenderer';
import SmallCampaign from './SmallCampaign';
import { theme } from '../../Theme';
import { toRem } from '../../../helpers';
import useChildren from '../../../hooks/useChildren';

const CampaignSmallWrapper = styled('div')``;

const SmallCampaignWrapper = styled('section')`
  display: grid;
  gap: ${toRem(16)};

  ${theme.above.sm} {
    grid-template-columns: repeat(auto-fit, minmax(${toRem(320)}, 1fr));
  }

  ${theme.above.lg} {
    grid-template-columns: repeat(auto-fit, minmax(${toRem(420)}, 1fr));
  }

  .bubble-text {
    display: none;
  }
`;

const TopTitleMobile = styled('div')`
  text-align: center;
  margin-bottom: ${toRem(16)};
  color: ${theme.colors.forest};
  ${theme.fonts.desktop.h3};
`;

const BottomButtonMobile = styled('div')`
  margin: ${toRem(16)} auto 0;
  max-width: ${toRem(320)};
  text-align: center;
`;

const TopDesktopWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  .secondary {
    margin-bottom: ${toRem(32)};
  }
`;

const TopDesktopTitle = styled('div')`
  color: ${theme.colors.forest};
  ${theme.fonts.desktop.h3};
  margin-bottom: ${toRem(32)};
`;

const TitleButtonTop = props => {
  const { title, label, externalLinkUrl, internalLinkUrl } = props;

  let url = '/';
  if (externalLinkUrl !== null) url = externalLinkUrl;
  if (internalLinkUrl !== null) url = internalLinkUrl;

  return (
    <Below breakpoint="md">
      {matches =>
        matches ? (
          title && <TopTitleMobile>{title}</TopTitleMobile>
        ) : (
          <TopDesktopWrapper>
            {title && <TopDesktopTitle>{title}</TopDesktopTitle>}
            {label && (
              <Button.ButtonAuto linkText={url} className="secondary">
                {label}
              </Button.ButtonAuto>
            )}
          </TopDesktopWrapper>
        )
      }
    </Below>
  );
};

const TitleButtonBottom = props => {
  const { label, externalLinkUrl, internalLinkUrl } = props;

  let url = '/';
  if (externalLinkUrl !== null) url = externalLinkUrl;
  if (internalLinkUrl !== null) url = internalLinkUrl;

  return (
    label && (
      <Below breakpoint="md">
        {matches =>
          matches &&
          label && (
            <BottomButtonMobile>
              <Button.ButtonAuto linkText={url} className="secondary">
                {label}
              </Button.ButtonAuto>
            </BottomButtonMobile>
          )
        }
      </Below>
    )
  );
};

const CampaignSmall = props => {
  const allProps = useChildren(props);

  const titleButtonArray = allProps[COMPONENTS.TITLE_BUTTON];
  const smallCampaignArray = allProps[COMPONENTS.SMALL_CAMPAIGN];

  if (
    titleButtonArray &&
    titleButtonArray.length &&
    titleButtonArray.length > 0
  ) {
    const titleButtonProps = titleButtonArray.pop();
    const { title, label, externalLinkUrl, internalLinkUrl } = titleButtonProps;

    return (
      <CampaignSmallWrapper>
        <TitleButtonTop
          title={title}
          label={label}
          externalLinkUrl={externalLinkUrl}
          internalLinkUrl={internalLinkUrl}
        />
        <SmallCampaignWrapper>
          {smallCampaignArray?.map(smallCampaign => (
            <SmallCampaign {...smallCampaign} aspect="16:10" cover />
          ))}
        </SmallCampaignWrapper>
        <TitleButtonBottom {...titleButtonProps} />
      </CampaignSmallWrapper>
    );
  }

  return (
    <CampaignSmallWrapper>
      <SmallCampaignWrapper>
        {smallCampaignArray?.map(smallCampaign => (
          <SmallCampaign {...smallCampaign} aspect="16:10" cover />
        ))}
      </SmallCampaignWrapper>
    </CampaignSmallWrapper>
  );
};

export default CampaignSmall;
