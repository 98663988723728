import { css } from 'linaria';

export const hideScrollbar = css`
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  /*track*/
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  /*thumb*/
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;
