import React from 'react';
import { BoxRowColorItem } from 'r3-lib/dist/r3lib';
import { PromotionBadge } from '../ui/vita/PromotionBadge/PromotionBadge';

export const CustomBoxRowColorItem = props => {
  const contentPadding = props?.contentPadding?.value;
  return (
    <BoxRowColorItem style={contentPadding} {...props}>
      {props?.promoBadgeText?.value?.length > 0 && (
        <PromotionBadge
          backgroundColor={props?.promoBadgeBackgroundColor}
          foregroundColor={props?.promoBadgeForegroundColor}
          text={props?.promoBadgeText}
        />
      )}
    </BoxRowColorItem>
  );
};
