import React from 'react';
import { styled } from 'linaria/react';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { theme } from '../Theme';
import { useLocation } from 'react-router';

const BannerContainer = styled.div`
  display: flex;
  white-space: nowrap;
  flex-direction: row;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-padding: 16px;
  width: 100%;
  grid-gap: ${props => props.dtg || '0'};
  padding: ${props => props.dttb || '0'} ${props => props.dtlr || '0'};
  .banner-item {
    align-items: center;
    border-radius: ${props => props.dtbr || '0'};
    ${theme.below.lg} {
      border-radius: ${props => props.mbr || '0'};
    }
  }

  &.left {
    .banner-item {
      justify-content: flex-start;
    }
  }
  &.center {
    .banner-item {
      justify-content: center;
    }
  }
  &.right {
    .banner-item {
      justify-content: flex-end;
    }
  }

  /* // Show scrollbar on desktop.
  &::-webkit-scrollbar {
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.border};
    border-radius: 0.25rem;
  } */

  // Hide scrollbar
  /* padding: 0.5rem 0.5rem; */
  
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

 ${theme.below.lg} {
  grid-gap: ${props => props.mg || '0'};
  padding: ${props => props.mtb || '0'} ${props => props.mlr || '0'};
 } 
 
`;

export const SiteWideBanner = ({
  children,
  alignment,
  showOn,
  desktopTopBottom,
  mobileTopBottom,
  desktopLeftRight,
  mobileLeftRight,
  desktopGap,
  mobileGap,
  desktopBorderRadius,
  mobileBorderRadius
}) => {
  const location = useLocation();
  const dictionary = {
    key1: '/login',
    key2: '/my-pages',
    key3: '/butiker',
    key4: '/butikker',
    key5: '/myymalat'
    // Add more key-value pairs as needed
  };

  const pathname = location.pathname;
  const hideBanner = Object.values(dictionary).some(path =>
    pathname.includes(path)
  );

  if (hideBanner) {
    return null;
  }

  if (showOn?.value === 'Mobile') {
    return (
      <Below breakpoint="lg">
        {matches =>
          matches && (
            <BannerContainer
              className={alignment?.value}
              dttb={desktopTopBottom?.value}
              dtlr={desktopLeftRight?.value}
              dtg={desktopGap?.value}
              mtb={mobileTopBottom?.value}
              mlr={mobileLeftRight?.value}
              mg={mobileGap?.value}
              dtbr={desktopBorderRadius?.value}
              mbr={mobileBorderRadius?.value}
            >
              {children}
            </BannerContainer>
          )
        }
      </Below>
    );
  }

  if (showOn?.value === 'Desktop') {
    return (
      <Above breakpoint="lg">
        {matches =>
          matches && (
            <BannerContainer
              className={alignment?.value}
              dttb={desktopTopBottom?.value}
              dtlr={desktopLeftRight?.value}
              dtg={desktopGap?.value}
              mtb={mobileTopBottom?.value}
              mlr={mobileLeftRight?.value}
              mg={mobileGap?.value}
              dtbr={desktopBorderRadius?.value}
              mbr={mobileBorderRadius?.value}
            >
              {children}
            </BannerContainer>
          )
        }
      </Above>
    );
  }

  return (
    showOn?.value === 'Both' && (
      <BannerContainer
        className={alignment?.value}
        dttb={desktopTopBottom?.value}
        dtlr={desktopLeftRight?.value}
        dtg={desktopGap?.value}
        mtb={mobileTopBottom?.value}
        mlr={mobileLeftRight?.value}
        mg={mobileGap?.value}
        dtbr={desktopBorderRadius?.value}
        mbr={mobileBorderRadius?.value}
      >
        {children}
      </BannerContainer>
    )
  );
};
