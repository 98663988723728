import React from 'react';
import { styled } from 'linaria/react';
import { useChildren, COMPONENTS, LargeCampaign } from './Imports';
import { theme } from './Imports';
import { toRem } from '../../../helpers';

const HeroOneWrapper = styled('div')`
  ${theme.above.md} {
    height: ${toRem(510)};
    margin: 1rem 0;
  }
  ${theme.below.md} {
    height: ${toRem(341)};
    padding-left: 0;
    padding-right: 0;
  }
`;

const HeroOne = props => {
  const allProps = useChildren(props);

  const heroOneArray = allProps[COMPONENTS.LARGE_CAMPAIGN];
  if (heroOneArray && heroOneArray.length > 0) {
    const largeCampaignProps = heroOneArray.pop();
    return (
      <HeroOneWrapper className="odd-even-background">
        <LargeCampaign
          {...largeCampaignProps}
          aspect="128:51"
          fillAvailableSpace={true}
        />
      </HeroOneWrapper>
    );
  }
  return null;
};

export default HeroOne;
