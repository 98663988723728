import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';

import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import useProductToast from './useProductToast';

import cartQuery from '../../../Cart/queries/cartQuery.gql';
import { Loader } from 'r3-lib/dist/r3lib';

const Container = styled('aside')`
  padding: 20px;
`;

const CartButton = styled('a')`
  &.cta {
    width: 100%;
    height: 45px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    line-height: 45px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      height: 12px;
      width: 60px;
    }
    .loader-dot {
      height: 6px !important;
      width: 6px !important;
      padding: 0 !important;
    }
  }
`;

const ProductImageWrapper = styled('div')`
  width: 75px;
  position: relative;
  &:after {
    content: '';
    background: rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const ProductCheckoutContainer = styled('div')``;

const Product = styled('div')`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const ProductDetails = styled('div')`
  padding: 0 20px;
  .product-price {
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
  }
  .product-name {
    font-size: 16px;
    margin: 0;
  }
`;

const Header = styled('h3')``;

const Error = styled('p')``;

const ProductToast = ({
  product,
  cart,
  selectedVariation,
  quantity,
  error
}) => {
  const [checkoutBtnClicked, setCheckoutBtnClicked] = useState(false);
  const { price, previousPrice, image } = useProductToast({
    product,
    selectedVariation,
    quantity
  });
  const checkoutUrl = cart?.externalCheckoutUrl;
  const track = useTracker();
  return (
    <Container data-testid="product-toast">
      <ProductCheckoutContainer>
        {error ? (
          <Header>{t('Failed adding to bag')}</Header>
        ) : quantity === 1 ? (
          <Header>{t('Added to bag')}</Header>
        ) : (
          <Header>
            {t.rich('Added {quantity} items to bag', { quantity })}
          </Header>
        )}
        <Product>
          {product.images.length > 0 && (
            <ProductImageWrapper>
              <Image
                src={image.url}
                sizes={80}
                aspect={'1:1'}
                alt={image.alt}
                quality={80}
              />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            <h3 className="product-name">{product.name}</h3>
            {!error && (
              <div className="product-price">
                <Price price={price} previousPrice={previousPrice} />
              </div>
            )}
            {error && (
              <Error>
                {t('An error occurred. Details:')}
                <ul>
                  {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                    error.graphQLErrors.map(({ message, locations, path }) => (
                      <li key={message}>{t(message)}</li>
                    ))
                  ) : (
                    <li>{t(error.message)}</li>
                  )}
                </ul>
              </Error>
            )}
          </ProductDetails>
        </Product>
        {cart && cart.externalCheckoutUrl && (
          <CartButton
            data-testid="checkout-button"
            className={cx('CTA cta forest')}
            href={checkoutUrl}
            onClick={() => {
              setCheckoutBtnClicked(true);
              track(
                trackCartCheckoutEvent({
                  cart: cart,
                  callback: () => {
                    window.location = checkoutUrl;
                  }
                })
              );
            }}
          >
            {checkoutBtnClicked ? (
              <Loader color="white" />
            ) : (
              t('Go to checkout')
            )}
          </CartButton>
        )}
      </ProductCheckoutContainer>
    </Container>
  );
};
const ProductToastWrapper = props => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;
