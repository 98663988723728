import { css } from 'linaria';
import { theme } from '../../../Theme';

css`
  :global() {
    .categories-row-item {
      .categories-row-item-image-wrapper {
        width: 100px;
        aspect-ratio: 1;
        border-radius: 50%;
        overflow: hidden;
        margin: auto;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      h4 {
        text-align: center;
        margin-top: 16px;
        ${theme.fonts.desktop.p2}
        font-weight: ${theme.fontWeights.medium};
      }
    }
  }
`;
