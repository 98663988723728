import { css } from 'linaria';
import { theme } from '../../../Theme';

export const newCategoryHeaderStyle = css`
  display: flex;
  width: 100%;
  height: 360px;
  background: #f7f7f7;
  margin-bottom: 10px;

  .header-article-page {
    padding-right: 0;
    padding-left: 0;
  }

  .image-wrapper {
    width: 100%;
  }

  .text-wrapper {
    margin: 10px;
    width: 100%;
    max-width: 50%;
    padding: 80px 60px;
  }
  .title {
    width: 100%;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #333333;
    margin-bottom: 10px;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;

    ${theme.below.lg} {
      &.dynamic-height {
        transition: max-height 0.3s ease-in-out;
        max-height: 42px;
        overflow: hidden;
        position: relative;

        :after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0;
          right: 0;
          height: 20px;
          background: linear-gradient(rgba(255, 255, 255, 0), #f7f7f7);
        }

        &.expanded {
          height: 100%;
          max-height: 400px;

          :after {
            height: 0;
          }
        }
      }
    }
  }

  .read-more {
    display: none;
  }

  ${theme.below.lg} {
    flex-direction: column;
    height: auto;

    .text-wrapper {
      padding: 20px;
      margin: 0;
      max-width: 100%;
    }
    .image-wrapper {
      height: 380px;
    }

    .read-more {
      display: block;
      width: fit-content;
      margin: 0 auto;
      padding: 5px;
      font-size: 14px;
      font-weight: 600;
      color: ${theme.colors.seaweed};
    }
  }
`;
