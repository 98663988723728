import React from 'react';
import { cx } from 'linaria';
import MaxWidth from '../../MaxWidth';
import { SVG } from '../../../../assets/SVG';
import { paymentMethodsRowStyle } from './PaymentMethodsRow.style';

export const PaymentMethodsRow = ({
  backgroundColor,
  foregroundColor,
  alignment,
  ...props
}) => {
  const logos = [];

  for (const prop in props) {
    if (props?.[prop]?.type === 'bool' && props?.[prop]?.value) {
      logos?.push(prop?.charAt?.(0)?.toUpperCase?.() + prop?.slice(1));
    }
  }

  const alignmentToFlexConverter = alignment => {
    switch (alignment) {
      case 'center':
        return 'center';
      case 'left':
        return 'start';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  };

  return (
    <div
      className={cx('payment-method-row', paymentMethodsRowStyle)}
      style={{
        background: backgroundColor?.value,
        color: foregroundColor?.value
      }}
    >
      <MaxWidth
        className="max-width"
        style={{ justifyContent: alignmentToFlexConverter(alignment?.value) }}
      >
        {logos?.map((logo, index) => {
          return <SVG key={`payment-logo-${index}`} name={logo} />;
        })}
      </MaxWidth>
    </div>
  );
};
