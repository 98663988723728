import { css } from 'linaria';
import { theme } from '../../../Theme';

css`
  :global() {
    .html-row.limited-max-width {
      max-width: 730px;
      margin: 0 auto;
      ${theme.below.md} {
        max-width: 80%;
      }
    }
    .html-row-item-outer {
      max-width: ${theme.layout.maxWidth};
      margin: 0 auto;
      width: 100%;

      h2 {
        font-size: 28px;
        font-weight: ${theme.fontWeights.medium};
      }

      &.fullWidth {
        max-width: 100%;
      }

      a {
        color: inherit;
      }
    }
  }
`;
