import { css } from 'linaria';
// import theme from '../../components/Theme';
import CeraPro from './CeraPro';
import IvarHeadline from './IvarHeadline';
import IvarText from './IvarText';

export default function loadCss() {
  return css`
    :global(html) {
      ${CeraPro};
      ${IvarHeadline};
      ${IvarText};

      &.cera-pro-ready body {
        font-family: 'Cera Pro', sans-serif;
        letter-spacing: 0;
        word-spacing: 0;
        font-weight: normal;
        font-size: 1rem;
        color: black;
      }
    }
  `;
}
