import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { useAddToCart } from '../../../hooks/useAddToCart';
import { QuickBuyContext } from './QuickBuyContext';
import { useTrackProductAdd } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { SVG } from '../../../assets/SVG';
import { theme } from '../../Theme';
import { useElevate } from '../../Elevate/Context';

const QuickBuyButtonWrapper = styled('button')`
  background: #004e42;
  height: 34px;
  aspect-ratio: 1 / 1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  svg {
    color: ${theme.colors.white};
    height: 15px;
    width: 15px;
    margin-right: 0;
    margin-bottom: 3px;
  }
`;

/**
 * The QuickBuyButton function renders a button that when clicked, either adds product to cart or displays the QuickBuyFlyout component.
 *
 * @param {boolean} icon Boolean to set if the button should be an icon or not
 * @param {object} product Pass the product details
 * @param {number} qty Set the default quantity of the product
 *
 * @return A button that can add the product to the cart
 *
 * @docauthor Anders Zetterström @ R3
 */
export const QuickBuyButton = ({ product, qty = 1 }) => {
  const { api } = useElevate();
  const trackAddProductVariant = useTrackProductAdd(product);
  const trackAddToCart = () => {
    trackAddProductVariant(product, qty);
  };
  const { addProduct } = useAddToCart(trackAddToCart);
  const { setQuickBuyProduct, setQuickBuyQty } = useContext(QuickBuyContext);

  const inStock = product?.inStock || product?.stockStatus?.buyable;

  if (!inStock) {
    return null;
  }

  return (
    <div className="quick-buy-button">
      {product?.variants?.values?.length > 0 ? (
        <DrawerTrigger preventOverflow={true} id="quick-buy-drawer">
          {drawer => (
            <QuickBuyButtonWrapper
              className={'icon'}
              onClick={e => {
                // Disables link click
                e.preventDefault();
                setQuickBuyProduct(product);
                setQuickBuyQty(qty);
                drawer.showTarget();
                if (product?.ticket) {
                  api.notify.addToCart(product?.ticket);
                }
              }}
            >
              <SVG name={'Shopping_bag'} />
            </QuickBuyButtonWrapper>
          )}
        </DrawerTrigger>
      ) : (
        <QuickBuyButtonWrapper
          className={'icon'}
          onClick={e => {
            // Disables link click
            e.preventDefault();
            addProduct(product, qty);
          }}
        >
          <SVG name={'Shopping_bag'} />
        </QuickBuyButtonWrapper>
      )}
    </div>
  );
};
