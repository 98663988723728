import { css } from 'linaria';
import { theme } from '../../../../Theme';

export const cartProductCardStyle = css`
  padding: 20px;

  display: flex;
  gap: 20px;
  text-decoration: none;

  &:not(:first-child) {
    border-top: 1px solid ${theme.colors.border};
  }

  [data-flight-image-container] {
    width: 75px !important;
    height: 75px !important;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .product-information {
    justify-content: space-between;
    flex: 1;

    .link-evaluator {
      color: ${theme.colors.black};
      text-decoration: none;
      margin-bottom: 8px;

      > * {
        margin-bottom: 4px;
      }
    }

    h4 {
      ${theme.fonts.desktop.p1}
      line-height: 120%;
      font-weight: ${theme.fontWeights.medium};
    }
    p {
      line-height: 100%;
      font-size: ${theme.fontSizes.desktop.p2};
    }
  }

  .cart-product-price {
    white-space: nowrap;

    [data-flight-price] {
      display: flex;
      gap: 10px;

      .new-price,
      .price,
      .old-price {
        font-weight: ${theme.fontWeights.medium};
        font-size: ${theme.fontSizes.desktop.p2};
      }

      .old-price {
        color: ${theme.colors.black + '55'};
      }
    }
  }

  .remove {
    justify-content: flex-start;
    button {
      background: transparent;
      .svg-wrapper {
        margin: 4px;
        background: ${theme.colors.guava};
        height: 16px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: ${theme.colors.white};
          width: 7px;
          height: 7px;
        }
      }
    }
  }

  .out-of-stock {
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colors.red};

    a {
      color: ${theme.colors.red};
    }
  }

  .member-price-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;

    .title {
      color: ${theme.colors.forest};
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 3px;
    }

    .offer-price {
      // font-size: 16px;
      // line-height: 1;
      // font-weight: bold;
      color: ${theme.colors.forest};
    }

    .standard-price {
      font-weight: 500;
      font-size: 13px;
      color: ${theme.colors.black};
      margin-bottom: 5px;
      display: flex;
      gap: 5px;
    }
  }
`;
