import React from 'react';
import { styled } from 'linaria/react';
import ArticleCard from './ArticleCard/index';
import { toRem } from '../../../helpers';
import useChildren from '../../../hooks/useChildren';

const ArticleListingWrapper = styled('div')`
  flex-direction: row;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  :-webkit-scrollbar {
    display: none;
  }
`;

const ArticleWrapper = styled.div`
  display: inline-block;
  min-width: ${toRem(295)};
  width: ${toRem(295)};
  margin-right: ${toRem(32)};
  &:last-child {
    margin-right: 0;
  }
`;

const ArticleListing = props => {
  const articles = useChildren(props)['CATEGORY'];
  const childrenWithData = articles.filter(
    article => article?.article !== null
  );

  if (!childrenWithData || childrenWithData.length === 0) return null;

  return (
    <ArticleListingWrapper className="odd-even-background">
      {childrenWithData.map(article => {
        const articleCards = article.article?.data?.items?.filter(
          item => item.type === 'Header article page'
        );

        if (!articleCards) return null;

        const id = article.article?.data?.id;

        return (
          <ArticleWrapper key={id}>
            {articleCards.map(articleCard => {
              return (
                <ArticleCard
                  key={id}
                  path={article.article.primaryRoute.path}
                  {...articleCard}
                />
              );
            })}
          </ArticleWrapper>
        );
      })}
    </ArticleListingWrapper>
  );
};

export default ArticleListing;
