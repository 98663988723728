import { styled } from 'linaria/react';
import theme from './Theme';
import { toRem } from '../../../helpers';

export const ImageWrapper = styled.div`
  height: ${toRem(48)};
  width: ${toRem(48)};
  overflow: hidden;
  border-radius: 50%;
`;

export const ImageTitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleAndProductsWrapper = styled.div`
  padding-left: ${toRem(8)};
`;

export const CategoryTitle = styled.h5`
  margin: 0;
  ${theme.fonts.h5};
  color: ${theme.colors.forest};
`;

export const NumProducts = styled.p`
  font-size: ${toRem(12)};
  color: ${theme.colors.forest};
`;
