import { styled } from 'linaria/react';
import theme from './Theme';
import { toRem } from '../../../helpers';

const CategoryLinkWrapper = styled.a`
  width: 100%;
  height: ${toRem(72)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${theme.colors.white};
  padding: ${toRem(12)};
  text-decoration: none;
`;

export default CategoryLinkWrapper;
