import React, { useRef } from 'react';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import t from '@jetshop/intl';
import { cx, css } from 'linaria';
import { productCardStyle } from './ProductCard.style';
import Badges from '@jetshop/ui/ProductList/Badges';
import ProductLink from '@jetshop/ui/ProductLink';
import { theme } from '../../Theme';
import { Favourite } from '../../ProductList/Favourite';
import { ProductCardHeader } from './ProductCardHeader/ProductCardHeader';
import { productCardHeaderStyle } from './ProductCardHeader/ProductCardHeader.style';
import { ProductCardPrice } from './ProductCardPrice/ProductCardPrice';
import { productCardPriceStyle } from './ProductCardPrice/ProductCardPrice.style';
import { LipscoreProductRating } from '../../ui/vita/LipscoreProductRating';
import { QuickBuyButton } from '../../Layout/QuickBuy/QuickBuyButton';
import { useElevate } from '../../Elevate/Context';
import { MemberPriceList } from '../../ui/vita/MemberPrice/MemberPrice';

export const badgeStyle = css`
  [data-flight-image-badges] > div > div,
  .badge-wrapper > div > div {
    padding: 0.7rem 0.3rem;

    > div {
      margin-top: 0.2rem;
      width: fit-content;
    }
  }

  [data-flight-image-badges] > div > div > div,
  .badge-wrapper > div > div > div {
    background: ${theme.colors.forest};
    padding: 2px 16px;
    border-radius: 60px 60px 0 60px;

    span {
      font-size: 14px;
      line-height: 122%;
      color: white;
      position: static;
    }

    &.forest {
      background: ${theme.colors.forest};
    }
    &.moss {
      background: ${theme.colors.moss};
    }
    &.seaweed {
      background: ${theme.colors.seaweed};
    }
    &.grass {
      background: ${theme.colors.grass};
    }
    &.herb {
      background: ${theme.colors.herb};
      span {
        color: ${theme.colors.moss};
      }
    }
    &.aloe {
      background: ${theme.colors.aloe};
      span {
        color: ${theme.colors.moss};
      }
    }
    &.guava {
      background: ${theme.colors.guava};
    }
    &.rosehip {
      background: ${theme.colors.rosehip};
    }
    &.plum {
      background: ${theme.colors.plum};
    }
    &.beetroot {
      background: ${theme.colors.beetroot};
    }
    &.black {
      background: ${theme.colors.black};
    }
    &.white {
      background: ${theme.colors.white};
      span {
        color: ${theme.colors.black};
      }
    }

    /* Not allowed to show standard images */
    img {
      display: none;
    }

    span {
      font-size: 14px;
      line-height: 22.5px;
      color: white;
      position: static;
    }
  }
`;

const roundToNearestFive = number => {
  const FIVE = 5;
  if (number % FIVE < 1) {
    return Math.floor(number);
  } else if (number % FIVE > 4) {
    return Math.ceil(number);
  } else {
    return Math.round(number);
  }
};

export const saleBadgeGenerator = (
  product,
  position = 'TOP_LEFT',
  style = 'rosehip'
) => {
  const price = product?.price?.incVat;
  const previousPrice = product?.previousPrice?.incVat;
  if (previousPrice > price) {
    const noRoundedPercentage = (1 - price / previousPrice) * 100;
    const roundedSalesPercentage = roundToNearestFive(noRoundedPercentage);
    return {
      location: position,
      name: 'Sale',
      text: `-${roundedSalesPercentage}%`,
      style: style
    };
  }

  return null;
};

export const ProductCard = ({
  product,
  imageAspect = '1:1',
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  loadImageEagerly,
  favourite,
  categoryPath,
  clickCallback,
  rating,
  style,
  children,
  ticket,
  ...props
}) => {
  const { api } = useElevate();
  const quickBuyBtn = useRef(null);
  const hasImages = product?.images?.length > 0;

  // REMOVES SALES BADGE FROM BADGE-LIST
  const removesSalesBadge = product?.badges?.filter(
    badge => !badge?.name.startsWith('-')
  );

  let badges = [...removesSalesBadge];

  product?.isPackage &&
    badges?.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  // CREATES A NEW SALES BADGE (WITH SPECIAL ROUNDING)
  const sale = saleBadgeGenerator(product, 'TOP_LEFT', 'rosehip');
  // ADDS SALE BADGE TO BADGE-LIST IF THERE IS A SALE
  if (sale) {
    badges?.push(sale);
  }

  // MEMBERPRICE
  const standard = product?.previousPrice;
  const standardPrice = product?.previousPrice?.incVat;
  const campaign = product?.custom?.AllCategoryBucket?.find(
    category =>
      category?.label?.includes('Life Club') && category?.label?.includes('%')
  );
  const percentage =
    parseFloat(campaign?.label?.match(/\d+%|([0-9]\d?)\.\d+%/g)) / 100;
  const memberPrice = {
    incVat: campaign && Math.round(standardPrice - standardPrice * percentage)
  };
  // MEMBERPRICE

  const clickNotify = e => {
    if (!product?.ticket) return;
    if (
      quickBuyBtn.current.contains(e.target) ||
      quickBuyBtn.current === e.target
    ) {
      api.notify.addToCart(product?.ticket);
    } else {
      api.notify.click(product?.ticket);
    }
  };
  return (
    <div
      data-testid="product"
      className={cx('product-card', style)}
      onClick={e => {
        clickNotify(e);
        clickCallback?.(e);
      }}
      {...props}
    >
      <ProductLink
        className="product-card-product-link"
        product={product}
        categoryPath={categoryPath}
      >
        <div className={cx('product-card-image-wrapper', badgeStyle)}>
          {hasImages ? (
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={product?.images?.[0]?.alt}
              src={product?.images?.[0]?.url}
              modifiedDate={product?.images?.[0]?.modifiedDate}
              badges={<Badges badges={badges} />}
              critical={loadImageEagerly}
            />
          ) : (
            <img
              alt={t('image is missing')}
              src={transparentDataImg}
              width={'100%'}
              height={'100%'}
            />
          )}
        </div>
        <Favourite product={product} />
        <LipscoreProductRating
          rating={product?.rating}
          identifier={product?.barcode}
        />
        <ProductCardHeader
          product={product}
          style={style && productCardHeaderStyle}
        ></ProductCardHeader>
        {memberPrice?.incVat ? (
          <MemberPriceList memberPrice={memberPrice} standardPrice={standard}>
            <div ref={quickBuyBtn}>
              <QuickBuyButton product={product} />
            </div>
          </MemberPriceList>
        ) : (
          <ProductCardPrice
            product={product}
            style={style && productCardPriceStyle}
          >
            <div ref={quickBuyBtn}>
              <QuickBuyButton product={product} />
            </div>
          </ProductCardPrice>
        )}
      </ProductLink>
    </div>
  );
};

export const StyledProductCard = ({
  product,
  imageAspect,
  imageSizes,
  loadImageEagerly,
  clickCallback,
  children,
  ...props
}) => {
  return (
    <ProductCard
      product={product}
      imageAspect={imageAspect}
      imageSizes={imageSizes}
      loadImageEagerly={loadImageEagerly}
      clickCallback={clickCallback}
      {...props}
      style={productCardStyle}
    />
  );
};
