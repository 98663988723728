import React from 'react';

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const pageOffset = typeof window !== 'undefined' ? window.pageYOffset : 0;
  const immediate = pageOffset === 0;
  const wait = 200;
  const debounce = React.useCallback(
    func => {
      let timeout;
      return function() {
        const context = this;
        const args = arguments;
        const later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    [immediate]
  );

  React.useEffect(() => {
    const updatePosition = debounce(() => {
      setScrollPosition(window.pageYOffset);
    });
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, [debounce, immediate]);

  return scrollPosition;
};
