import { useProductList } from '@jetshop/core/hooks/ProductList';
import React from 'react';
import { css } from 'linaria';
import { ReactComponent as HeartSVG } from '../../svg/Heart.svg';
import { theme } from '../Theme';

const favourite = css`
  position: absolute;
  top: 10px;
  right: 25px;

  button {
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 14px;
      height: 14px;
      color: ${theme.colors.forest};

      &.active {
        fill: currentColor;
      }
    }
  }
`;

export function Favourite({ product, variant, listId }) {
  const articleNumber = product?.articleNumber;
  const selectedVariation = product?.variant || variant;
  const variantArticleNumber = selectedVariation?.articleNumber;

  const { toggle, inList } = useProductList(listId);
  function toggleWithoutBubble(e) {
    e.preventDefault();
    toggle(articleNumber, {
      variantArticleNumber,
      productName: product?.name
    });
  }

  const isInList = inList(articleNumber, {
    variantArticleNumber
  });

  return (
    <div className={favourite}>
      <button onClick={toggleWithoutBubble} aria-label="Add to favourites">
        <HeartSVG className={isInList ? 'active' : 'inactive'} />
      </button>
    </div>
  );
}
