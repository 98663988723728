import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const QuoteWrapper = styled.div`
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
  ${theme.below.lg} {
    max-width: 75%;
  }
  ${theme.below.sm} {
    padding: 0;
  }
`;

const QuoteText = styled.p`
  font-size: 2.2rem;
  font-weight: 500;
  font-style: italic;
  line-height: 1.4;
  text-align: left;
  color: ${props => props.textColor};
  ${theme.below.lg} {
    font-size: 1.8rem;
  }
`;

const QuoteAuthor = styled.p`
  font-size: 1rem;
  font-weight: 500;
  justify-self: flex-start;
  color: #000;
  margin-top: 1rem;
`;
export const Quote = ({ author, quote, textColor }) => {
  return (
    <QuoteWrapper>
      <QuoteText
        textColor={textColor?.value}
        dangerouslySetInnerHTML={{
          __html: quote?.value
        }}
      ></QuoteText>
      <QuoteAuthor
        textColor={textColor?.value}
        dangerouslySetInnerHTML={{
          __html: author?.value
        }}
      ></QuoteAuthor>
    </QuoteWrapper>
  );
};
