import React from 'react';
import { cx } from 'linaria';
import { useQuery } from 'react-apollo';
import { CategoryContentQuery } from '../../ContentEditor/DynamicCategoryRenderer/CategoryContentQuery.gql';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { footerComponents } from '../../ContentEditor/ContentComponents';
// import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { LifeClub } from './LifeClub';

// TODO - useMemo ??
const Footer = () => {
  // const { loggedIn } = useAuth();
  const { data, error } = useQuery(CategoryContentQuery, {
    variables: {
      id: process?.env?.REACT_APP_VITA_FOOTER_CATEGORY_ID ?? '0000'
    }
  });

  if (!data || error) {
    return null;
  }
  try {
    const filterCEItemsWithType = (items, type) => {
      return items?.filter(item => item?.type === type);
    };

    const footerInfo = filterCEItemsWithType(
      data?.category?.data?.items,
      'Vita-Footer-1'
    )?.[0] ?? { children: [] };

    const columnLinks =
      filterCEItemsWithType(
        data?.category?.data?.items,
        'Vita-Footer-2-0-Column'
      ) ?? [];

    const paymenMethods =
      filterCEItemsWithType(
        data?.category?.data?.items,
        'Vita-PaymentMethods'
      ) ?? [];

    if (footerInfo?.children?.length === 0) return null;

    let newChildren = [];
    if (footerInfo?.children?.length > 0) {
      footerInfo?.children?.forEach(child => {
        newChildren?.push(child);
      });
    }
    if (columnLinks?.length > 0) {
      columnLinks?.forEach(cl => {
        newChildren?.push(cl);
      });
    }

    let newFooterComp = {
      ...footerInfo,
      children: newChildren
    };
    /**
     * The keysFromObject function takes an object and returns the keys of that object.
     * @param obj Pass in an object that will be used to return the keys of that object
     * @return An array of the keys in an object
     *
     * @docauthor Anders Zetterström @ R3
     */
    const keysFromObject = obj => {
      return obj ? Object?.keys(obj) : null;
    };

    let items = [];

    if (keysFromObject(newFooterComp)?.length > 1) {
      items?.push(newFooterComp);
    }

    if (paymenMethods?.length > 0) {
      paymenMethods?.forEach(pm => {
        items?.push(pm);
      });
    }

    return (
      <div className={cx('footer')}>
        {/* {!loggedIn && <LifeClub />} */}
        <LifeClub />
        {items?.length > 0 && (
          <ContentRenderer items={items} components={footerComponents} />
        )}
      </div>
    );
  } catch (error) {
    console.warn('error', error);
    return null;
  }
};

export default Footer;
