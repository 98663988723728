/*
Use this import to get the configuration:
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
*/

const fontSizeBaseValue = 16; // pixels

module.exports = {
  default: {
    colors: {
      main: '#000000',
      grey: '#878787',
      darkerGrey: '#4F4F4F',
      lightgrey: '#E8E8E8',
      tablegrey: '#F3F3F3',
      beige: '#f5f5dc',
      loadingBar: '#2f80ed',
      blue: '#146DE1',
      red: '#DE2A23',
      background: '#FFFFFF',
      darkgrey: '#333',
      mediumgrey: '#9a9a9a',
      highlight: '#2f80ed',

      /* Life färger */
      forest: '#004E42',
      moss: '#00594C',
      seaweed: '#006C5B',
      grass: '#789F90',
      herb: '#B7CDC2',
      aloe: '#D1E0D7',
      guava: '#E8927C',
      rosehip: '#E5554F',
      plum: '#893B67',
      white: '#FFFFFF',
      black: '#333333',
      aloe25: 'rgba(209, 224, 215, 0.25)',
      boxshadow: '#c4c4c4',
      menuLines: '#e7e4e4',
      productBackground: '#f7f7f7',
      beetroot: '#982743'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700
    },
    breakpoints: {
      xs: '20rem',
      sm: '40rem',
      md: '50rem',
      lg: '64rem',
      xl: '75rem',
      xxl: '85rem'
    },
    fontSizes: [
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
      '48px',
      '64px',
      '72px'
    ],
    space: [
      '0px',
      '8px',
      '16px',
      '24px',
      '32px',
      '40px',
      '48px',
      '56px',
      '64px'
    ],
    fonts: {
      primary: 'Cera Pro',
      h1: `
        font-family: Cera Pro;
        font-size: 40px;
        line-height: 100%;
        font-weight: bold;
      `,
      h2: `
        font-family: Cera Pro;
        font-size: 32px;
        line-height: 120%;
        font-weight: bold;
      `,
      h3: `
        font-family: Cera Pro;
        font-size: 28px;
        line-height: 120%;
        font-weight: bold;
      `,
      h4: `
        font-family: Cera Pro;
        font-size: 20px;
        line-height: 120%;
      `,
      h5: `
        font-family: Cera Pro;
        font-size: 16px;
        line-height: 140%;
      `,
      h6: `
        font-family: Cera Pro;
        font-size: 14px;
        line-height: 130%;
      `,
      h7: `
        font-family: Cera Pro;
        font-size: 12px;
        line-height: 100%;
      `
    }
  },
  alt: {
    colors: {
      blue: 'blue',
      main: 'black'
    }
  },
  fontSizeBaseValue
};
