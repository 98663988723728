import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import t from '@jetshop/intl';
import useChildren from '../../../hooks/useChildren';
import { toRem } from '../../../helpers';
import { theme } from '../../Theme';
import { ReactComponent as GreenArrowRight } from '../../../svg/GreenArrowRight.svg';

const CategoryLinkWrapper = styled.a`
  width: 100%;
  height: ${toRem(72)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${theme.colors.white};
  padding: ${toRem(12)};
  text-decoration: none;
`;

const ImageWrapper = styled.div`
  height: ${toRem(48)};
  width: ${toRem(48)};
  overflow: hidden;
  border-radius: 50%;
`;

const ImageTitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

const TitleAndProductsWrapper = styled.div`
  padding-left: ${toRem(8)};
`;

const CategoryTitle = styled.h5`
  margin: 0;
  ${theme.fonts.desktop.h4};
  color: ${theme.colors.forest};
`;

const NumProducts = styled.p`
  font-size: ${toRem(12)};
  color: ${theme.colors.forest};
`;

const ArticleCategoryLinksWrapper = styled('div')`
  background-color: ${theme.colors.aloe};
  ${theme.below.md} {
    padding: 0;
  }
`;

const InnerWrapper = styled.div`
  display: grid;
  grid-gap: ${toRem(8)};

  padding-top: ${toRem(16)};
  padding-bottom: ${toRem(16)};

  ${theme.above.md} {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 1rem;
    padding-right: 1rem;
  }

  ${theme.above.lg} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${theme.below.md} {
    grid-template-columns: repeat(1, 1fr);
    padding: ${toRem(20)};
  }
`;
const ArticleCategoryLinks = props => {
  const cleanedProps = useChildren(props);
  const categoryLinks = cleanedProps['CATEGORY_LINK'];

  if (!categoryLinks) return null;

  return (
    <ArticleCategoryLinksWrapper>
      <InnerWrapper>
        {categoryLinks.map(cat => {
          return (
            <CategoryLinkWrapper
              key={cat?.key}
              href={cat?.category?.primaryRoute?.path}
            >
              <ImageTitleBlock>
                {cat.image && (
                  <ImageWrapper>
                    <Image
                      alt={cat?.imageDescription}
                      src={cat?.image}
                      fillAvailableSpace={true}
                    />
                  </ImageWrapper>
                )}
                <TitleAndProductsWrapper>
                  <CategoryTitle>{cat?.category?.name}</CategoryTitle>
                  {cat?.category?.products?.totalResults > 0 && (
                    <NumProducts>
                      {cat?.category?.products?.totalResults} {t('Products')}
                    </NumProducts>
                  )}
                </TitleAndProductsWrapper>
              </ImageTitleBlock>
              <GreenArrowRight />
            </CategoryLinkWrapper>
          );
        })}
      </InnerWrapper>
    </ArticleCategoryLinksWrapper>
  );
};

export default ArticleCategoryLinks;
