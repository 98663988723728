import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import Bubble from './Bubble';
import BlockLink from './BlockLink';
import { toRem, addAlphaChannel } from '../../../helpers';
import { theme } from '../../Theme';
import { Above } from '@jetshop/ui/Breakpoints';

const SmallCampaignWrapper = styled('div')`
  grid-area: ${props =>
    props.index !== undefined ? `small${props.index}` : 'auto'};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  > a {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;
  }
`;

const BubbleWrapper = styled('div')`
  position: absolute;
  left: ${toRem(24)};
  top: ${toRem(20)};

  .bubble-large-oval {
    width: 9rem;
    height: 9rem;

    ${theme.above.sm} {
      width: ${toRem(102)};
      height: ${toRem(102)};
    }
  }

  .bubble-small-oval {
    width: 1rem;
    height: 1rem;
    ${theme.above.sm} {
      width: ${toRem(13)};
      height: ${toRem(13)};
    }
  }

  .bubble-percentage {
    font-weight: 700;
    font-size: ${toRem(36)};
    line-height: ${toRem(36)};
    ${theme.above.sm} {
      font-size: ${toRem(28)};
      line-height: ${toRem(40)};
    }
  }

  ${theme.above.md} {
    left: ${toRem(28)};
    top: ${toRem(28)};
  }
`;

const Overlay = styled('div')`
  flex: 1 1 auto;
  padding: ${toRem(16)} ${toRem(24)};
  backdrop-filter: blur(5px);
  background: ${theme.colors.grass};

  ${theme.above.md} {
    padding: ${toRem(16)} ${toRem(28)};
  }
  ${theme.above.lg} {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${props =>
      !props.disableOverlayBackdrop
        ? addAlphaChannel(theme.colors.grass, 0.55)
        : 'transparent'};

    padding: ${toRem(16)};
  }
  ${theme.above.lg} {
    padding: ${toRem(16)} ${toRem(28)};
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  color: ${props => props.color};
`;

const Title = styled('div')`
  font-size: ${toRem(22)};
  line-height: 120%;
  font-weight: bold;
  margin-bottom: ${toRem(3)};
`;

const Text = styled('div')`
  font-size: ${toRem(16)};
  line-height: 120%;
  font-weight: normal;
`;

const OverlayText = ({
  title,
  text,
  textColor = 'white',
  disableOverlayBackdrop
}) => {
  if (title || text) {
    return (
      <Overlay
        color={theme.colors[textColor.toLowerCase()]}
        disableOverlayBackdrop={disableOverlayBackdrop}
      >
        {title && <Title>{title}</Title>}
        {text && <Text>{text}</Text>}
      </Overlay>
    );
  }
  return null;
};

const SmallCampaign = props => {
  const {
    externalLinkUrl,
    internalLinkUrl,
    backgroundImage,
    backgroundImageAlt,
    salesPercentage,
    salesBackgroundColor,
    overlayText,
    overlayTitle,
    overlayTextColor,
    disableOverlayBackdrop,
    cover,
    fillAvailableSpace,
    index
  } = props;

  return (
    <SmallCampaignWrapper index={index}>
      <BlockLink
        externalLinkUrl={externalLinkUrl}
        internalLinkUrl={internalLinkUrl}
      >
        <Above breakpoint="lg">
          {matches => (
            <Image
              src={backgroundImage}
              alt={backgroundImageAlt}
              fillAvailableSpace={fillAvailableSpace && matches}
              cover={cover}
              aspect="16:9"
              sizes={'1000'}
              critical
            />
          )}
        </Above>
        <BubbleWrapper>
          <Bubble
            percentage={salesPercentage}
            background={salesBackgroundColor}
          />
        </BubbleWrapper>
        <OverlayText
          title={overlayTitle}
          text={overlayText}
          textColor={overlayTextColor || undefined}
          disableOverlayBackdrop={disableOverlayBackdrop}
        />
      </BlockLink>
    </SmallCampaignWrapper>
  );
};

export default SmallCampaign;
