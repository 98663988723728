import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import { toRem } from '../../../../helpers';

const SectionWrapper = styled.li`
  display: flex;
  flex-direction: column;
  padding-top: ${toRem(48)};
  padding-bottom: ${toRem(48)};

  &:after {
    content: '';
    position: relative;
    top: ${toRem(48)};
    height: ${toRem(1)};
    width: ${toRem(100)};
    background-color: ${theme.colors.herb};
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    &:after {
      display: none;
    }
  }

  ${theme.above.md} {
    background: light-grey;
    min-width: ${toRem(220)};
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 ${toRem(48)} 0;
    margin-right: ${toRem(40)};

    &:after {
      display: block;
      content: '';
      position: relative;
      top: ${toRem(28)};
      left: ${toRem(-10)};
      height: ${toRem(66)};
      width: ${toRem(1)};
      background-color: ${theme.colors.herb};
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
      padding-bottom: ${toRem(48)};
    }
  }
`;

export default SectionWrapper;
