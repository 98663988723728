import { css } from 'linaria';
import { theme } from '../../../Theme';

export const headerDropdowCategoryStyle = css`
  > * {
    color: ${theme.colors.black};
    text-decoration: none;
    ${theme.fonts.desktop.p2}
    line-height: 180%;
    font-weight: 400;
  }

  &.lvl-2 {
    margin-bottom: 10px;
    break-inside: avoid-column;
    padding-left: 1rem;
    > * {
      font-weight: 700;

  }

  &.lvl-3 {
    > * {

    }
    
  }
`;
