import IvarTextBold_woff from './Ivar Text/IvarText-Bold.woff';
import IvarTextBold_woff2 from './Ivar Text/IvarText-Bold.woff2';
import IvarTextBoldItalic_woff from './Ivar Text/IvarText-BoldItalic.woff2';
import IvarTextBoldItalic_woff2 from './Ivar Text/IvarText-Italic.woff2';
import IvarTextItalic_woff from './Ivar Text/IvarText-Italic.woff';
import IvarTextItalic_woff2 from './Ivar Text/IvarText-Italic.woff2';
import IvarTextMedium_woff from './Ivar Text/IvarText-Medium.woff';
import IvarTextMedium_woff2 from './Ivar Text/IvarText-Medium.woff2';
import IvarTextMediumItalic_woff from './Ivar Text/IvarText-MediumItalic.woff';
import IvarTextMediumItalic_woff2 from './Ivar Text/IvarText-MediumItalic.woff2';
import IvarTextRegular_woff from './Ivar Text/IvarText-Regular.woff';
import IvarTextRegular_woff2 from './Ivar Text/IvarText-Regular.woff2';
import IvarTextSemiBold_woff from './Ivar Text/IvarText-SemiBold.woff';
import IvarTextSemiBold_woff2 from './Ivar Text/IvarText-SemiBold.woff2';
import IvarTextSemiBoldItalic_woff from './Ivar Text/IvarText-SemiBoldItalic.woff';
import IvarTextSemiBoldItalic_woff2 from './Ivar Text/IvarText-SemiBoldItalic.woff2';

const IvarText = `
    /* Ivar Text - Bold */
    @font-face {
        font-family: 'Ivar Text';
        src: url(${IvarTextBold_woff2}) format('woff2'),
            url(${IvarTextBold_woff}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    /* Ivar Text - Bold Italic */
    @font-face {
        font-family: 'Ivar Text';
        src: url(${IvarTextBoldItalic_woff2}) format('woff2'),
            url(${IvarTextBoldItalic_woff}) format('woff');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
    }

    /* Ivar Text - Semi Bold */
    @font-face {
        font-family: 'Ivar Text';
        src: url(${IvarTextSemiBold_woff2}) format('woff2'),
            url(${IvarTextSemiBold_woff}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    /* Ivar Text - Semi Bold Italic*/
    @font-face {
        font-family: 'Ivar Text';
        src: url(${IvarTextSemiBoldItalic_woff2}) format('woff2'),
            url(${IvarTextSemiBoldItalic_woff}) format('woff');
        font-weight: 600;
        font-style: italic;
        font-display: swap;
    }

    /* Ivar Text - Medium */
    @font-face {
        font-family: 'Ivar Text';
        src: url(${IvarTextMedium_woff2}) format('woff2'),
            url(${IvarTextMedium_woff}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    /* Ivar Text - Medium Italic*/
    @font-face {
        font-family: 'Ivar Text';
        src: url(${IvarTextMediumItalic_woff2}) format('woff2'),
            url(${IvarTextMediumItalic_woff}) format('woff');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

    /* Ivar Text - Regular */
    @font-face {
        font-family: 'Ivar Text';
        src: url(${IvarTextRegular_woff2}) format('woff2'),
            url(${IvarTextRegular_woff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    /* Ivar Text - Regular Italic */
    @font-face {
        font-family: 'Ivar Text';
        src: url(${IvarTextItalic_woff2}) format('woff2'),
            url(${IvarTextItalic_woff}) format('woff');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }
`;

export default IvarText;
