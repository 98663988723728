export const cs = () => import('./cs.json');
export const da = () => import('./da.json');
export const de = () => import('./de.json');
export const en = () => import('./en.json');
export const es = () => import('./es.json');
export const et = () => import('./et.json');
export const fr = () => import('./fr.json');
export const it = () => import('./it.json');
export const lv = () => import('./lv.json');
export const nl = () => import('./nl.json');
export const pl = () => import('./pl.json');
export const ru = () => import('./ru.json');
export const sk = () => import('./sk.json');
export const sr = () => import('./sr.json');
export const sv = () => translationFilesMerger('sv');
export const nb = () => translationFilesMerger('nb');
export const fi = () => translationFilesMerger('fi');

/**
 * The translationFilesMerger is function that merges the default translation file with the custom translation file (named r3-<languageCode>.json).
 *
 * @param {string} language Language code
 * @return {Object} Object with data spread from both default and custom translation files
 * @docauthor Anders Zetterström @ R3
 */
const translationFilesMerger = language => {
  let defaultTranslation = null;
  let customTranslation = null;
  try {
    defaultTranslation = require(`./${language}.json`);
  } catch (error) {
    console.warn(
      '🗣️ Default translation file not found for language: ',
      language
    );
  }
  try {
    customTranslation = require(`./r3-${language}.json`);
  } catch (error) {
    console.warn(
      '🗣️ Custom translation file not found for language: ',
      language
    );
  }

  return { ...defaultTranslation, ...customTranslation };
};
