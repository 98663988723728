import { css } from 'linaria';
import { theme } from '../../../Theme';

css`
  :global() {
    .content-positioner {
      padding: 20px !important;
      &.content-darken-gradient {
        --gradient-angle: 0deg;
        --gradient-opacity: 0.3;
        --gradient-opacity-zero: 0;

        :after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
        }
        &.top {
          --gradient-angle: 180deg;
          :after {
            background: linear-gradient(
              var(--gradient-angle),
              rgba(0, 0, 0, var(--gradient-opacity)) 0%,
              rgba(0, 0, 0, var(--gradient-opacity)) 33%,
              rgba(0, 0, 0, var(--gradient-opacity-zero)) 50%,
              rgba(0, 0, 0, var(--gradient-opacity-zero)) 66%,
              rgba(0, 0, 0, var(--gradient-opacity-zero)) 100%
            );
          }
        }

        &.middle {
          --gradient-angle: 0deg;
          :after {
            background: linear-gradient(
              var(--gradient-angle),
              rgba(0, 0, 0, var(--gradient-opacity-zero)) 0%,
              rgba(0, 0, 0, var(--gradient-opacity)) 33%,
              rgba(0, 0, 0, var(--gradient-opacity)) 50%,
              rgba(0, 0, 0, var(--gradient-opacity)) 66%,
              rgba(0, 0, 0, var(--gradient-opacity-zero)) 100%
            );
          }
        }

        &.bottom {
          --gradient-angle: 0deg;
          :after {
            background: linear-gradient(
              var(--gradient-angle),
              rgba(0, 0, 0, var(--gradient-opacity)) 0%,
              rgba(0, 0, 0, var(--gradient-opacity)) 33%,
              rgba(0, 0, 0, var(--gradient-opacity-zero)) 50%,
              rgba(0, 0, 0, var(--gradient-opacity-zero)) 66%,
              rgba(0, 0, 0, var(--gradient-opacity-zero)) 100%
            );
          }
        }
      }

      .content-positioner-container {
        z-index: 1;
        .content-pre-title {
          ${theme.fonts.desktop.h4}
          font-size: 16px;
          margin-bottom: 12px;
          ${theme.below.lg} {
            font-size: 14px;
          }
          &.hero {
            font-size: 16px;
          }
        }

        .content-title {
          ${theme.fonts.desktop.h2};
          margin-bottom: 10px;

          ${theme.below.lg} {
            font-size: 1.3rem;
            line-height: 1.2;
          }

          &.hero {
            font-size: 1.6rem;
          }
        }

        .content-text {
          margin-bottom: 10px;
        }

        .cta {
          margin-top: 15px;
        }
      }
    }
  }
`;
