import { css } from 'linaria';
import { theme } from '../../../Theme';

export const mobileHeaderStyle = css`
  width: 100%;
  position: sticky;
  top: 0;
  background-color: ${theme.colors.white};
  z-index: 3;
  display: block;

  .logo-and-icons {
    display: flex;
    justify-content: space-between;
    padding: 10px 1rem;

    .left {
    }

    .right {
      display: flex;
      align-items: center;
      gap: 1rem;

      svg {
        height: 20px;
        color: ${theme.colors.forest};
      }

      .favourites-icon-wrapper {
        .count-badge {
          background: ${theme.colors.seaweed};
        }
      }
    }
  }

  .header-search {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 1rem;
    border: 1px solid ${theme.colors.aloe};
    border-left: none;
    border-right: none;
  }
`;
