import React from 'react';
import { Flyout } from './Flyout';
import { FlyoutCartLayout } from './FlyoutCartLayout';
import { useCart } from '@jetshop/core/components/Query/CartProvider';
import cartQuery from '../Cart/queries/cartQuery.gql';
import { FlyoutMenuLayout } from './FlyoutMenuLayout';
import { useQuery } from '@apollo/react-hooks';
import homeCategoriesQuery from '../../../Layout/Header/HomeCategoriesQuery.gql';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { LifeClubFlyout } from './LifeClubFlyout';

export const FlyoutHolder = () => {
  const cartData = useCart(cartQuery);
  const { routes } = useShopConfig();

  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });
  return (
    <>
      <Flyout targetId={'life-club-flyout'} left={false} size={450}>
        {drawer => <LifeClubFlyout close={drawer.hideTarget} />}
      </Flyout>
      <Flyout targetId={'header-cart-desktop'} left={false} size={450}>
        {drawer => <FlyoutCartLayout result={result} cart={cartData?.cart} />}
      </Flyout>
      <Flyout targetId={'header-cart-mobile'} left={false} full={true}>
        {drawer => <FlyoutCartLayout result={result} cart={cartData?.cart} />}
      </Flyout>
      <Flyout targetId={'header-menu-mobile'} left={true}>
        {drawer => (
          <FlyoutMenuLayout
            categories={result?.data?.categories}
            active={true}
            parent={null}
            routes={routes}
            close={drawer.hideTarget}
          />
        )}
      </Flyout>
    </>
  );
};
