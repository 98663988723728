import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../../Theme';
import { SVG } from '../../../../assets/SVG';

const QtyAdjusterContainer = styled('div')`
  display: flex;
  align-items: center;
  grid-gap: 2px;
  background: ${theme.colors.backgroundGrey};
  width: max-content;

  button {
    width: 33px;
    height: 33px;
    background: ${theme.colors.forest};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.button.desktop.borderRadius};

    .icon {
      width: 11px;
      height: 11px;
      color: ${theme.colors.white};
    }

    :active {
      outline: none;
      background: ${theme.colors.grass};
    }
  }

  input {
    border: none;
    width: 33px;
    height: 33px;
    background: ${theme.colors.seaweed};
    text-align: center;
    font-size: 12px;
    border-radius: ${theme.button.desktop.borderRadius};
    color: ${theme.colors.white};
    /* --> Remove standard buttons from input <-- */
    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    [type='number'] {
      -moz-appearance: textfield;
    }

    :focus {
      outline: none;
      border: 1px solid ${theme.colors.grass};
    }
  }
`;

export const QtyAdjuster = ({
  qty = 1,
  incrementCallback,
  decrementCallback,
  setQuantityCallback
}) => {
  return (
    <QtyAdjusterContainer className="qty-adjuster">
      <button
        disabled={qty < 2}
        onClick={e => {
          e.preventDefault();
          decrementCallback?.();
        }}
      >
        <SVG name={'Minus'} />
      </button>
      <input
        value={qty}
        type={'number'}
        onChange={e => {
          const value = e?.currentTarget?.value;
          const newInt = parseInt(value);
          const notNumber = isNaN(newInt);

          if (!notNumber) {
            // setQty(newInt);
            setQuantityCallback?.(newInt);
          }
        }}
      />
      <button
        onClick={e => {
          e.preventDefault();
          incrementCallback?.();
        }}
      >
        <SVG name={'Plus'} />
      </button>
    </QtyAdjusterContainer>
  );
};
