import { useIntl } from '@jetshop/intl';
import { styled } from 'linaria/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../../../Theme';
import { SVG } from '../../../../assets/SVG';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';
import { hideScrollbar } from '../styles/scrollBar.style';

export const FlyoutContentWrapper = styled('div')`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: auto;
  opacity: ${props => (props.active ? 1 : 0)};
  overflow-y: ${props => (props.active ? 'auto' : 'hidden')};
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  transform: translateX(${props => (props.active ? 0 : -100)}%);
  transition: ${props => (props.active ? 0.4 : 0.2)}s ease-in-out;
  background: ${theme.colors.backgroundGrey};
  height: 100%;

  ${theme.below.lg} {
    padding-bottom: 3rem;
  }

  svg {
    height: 15px;
  }

  .categories,
  .sub-menu {
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;

    > button,
    > a {
      padding: 0 14px;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${theme.fonts.mobile.p1}

      text-decoration: none;
      background: ${theme.colors.white};
      color: ${theme.colors.black};

      &.backbutton {
        flex-direction: row-reverse;

        svg {
          transform: rotate(-180deg);
        }
      }

      &.show-all {
        background: ${theme.colors.aloe};
        font-weight: ${theme.fontWeights.medium};
      }

      svg {
        margin-bottom: 3px;
      }
    }

    button {
      font-weight: ${theme.fontWeights.medium};
    }
  }
  .sub-menu {
    background: transparent;
    > button,
    > a {
      background: transparent;

      svg {
        height: 18px;
        width: auto;
        margin-right: 12px;
        &.bigger {
          height: 22px;
        }
      }
    }
  }
`;

export const FlyoutMenuLayout = ({
  categories,
  active,
  goBack = () => {},
  parent = null,
  routes,
  close,
  ...rest
}) => {
  const [activeId, setActiveId] = useState(null);
  const backHandler = () => {
    setActiveId(null);
  };
  const t = useIntl();

  const favLink = t('/favourites');
  return (
    <>
      <FlyoutContentWrapper active={active} className={hideScrollbar}>
        <div className="categories">
          {parent && (
            <>
              <button onClick={goBack} className="backbutton">
                <span>{parent?.name}</span>

                <SVG name={'Chevron_right'} />
              </button>
            </>
          )}
          {parent && (
            <Link
              className="show-all"
              onClick={close}
              to={parent?.primaryRoute?.path}
            >
              <span>
                {t('Show all')} {parent?.name}
              </span>
              <SVG name={'Chevron_right'} />
            </Link>
          )}
          {categories?.map(cat =>
            cat?.hasSubcategories && cat?.subcategories ? (
              <button
                className="menu-btn"
                key={`btn-${cat?.id}`}
                onClick={() => setActiveId(cat?.id)}
              >
                <span>{cat?.name}</span>
                <SVG name={'Chevron_right'} />
              </button>
            ) : (
              <React.Fragment key={cat?.id}>
                {cat?.name?.toLowerCase() !== 'menu content' && (
                  <Link
                    className="anze"
                    onClick={close}
                    to={cat?.primaryRoute?.path}
                    key={`link-${cat.id}`}
                  >
                    <span>{cat?.name}</span>
                  </Link>
                )}
              </React.Fragment>
            )
          )}

          {routes && (
            <div className="sub-menu">
              <LinkEvaluator linkClickCallback={close} link={favLink}>
                <div>
                  <SVG name={'Heart'} />
                  <span>{t('Favourites')}</span>
                </div>
              </LinkEvaluator>
              <Link onClick={close} to={t(routes?.stores?.path)}>
                <div>
                  <SVG name={'Pin_outline'} className={'bigger'} />
                  <span>{t('Find store')}</span>
                </div>
              </Link>
            </div>
          )}
        </div>
      </FlyoutContentWrapper>

      {categories?.map(cat =>
        cat?.hasSubcategories && cat?.subcategories ? (
          <FlyoutMenuLayout
            key={`cat-${cat?.id}`}
            {...rest}
            close={close}
            parent={{ ...cat, parent: parent }}
            goBack={backHandler}
            categories={cat?.subcategories}
            active={cat?.id === activeId}
          />
        ) : null
      )}
    </>
  );
};
