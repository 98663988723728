import React from 'react';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import breadcrumbsQuery from './BreadcrumbsQuery.gql';
import { styled } from 'linaria/react';
import { default as Breadcrumbs } from '@jetshop/ui/Breadcrumbs';
import { theme } from '../../../Theme';

const StyledBreadcrumbsWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: ${props => props.talign}!important;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 8px;
  margin-top: 5px;

  ${theme.below.lg} {
    margin-top: 0;
  }

  color: ${props => props.textcolor};
  li {
    color: ${props => props.textcolor}!important;
    a {
      text-decoration: none;
    }
  }

  li:last-of-type::after {
    display: none;
  }
`;

export const BoxBreadcrumbs = ({ align, textColor }) => {
  const location = useLocation();
  const { data, loading, error } = useQuery(breadcrumbsQuery, {
    variables: {
      path: location?.pathname
    },
    errorPolicy: 'all'
  });

  const noCrumbs =
    data?.route?.id === '/' || data?.route?.object?.__typename === 'StartPage';

  if (error) {
    console.warn('Error fetching breadcrumbs data: ', error);
    return null;
  }

  if (loading || !data || noCrumbs) {
    return null;
  }
  let textAlign;
  switch (align) {
    case 'left':
      textAlign = 'flex-start';
      break;
    case 'center':
      textAlign = 'center';
      break;
    case 'right':
      textAlign = 'flex-end';
      break;
    default:
      textAlign = 'center';
  }
  const bcText = data?.route?.object?.breadcrumbText;
  const bcParents = data?.route?.parents;

  const breadcrumbProps = {
    breadcrumbText: bcText,
    parents: bcParents,
    textcolor: textColor ?? '#fff'
  };

  return (
    <StyledBreadcrumbsWrapper className={'box-breadcrumbs'} talign={textAlign}>
      <StyledBreadcrumbs {...breadcrumbProps} />
    </StyledBreadcrumbsWrapper>
  );
};
