import React from 'react';
import { cx } from 'linaria';
import { productCardHeaderStyle } from './ProductCardHeader.style';
import { useCustomFields } from '../../../ProductPage/components/useCustomFields';

export const ProductCardHeader = ({ product, style, children }) => {
  const { brand, productSize } = useCustomFields(product);
  const subTitle =
    product?.subName || `${brand?.stringValue} ${productSize?.stringValue}`;
  return (
    <header className={cx('product-card-header', style)}>
      <div className="product-card-header-names">
        <h3 className="product-card-header-product-name">{product?.name}</h3>
        <h4 className="product-card-header-sub-name">{subTitle}</h4>
      </div>
      {children}
    </header>
  );
};

export const StyledProductCardHeader = ({ product, children }) => {
  return (
    <ProductCardHeader product={product} style={productCardHeaderStyle}>
      {children}
    </ProductCardHeader>
  );
};
