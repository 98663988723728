import { ContentRenderer as Renderer } from '@jetshop/ui/ContentRenderer';
import React from 'react';
import { styled } from 'linaria/react';

// Component elements in admin should use a camel case name,
// the name will be in capital letters when we get it.
// imageWithAspectRatio -> IMAGEWITHASPECTRATIO

// Component files should return a string that you will get
// in the data as type.

// Root elements in admin should use words that are easy for
// the client to understand.
// The name will be in capital letters with the spaces kept.
// Text And Image -> 'TEXT AND IMAGE'

// Root files should return the react code to show the root element.
// Use useChildren to easilly get all root element props and
// all component element props. This requires you to use the
// type trick above.

// root elements
// import UspBar from './root/UspBar';
// import Footer from './root/Footer';
// import UspSection from './root/UspSection';
// import ArticleBlockLarge from './root/ArticleBlockLarge';
// import HeroOne from './root/HeroOne';
import HeroThree from './HeroThree';
import CampaignSmall from './CampaignSmall';
import ArticleBlockMedium from './ArticleBlockMedium';
import CategoryLinksWithImagesSection from './CategoryLinksWithImagesSection';
import CategoryLinksWithLinksSection from './CategoryLinksWithLinksSection';
// import RecommendedProducts from './root/RecommendedProducts';
// import ArticleListing from './root/ArticleListing';
import ArticlePageSection from './ArticlePageSection';
// import ArticleListingGrid from './root/ArticleListingGrid';
// import ArticleCategoryLinks from '../pages/CategoryPage/ArticleCategoryLinks';
// import ArticlePageHeader from '../pages/CategoryPage/ArticlePageHeader';
// import CategoryPageHeader from '../pages/CategoryPage/CategoryPageHeader';
import CategoryLinks from './CategoryLinks';
import { ScriptLoader } from '../ScriptLoader';
import { PlaceholderChildComponent } from 'r3-lib/dist/r3lib';
import { allContentComponents } from './Imports';
// import { HtmlRow } from '../components/HtmlRow';
// import { PopupTrigger } from '../header/PopupTrigger';

// component elements
export const COMPONENTS = {
  //   USP: 'USP',
  //   LINK_GROUP_1: 'LINK_GROUP_1',
  //   LINK_GROUP_2: 'LINK_GROUP_2',
  //   LINK_GROUP_3: 'LINK_GROUP_3',
  //   SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  //   NEWSLETTER: 'NEWSLETTER',
  //   BADGE: 'BADGE',
  //   PAYMENT_ICON: 'PAYMENT_ICON',
  //   HERO_LARGE: 'HERO_LARGE',
  //   HERO_SMALL: 'HERO_SMALL',
  //   USP_SECTION: 'USP_SECTION',
  //   USP_BOX: 'USP_BOX',
  //   ARTICLE_BLOCK_LARGE: 'ARTICLE BLOCK LARGE',
  LARGE_CAMPAIGN: 'LARGE_CAMPAIGN',
  SMALL_CAMPAIGN: 'SMALL_CAMPAIGN',
  //   TITLE_BUTTON: 'TITLE_BUTTON',
  ARTICLE_BLOCK_MEDIUM: 'ARTICLE BLOCK MEDIUM',
  ARTICLE_BOX_MEDIUM: 'ARTICLE_BOX_MEDIUM',
  NAVIGATION_LINK_WITH_IMAGE: 'NAVIGATION_LINK_WITH_IMAGE',
  NAVIGATION_LINK_WITH_SUB_LINKS: 'NAVIGATION_LINK_WITH_SUB_LINKS'
};

/** The ContentRenderer builds a data structure from these components
 * that is returned to the Root element. The props in admin comes
 * as props and the component elements comes as children.
 */
const components = {
  //   'ARTICLE BLOCK LARGE': ArticleBlockLarge,
  'ARTICLE BLOCK MEDIUM': ArticleBlockMedium,
  'ARTICLE BOX MEDIUM': 'ARTICLE_BOX_MEDIUM',
  'ARTICLE CONTENT BLOCK': ArticlePageSection,
  //   'ARTICLE LISTING': ArticleListingGrid,
  //   'ARTICLE LISTING STARTPAGE': ArticleListing,
  //   'ARTICLE PAGE CATEGORY LINKS': ArticleCategoryLinks,
  //   BADGE: COMPONENTS.BADGE,
  'CAMPAIGN SMALL': CampaignSmall,
  //   CATEGORY: 'CATEGORY',
  'CATEGORY LINKS': CategoryLinks,
  'CATEGORY LINK': 'CATEGORY_LINK',
  //   FOOTER: Footer,
  //   'HEADER ARTICLE PAGE': ArticlePageHeader,
  //   'HEADER ARTICLE LISTING PAGE': ArticlePageHeader,
  //   'HEADER CATEGORY PAGE': CategoryPageHeader,
  //   'HERO ONE': HeroOne,
  //   HEROLARGE: 'HERO_LARGE',
  //   HEROSMALL: 'HERO_SMALL',
  'HERO THREE': HeroThree,
  'LARGE CAMPAIGN': 'LARGE_CAMPAIGN',
  //   'LINK GROUP 1': COMPONENTS.LINK_GROUP_1,
  //   'LINK GROUP 2': COMPONENTS.LINK_GROUP_2,
  //   'LINK GROUP 3': COMPONENTS.LINK_GROUP_3,
  'NAVIGATION LINK WITH IMAGE': 'NAVIGATION_LINK_WITH_IMAGE',
  'NAVIGATION LINKS WITH IMAGES': CategoryLinksWithImagesSection,
  'NAVIGATION LINK WITH SUB LINKS': 'NAVIGATION_LINK_WITH_SUB_LINKS',
  'NAVIGATION LINKS WITH SUB LINKS': CategoryLinksWithLinksSection,
  //   NEWSLETTER: COMPONENTS.NEWSLETTER,
  //   'PAYMENT ICON': COMPONENTS.PAYMENT_ICON,
  //   'PRODUCT RECOMMENDATIONS': RecommendedProducts,
  'SMALL CAMPAIGN': 'SMALL_CAMPAIGN',
  //   'SOCIAL MEDIA': COMPONENTS.SOCIAL_MEDIA,
  //   'TITLE BUTTON': 'TITLE_BUTTON',
  //   USP: COMPONENTS.USP,
  //   'USP BAR': UspBar,
  //   'USP BOX': 'USP_BOX',
  //   'USP SECTION': UspSection,
  //   HTMLROW: HtmlRow
  'VITA-SCRIPTLOADER': ScriptLoader,
  'VITA-SCRIPTLOADERATTRIBUTE': PlaceholderChildComponent
};
/** The holder of the elements */
const ContentRendererWrapper = styled('section')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

/** Fetch the elements from the database that the page contains (items). Then
 * use the elements defined in this page to render the elements as React code.
 */
const ContentRenderer = props => {
  let { items, onlyWithComponents } = props;

  if (!items) items = [];

  return (
    <ContentRendererWrapper>
      <Renderer
        items={items}
        components={
          onlyWithComponents ?? { ...components, ...allContentComponents }
        }
      />
    </ContentRendererWrapper>
  );
};
export default ContentRenderer;
