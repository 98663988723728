import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';

import { SVG } from '../../../assets/SVG';
import { theme } from '../../Theme';

const SwiperButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  aspect-ratio: 1;
  background-color: ${theme.colors.forest};
  transition: all 0.2s ease-in-out;

  svg {
    height: 14px;
    width: 14px;
    color: ${theme.colors.white};
  }

  &.left {
    svg {
      transform: rotate(180deg);
    }
  }

  :hover,
  :active {
    opacity: 0.8;
  }

  :active {
    transform: scale(0.95);
  }
`;

export const SwiperButton = ({ direction, callback }) => {
  return (
    <SwiperButtonWrapper
      className={cx('slider-button', direction)}
      onClick={callback}
    >
      <SVG name="Chevron_right" />
    </SwiperButtonWrapper>
  );
};
