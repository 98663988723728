import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

import { css } from 'linaria';

const linkStyle = css`
  a {
    color: inherit;
  }
`;

const ColumnTextWrapper = styled.div`
  columns: 2;
  column-gap: 3rem;
  column-width: 5rem;
  max-width: min(60rem, 80%);
  margin: 0 auto;
  ${theme.below.md} {
    columns: 1;
  }

  blockquote {
    font-size: 1.6rem !important;
    font-style: italic;
    font-weight: 500 !important;
    line-height: 1.4;
    text-align: left !important;
    color: #000;
    margin: 1.5rem 0 0 0 !important;
  }
`;

export const ColumnText = props => {
  const text = props?.children[0]?.props?.html?.value;
  return (
    <ColumnTextWrapper
      className={linkStyle}
      dangerouslySetInnerHTML={{
        __html: text
      }}
    ></ColumnTextWrapper>
  );
};
