import React from 'react';
import { styled } from 'linaria/react';
import theme from './Theme';

const HtmlRowWrapper = styled('section')`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const HtmlWrapper = styled('div')`
  padding: 0 20px;
  h1,
  h2,
  h3,
  h4,
  p,
  a {
    margin-bottom: 1rem;
    color: ${theme.colors.black};
  }

  h1 {
    ${theme.fonts.h1}
  }

  h2 {
    ${theme.fonts.h2}
  }

  h3 {
    ${theme.fonts.h3}
  }

  h4 {
    ${theme.fonts.h4}
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
  }

  ul {
    list-style-type: disc;
    padding-left: 2rem;
    margin-bottom: 1rem;
    li {
      font-size: 1rem;
      line-height: 1.6;
      color: ${theme.colors.black};
    }
  }
`;

export const OldHtmlRow = ({ html }) => {
  return (
    <HtmlRowWrapper className="html-row-wrapper">
      <HtmlWrapper
        dangerouslySetInnerHTML={{
          __html: html?.value?.value ?? html?.value
        }}
      />
    </HtmlRowWrapper>
  );
};
