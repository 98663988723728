export const getProductStructure = product => {
  const size_tag =
    product?.custom?.Product_Size?.[0]?.label !== '1 st'
      ? product?.custom?.Product_Size?.[0]?.label
      : '';

  const subTitle = size_tag ? `${product?.brand} ${size_tag}` : product?.brand;

  const decodedBadges = product?.custom?.Badges?.flatMap(b => {
    const decoded = Buffer.from(b.label, 'base64').toString('utf-8');
    if (decoded !== 'null') {
      return JSON.parse(decoded);
    }
    return null;
  }).filter(b => b !== null && b?.text !== '');

  const otherBadges = product?.badges?.primary
    ?.filter(b => b?.attribute !== 'custom.Badges')
    .flatMap(b => {
      return {
        name: b.label,
        style: 'rosehip',
        text: b.label,
        location: 'TOP_LEFT',
        url:
          'https://www.lifebutiken.se/M1/production/images/overlay/overlay6_sv-SE.png'
      };
    });
  let combinedBadges = [];
  if (decodedBadges?.length > 0) {
    combinedBadges = decodedBadges;
  }
  if (otherBadges?.length > 0) {
    combinedBadges = [...otherBadges, ...combinedBadges];
  }

  const productLink =
    product?.variants?.[0]?.link?.length > 0
      ? product?.variants?.[0]?.link
      : product?.link;

  const newStructure = {
    articleNumber: product.key.split('-')[1],
    badges: combinedBadges,
    name: product?.title,
    barcode: product?.custom?.GTIN?.[0]?.label,
    subName: subTitle,
    images: [{ url: product?.imageInfo?.thumbnail }],
    primaryRoute: {
      path: productLink,
      slug: product.link.replace(/^\/+/, '')
    },
    previousPrice: { incVat: product?.listPrice?.min },
    price: { incVat: product?.sellingPrice?.min },
    ticket: product.ticket
  };
  return {
    ...product,
    ...newStructure
  };
};
